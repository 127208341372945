import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRateAdjustmentDataList } from 'src/store/selectors/catalogSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  LOAD_RATE_ADJUSTMENT_DATA,
  loadRateAdjustmentData
} from 'src/store/actions/catalogActions';

import RateAdjustmentsTable from './RateAdjustmentsTable';

const RateAdjustmentsView = () => {
  const rateAdjustmentData = useSelector(selectRateAdjustmentDataList);

  const isLoading = useSelector(
    isLoadingSelector([LOAD_RATE_ADJUSTMENT_DATA.action])
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadRateAdjustmentData());
  }, []);

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <RateAdjustmentsTable
          rateAdjustmentData={rateAdjustmentData}
          loading={isLoading}
        />
      </Box>
    </>
  );
};

export default RateAdjustmentsView;
