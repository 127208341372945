import { Card } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import loadPayment, {
  LOAD_PAYMENTS_LIST
} from 'src/store/actions/paymentsActions';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import AutomaticDebitCollectionStatus from 'src/utils/payments';
import {
  selectPaymentsList,
  selectPaymentsListCount,
  selectPaymentQueryOptions
} from 'src/store/selectors/paymentSelectors';
import PaymentsFailuresTable from './PaymentsFailuresTable';

const PaymentsFailuresList = () => {
  const dispatch = useDispatch();
  const paymentFailures = useSelector(selectPaymentsList);
  const count = useSelector(selectPaymentsListCount);
  const { page } = useSelector(selectPaymentQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_PAYMENTS_LIST.action]));
  const success = useSelector(successSelector([LOAD_PAYMENTS_LIST.action]));

  const handleChangePage = useCallback(
    (event, value) => {
      dispatch(
        loadPayment({
          status: AutomaticDebitCollectionStatus.FAILED_TO_BE_DEBITED,
          page: value
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(
      loadPayment({
        status: AutomaticDebitCollectionStatus.FAILED_TO_BE_DEBITED,
        page
      })
    );
  }, [dispatch, page]);

  if (success && !loading && paymentFailures.length === 0) {
    return (
      <Card>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.AUTOMATIC_DEBIT_TITLE" />
      </Card>
    );
  }

  return (
    <PaymentsFailuresTable
      paymentFailures={paymentFailures}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default PaymentsFailuresList;
