import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getPaymentFailures = serviceBase({
  request: options =>
    axiosClient.get('automatic-debit', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    }),
  retry: 0
});

export const cancelPayment = serviceBase({
  request: id => axiosClient.put(`automatic-debit/${id}/cancel`),
  retry: 0
});

export const editPaymentDueDate = serviceBase({
  request: ({ id, dueDate }) => {
    return axiosClient.put(`automatic-debit/${id}/due-date`, {
      dueDate: new Date(dueDate)
    });
  },
  retry: 0
});

export const sendToBillPayment = serviceBase({
  request: id => axiosClient.put(`automatic-debit/charge-payment/${id}`),
  retry: 0
});
