import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { calculateDiscountGridAction } from 'src/store/actions/catalogActions';
import validationSchema from './schema';
import makeInitialState from './initialState';
import useStyles from '../styles';

const CalculateGridForm = ({ setRate }) => {
  const initialState = useMemo(() => makeInitialState({ rate: '' }), []);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmit = values => {
    setRate(values.rate);
    dispatch(calculateDiscountGridAction(values));
  };

  return (
    <>
      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          handleSubmit
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', marginBottom: 2 }}>
                <Box>
                  <TextField
                    className={clsx(classes.textField)}
                    InputProps={{
                      autoComplete: 'off',
                      sx: {
                        height: 50
                      }
                    }}
                    sx={{ backgroundColor: 'whitesmoke' }}
                    error={Boolean(touched.rate && errors.rate)}
                    fullWidth
                    helperText={touched.rate && t(errors.rate)}
                    label={t('COMMON.RATE')}
                    margin="normal"
                    name="rate"
                    onBlur={handleBlur}
                    onChange={e => {
                      handleChange(e);
                    }}
                    onInput={e => {
                      e.target.value = e.target.value.toUpperCase();
                    }}
                    type="text"
                    value={values.rate}
                    variant="outlined"
                    size="medium"
                  />
                </Box>
                <Box
                  sx={theme => ({
                    marginTop: theme.spacing(2),
                    marginLeft: 2
                  })}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    className={clsx(classes.buttons)}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {t('COMMON.CALCULATE')}
                  </Button>
                </Box>
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
CalculateGridForm.propTypes = {
  setRate: PropTypes.func
};

export default CalculateGridForm;
