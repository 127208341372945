import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card } from '@mui/material';

import NotFoundRecords from 'src/components/NotFoundRecords';
import Accordion from 'src/components/Accordion/Accordion';
import BillingParameterTable from './BillingParameterTable';

export const BillingSubSectionList = {
  BILLING_PARAMETERS: 'BILLING_PARAMETERS',
  INVOICE_TYPES: 'INVOICE_TYPES'
};

const BillingItemsBySubSection = {
  BILLING_PARAMETERS: [
    'ACTIVE_GENERIC_INVOICES',
    'INVOICE_USE_CFDI',
    'EQUIPMENT_SELL_USE_CDFI',
    'BILLING_PAYMENT_METHOD',
    'BILLING_PAYMENT_FORM',
    'IVA',
    'ACTIVATE_AUTOMATIC_BILLING',
    'UNIT_KEY_FOR_SELLING_BILLING',
    'UNIT_KEY_FOR_RECURRING_BILLING',
    'MAX_COLLECTION_ATTEMPTS'
  ],
  INVOICE_TYPES: [
    'CFDI_RENT_SERIES',
    'CFDI_CAPITAL_CONTRIBUTION_SERIES',
    'CFDI_RESIDUAL_SERIES',
    'CFDI_DISCHARGE_SERIES',
    'CFDI_DISCOUNT_SERIES',
    'CFDI_ADVANCE_SERIES',
    'CFDI_PAYMENT_SERIES',
    'CFDI_INITIAL_PAYMENT_SERIES',
    'CFDI_OTHER_SERIES'
  ]
};

export const filterParameterByBillingSubSection = (
  parameters,
  billingSection
) => {
  return parameters.filter(parameter => {
    return BillingItemsBySubSection[billingSection].includes(parameter.name);
  });
};

const BillingParameterList = ({ handleOpenEdit, parameters, loading }) => {
  const billingSeciontList = {
    BILLING_PARAMETERS: [
      {
        title: 'COMMON.BILLING_PARAMETERS',
        subtitle: '',
        content: (
          <BillingParameterTable
            parameters={filterParameterByBillingSubSection(
              parameters,
              BillingSubSectionList.BILLING_PARAMETERS
            )}
            loading={loading}
            handleOpenEdit={handleOpenEdit}
          />
        )
      }
    ],
    INVOICE_TYPES: [
      {
        title: 'COMMON.INVOICE_TYPES',
        subtitle: '',
        content: (
          <BillingParameterTable
            parameters={filterParameterByBillingSubSection(
              parameters,
              BillingSubSectionList.INVOICE_TYPES
            )}
            loading={loading}
            handleOpenEdit={handleOpenEdit}
          />
        )
      }
    ]
  };

  if (!parameters.length)
    return (
      <Card>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.PARAMETER_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.PARAMETER_DESCRIPTION"
        />
      </Card>
    );

  return (
    <Box>
      <Accordion list={billingSeciontList.BILLING_PARAMETERS} openFirst />
      <Accordion list={billingSeciontList.INVOICE_TYPES} />
    </Box>
  );
};

BillingParameterList.propTypes = {
  handleOpenEdit: PropTypes.func,
  parameters: PropTypes.array,
  loading: PropTypes.bool
};

export default BillingParameterList;
