import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Collapse } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import PropTypes from 'prop-types';

const NoMsiParametersAlert = ({ open, distributorId }) => {
  const { t } = useTranslation();
  return (
    <Collapse in={open}>
      <Alert severity="error">
        {t('QUOTES_VIEW.ALERT.NO_MSI_PARAMETERS_FIRST')}
        <Link to={`/distributors/business/${distributorId}/parameters`}>
          {t('QUOTES_VIEW.ALERT.NO_MSI_PARAMETERS_SECOND')}
        </Link>
      </Alert>
    </Collapse>
  );
};

export default NoMsiParametersAlert;

NoMsiParametersAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  distributorId: PropTypes.object.isRequired
};
