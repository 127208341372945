import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  FormControl
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DialogTransition from 'src/components/DialogTransition';
import Button from 'src/components/Button';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import { useDispatch, useSelector } from 'react-redux';
import { getDifferenceBetweenStates } from 'src/utils/common';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import {
  LOAD_MSI_DISCOUNT,
  loadMsiDiscount
} from 'src/store/actions/catalogActions';
import { msiTermList } from 'src/utils/constants/distributors';
import { selectMsiDiscountsList } from 'src/store/selectors/catalogSelectors';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../styles';

const EditParameterCategoryDialog = ({
  open,
  distributor,
  handleClose,
  onSubmit
}) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(distributor), [
    distributor
  ]);
  const dispatch = useDispatch();
  const resetRef = useRef();
  const { t } = useTranslation();

  const isLoadingDiscountMsi = useSelector(
    isLoadingSelector([LOAD_MSI_DISCOUNT.action])
  );
  const discountMsiList = useSelector(selectMsiDiscountsList);

  useEffect(() => {
    if (open) {
      if (distributor.enableMsi) {
        dispatch(loadMsiDiscount());
      }
    }
  }, [open]);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      const diference = getDifferenceBetweenStates(initialState, values);
      if (Object.entries(diference).length === 0) {
        handleClose();
        return;
      }
      onSubmit(diference);
      resetForm(initialState);
      handleClose();
    },
    [onSubmit, handleClose, initialState]
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  const handleMsiChange = (field, value, values, setFieldValue) => {
    if (field === 'minMsiTerm') {
      if (value > values.maxMsiTerm) {
        setFieldValue('maxMsiTerm', value);
      }
      setFieldValue('minMsiTerm', value);
    } else if (field === 'maxMsiTerm') {
      if (value < values.minMsiTerm) {
        setFieldValue('minMsiTerm', value);
      }
      setFieldValue('maxMsiTerm', value);
    }
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-quote-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('DISTRIBUTORS_VIEW.PARAMETERS.EDIT_PARAMETER')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px', overflow: 'hidden' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleSubmit,
            values,
            touched,
            resetForm,
            setFieldValue
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                {distributor.enableMsi && (
                  <>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.discountMsi && errors.discountMsi)}
                    >
                      <Autocomplete
                        options={discountMsiList}
                        id="discountMsi"
                        loading={isLoadingDiscountMsi}
                        getOptionLabel={option =>
                          option.name ? option.name : ''
                        }
                        label={t('COMMON.MSI_QUOTER')}
                        value={
                          discountMsiList.find(
                            discount => discount.id === values.discountMsi?.id
                          ) || null
                        }
                        fullWidth
                        onChange={(_, value) => {
                          setFieldValue('discountMsi', value || '');
                          setFieldValue('discountMsiId', value?.id || '');
                        }}
                        sx={{ marginBottom: 2, marginTop: 2 }}
                        error={Boolean(
                          touched.discountMsi && errors.discountMsi
                        )}
                        helperText={
                          touched.discountMsi && t(errors.discountMsi)
                        }
                      />
                    </FormControl>

                    <FormControl
                      fullWidth
                      error={Boolean(touched.minMsiTerm && errors.minMsiTerm)}
                    >
                      <Autocomplete
                        options={msiTermList}
                        id="minMsiTerm"
                        loading={isLoadingDiscountMsi}
                        getOptionLabel={option => t(option.name) || ''}
                        label={t('DISTRIBUTORS_VIEW.PARAMETERS.MIN_MSI_TERM')}
                        value={msiTermList.find(
                          option => option.key === Number(values.minMsiTerm)
                        )}
                        fullWidth
                        onChange={(_, value) => {
                          handleMsiChange(
                            'minMsiTerm',
                            value?.key || '',
                            values,
                            setFieldValue
                          );
                        }}
                        sx={{ marginBottom: 2, marginTop: 2 }}
                        error={Boolean(touched.minMsiTerm && errors.minMsiTerm)}
                        helperText={touched.minMsiTerm && t(errors.minMsiTerm)}
                      />
                    </FormControl>

                    <FormControl
                      fullWidth
                      error={Boolean(touched.maxMsiTerm && errors.maxMsiTerm)}
                    >
                      <Autocomplete
                        options={msiTermList}
                        id="maxMsiTerm"
                        loading={isLoadingDiscountMsi}
                        getOptionLabel={option => t(option.name) || ''}
                        label={t('DISTRIBUTORS_VIEW.PARAMETERS.MAX_MSI_TERM')}
                        value={msiTermList.find(
                          option => option.key === Number(values.maxMsiTerm)
                        )}
                        fullWidth
                        onChange={(_, value) => {
                          handleMsiChange(
                            'maxMsiTerm',
                            value?.key || '',
                            values,
                            setFieldValue
                          );
                        }}
                        sx={{ marginBottom: 2, marginTop: 2 }}
                        error={Boolean(touched.maxMsiTerm && errors.maxMsiTerm)}
                        helperText={touched.maxMsiTerm && t(errors.maxMsiTerm)}
                      />
                    </FormControl>
                  </>
                )}
                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button type="submit" color="primary" variant="outlined">
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

EditParameterCategoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  distributor: PropTypes.object
};

export default EditParameterCategoryDialog;
