import * as Yup from 'yup';

export default Yup.object().shape({
  adjustment: Yup.number()
    .min(0, 'SCHEMA_ERRORS.NUMBER_MUST_TO_EQUAL_OR_GREATER_THAN_ZERO')
    .max(100, 'SCHEMA_ERRORS.NUMBER_MUST_BE_LESS_THAN_ONE_HUNDRED')
    .required('SCHEMA_ERRORS.VALUE_REQUIRED'),
  gracePeriod: Yup.string()
    .required('SCHEMA_ERRORS.VALUE_REQUIRED')
    .max(50)
});
