import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, TextField } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DropdownMenu from 'src/components/DropdownMenu';
import {
  DaysForFirstRentAfterContractStartFirstUpfrontRentList,
  DaysForFirstRentAfterContractStartList
} from 'src/utils/constants/distributors';
import { selectDistributorDetail } from 'src/store/selectors/distributorSelectors';
import { useSelector } from 'react-redux';
import { calculateFirstRentDateFromGracePeriod } from 'src/utils/contracts';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import momentLocalTimezone from 'src/utils/momentLocalTimezone';
import generateNumbersList from 'src/utils/generateNumbersList';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const StepFour = ({ data, handleNext, handleBack, resetRef }) => {
  const classes = useStyles();
  const parameter = useSelector(selectDistributorDetail);
  const initialState = useMemo(() => makeInitialState(data, parameter), [data]);
  const { t } = useTranslation();

  const onNext = useCallback(
    values => {
      handleNext(values);
    },
    [handleNext]
  );

  return (
    <Formik
      initialValues={initialState}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onNext}
    >
      {({
        errors,
        handleSubmit,
        touched,
        values,
        setFieldValue,
        resetForm,
        handleBlur
      }) => {
        resetRef.current = () => resetForm();
        return (
          <form onSubmit={handleSubmit}>
            <Box className={classes.container}>
              <DatePicker
                label={t('CONTRACT_VIEW.SIGNATURE_DATE')}
                inputFormat="DD/MM/YYYY"
                value={values.startDate}
                disabled={data.sendToDigitalSign}
                margin="startDate"
                onChange={newValue => {
                  setFieldValue('startDate', moment(newValue));

                  let firstRentDate;
                  if (values.isFirstUpfrontRentModality) {
                    firstRentDate = momentLocalTimezone(newValue).add(
                      values.gracePeriod
                    );
                  } else if (Number(values.initialPayment) === 0) {
                    firstRentDate = momentLocalTimezone(newValue);
                  } else {
                    firstRentDate = momentLocalTimezone(newValue).add(
                      values.gracePeriod + 30,
                      'days'
                    );
                  }
                  setFieldValue('firstRentDate', firstRentDate);
                  setFieldValue(
                    'endDate',
                    calculateFirstRentDateFromGracePeriod(
                      momentLocalTimezone(newValue),
                      values.gracePeriod
                    ).add(data.term - 1, 'months')
                  );
                }}
                error={Boolean(touched.startDate && errors.startDate)}
                renderInput={params => (
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    {...params}
                    sx={{ width: '100%', margin: '16px 0' }}
                    FormHelperTextProps={{ sx: { color: 'red' } }}
                    helperText={touched.startDate && t(errors.startDate)}
                  />
                )}
              />

              <DropdownMenu
                list={
                  values.isFirstUpfrontRentModality
                    ? DaysForFirstRentAfterContractStartFirstUpfrontRentList
                    : DaysForFirstRentAfterContractStartList
                }
                onBlur={handleBlur}
                label={t('COMMON.DAYS_FOR_FIRST_RENT')}
                value={values.gracePeriod}
                name="gracePeriod"
                setValue={value => {
                  setFieldValue('gracePeriod', value);
                  setFieldValue(
                    'firstRentDate',
                    calculateFirstRentDateFromGracePeriod(
                      momentLocalTimezone(value.startDate),
                      value,
                      data.isFirstUpfrontRentModality
                    )
                  );
                  setFieldValue(
                    'endDate',
                    calculateFirstRentDateFromGracePeriod(
                      momentLocalTimezone(value.startDate),
                      value,
                      data.isFirstUpfrontRentModality
                    ).add(data.term - 1, 'months')
                  );
                }}
                error={Boolean(touched.gracePeriod && errors.gracePeriod)}
                errorText={touched.gracePeriod && t(errors.gracePeriod)}
                disabled={data.enableMsi || Number(data.initialPayment) === 0}
              />
              <Autocomplete
                options={generateNumbersList(1, 25)}
                id="payday"
                label={t('NAVIGATION.PAY_DAY')}
                value={values.payday}
                fullWidth
                onInputChange={(e, value) => {
                  setFieldValue('payday', Number(value));
                }}
                sx={{ marginBottom: 1, marginTop: 2 }}
                helperText={touched.payday && t(errors.payday)}
                error={Boolean(touched.payday && errors.payday)}
              />
              <DatePicker
                label={t('CONTRACT_VIEW.FIRST_RENT_DATE')}
                value={values.firstRentDate}
                disabled
                margin="firstRentDate"
                inputFormat="DD/MM/YYYY"
                onChange={newValue => {
                  setFieldValue('firstRentDate', moment(newValue));
                }}
                error={Boolean(touched.firstRentDate && errors.firstRentDate)}
                renderInput={params => (
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    {...params}
                    sx={{ width: '100%', margin: '16px 0' }}
                    FormHelperTextProps={{ sx: { color: 'red' } }}
                    helperText={
                      touched.firstRentDate && t(errors.firstRentDate)
                    }
                  />
                )}
              />

              <DatePicker
                label={t('CONTRACT_VIEW.LAST_RENT_DATE')}
                value={values.endDate}
                margin="endDate"
                disabled
                inputFormat="DD/MM/YYYY"
                error={Boolean(touched.endDate && errors.endDate)}
                renderInput={params => (
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    {...params}
                    sx={{ width: '100%', margin: '16px 0' }}
                    FormHelperTextProps={{
                      sx: {
                        color: 'red'
                      }
                    }}
                    helperText={touched.endDate && t(errors.endDate)}
                    error={Boolean(touched.endDate && errors.endDate)}
                  />
                )}
              />
            </Box>

            <Box
              className={classes.box}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                className={classes.buttons}
                onClick={() => handleBack()}
                color="primary"
                variant="outlined"
              >
                {t('COMMON.BACK')}
              </Button>
              <Button
                className={clsx(classes.buttons, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
              >
                {t('COMMON.NEXT')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};
StepFour.propTypes = {
  data: PropTypes.object,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  resetRef: PropTypes.object
};

export default StepFour;
