import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box
} from '@mui/material';
import { formatAmount } from 'src/utils/formatAmout';
import useStyles from '../../styles';

const DistributorEquipmentsList = ({ equipments }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const total = equipments.reduce(
      (eqTotal, eq) => eqTotal + eq.priceWithIva * eq.quantity,
      0
    );
    setTotalPrice(total);
  }, [equipments]);

  return (
    <Table classes={{ root: classes.tableRoot }} component="div">
      <TableHead>
        <TableRow>
          <TableCell align="center">
            {t('COMMON.TITLE_EQUIPMENT', { defaultValue: 'Equipo' })}
          </TableCell>
          <TableCell align="center">
            {t('COMMON.TITLE_PRICE', { defaultValue: 'Precio' })}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody classes={{ root: classes.tableBody }} component="div">
        {equipments.map(eq => {
          const formattedAmount = formatAmount(eq.priceWithIva * eq.quantity);
          return (
            <TableRow component="div" key={eq.model}>
              <TableCell
                component="div"
                className={classes.reviewDataLabel}
                align="center"
              >
                {t('COMMON.EQUIPMENT_MODEL', {
                  equipment: `${eq.model} (x${eq.quantity})`
                })}
              </TableCell>
              <TableCell component="div" align="center">
                <Typography variant="body2" color="textSecondary">
                  {t('COMMON.EQUIPMENT_PRICE_WITH_IVA', {
                    amount: formattedAmount
                  })}
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Typography variant="h6" className={classes.totalText}>
          {t('COMMON.TOTAL_PRICE', { defaultValue: 'Total:' })}
          {` $${formatAmount(totalPrice)}`}
        </Typography>
      </Box>
    </Table>
  );
};

DistributorEquipmentsList.propTypes = {
  equipments: PropTypes.arrayOf(
    PropTypes.shape({
      model: PropTypes.string.isRequired,
      priceWithIva: PropTypes.number.isRequired
    })
  ).isRequired
};

export default DistributorEquipmentsList;
