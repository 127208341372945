import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LayersIcon from '@mui/icons-material/Layers';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import { getTermMsiList, GracePeriodValues } from './constants/distributors';

export const DistributorEquipmentMenuOptions = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  ENABLE_MSI: 'ENABLE_MSI',
  DISABLE_MSI: 'DISABLE_MSI'
};

export const getMenuOptionsList = (
  isMsiEnabledForEquipment,
  isMsiEnabledForDistributor
) => {
  if (isMsiEnabledForDistributor) {
    if (isMsiEnabledForEquipment) {
      return [
        {
          name: DistributorEquipmentMenuOptions.EDIT,
          label: 'COMMON.EDIT',
          icon: <EditIcon />
        },
        {
          name: DistributorEquipmentMenuOptions.DELETE,
          label: 'COMMON.DELETE',
          icon: <DeleteIcon />
        },
        {
          name: DistributorEquipmentMenuOptions.DISABLE_MSI,
          label: 'COMMON.DISABLE_MSI',
          icon: <LayersClearIcon />
        }
      ];
    }
    return [
      {
        name: DistributorEquipmentMenuOptions.EDIT,
        label: 'COMMON.EDIT',
        icon: <EditIcon />
      },
      {
        name: DistributorEquipmentMenuOptions.DELETE,
        label: 'COMMON.DELETE',
        icon: <DeleteIcon />
      },
      {
        name: DistributorEquipmentMenuOptions.ENABLE_MSI,
        label: 'COMMON.ENABLE_MSI',
        icon: <LayersIcon />
      }
    ];
  }

  return [
    {
      name: DistributorEquipmentMenuOptions.EDIT,
      label: 'COMMON.EDIT',
      icon: <EditIcon />
    },
    {
      name: DistributorEquipmentMenuOptions.DELETE,
      label: 'COMMON.DELETE',
      icon: <DeleteIcon />
    }
  ];
};

export const handleMsiCheckboxChange = (
  e,
  setFieldValue,
  values,
  data,
  dispatch,
  loadDistributorEquipmentsCatalog,
  needToSetTerm = false,
  parameter
) => {
  const isChecked = e.target.checked;
  setFieldValue('isMsiModality', isChecked);
  setFieldValue('residualPayment', 1);
  setFieldValue('distributorEquipments', '');
  setFieldValue('price', '');
  setFieldValue('initialPayment', 0);
  if (isChecked) {
    dispatch(
      loadDistributorEquipmentsCatalog(
        data.distributor.id,
        { enableMsi: true },
        false
      )
    );
    setFieldValue(
      values.initialPayment > 0
        ? GracePeriodValues.ZERO
        : GracePeriodValues.SAME_DAY_AS_CONTRACT_STARTS
    );
  } else {
    dispatch(
      loadDistributorEquipmentsCatalog(
        data.distributor.id,
        { enableMsi: null },
        false
      )
    );
    setFieldValue(
      values.initialPayment > 0
        ? values.gracePeriod
        : GracePeriodValues.SAME_DAY_AS_CONTRACT_STARTS
    );
  }

  if (needToSetTerm) {
    setFieldValue(
      'term',
      isChecked
        ? getTermMsiList(parameter.minMsiTerm, parameter.maxMsiTerm)
        : values.term
    );
  }
};
