import React from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import theme from 'src/theme';
import ResultBuroType from '../ResultBuroEnums';

const ResultBureauView = ({ buroResponse, name }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        justifyContent: 'space-around',
        display: 'flex',
        flexDirection: 'column',
        height: {
          xs: 'auto',
          md: '50vh'
        },
        padding: '20px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          height: '50%',
          alignItems: 'flex-start'
        }}
      >
        <Typography
          variant="body1"
          sx={{
            marginBottom: '15px',
            color: theme.palette.primary.main,
            fontSize: '1.2rem',
            fontWeight: '500'
          }}
        >
          {t('BUREAU_FORM.RESULT.DEAR_USER').replace('{user}', name)}
          ,
        </Typography>

        {buroResponse === ResultBuroType.DENIED && (
          <>
            <Typography
              variant="body1"
              sx={{
                marginBottom: '15px',
                color: theme.palette.common.black,
                fontSize: '1.1rem'
              }}
            >
              {t('BUREAU_FORM.RESULT.THANKS_FOR_APPLYING')}
            </Typography>

            <Typography
              variant="body1"
              sx={{
                marginBottom: '15px',
                color: theme.palette.primary.main,
                fontSize: '1.4rem',
                fontWeight: '500'
              }}
            >
              {t('BUREAU_FORM.RESULT.REJECTED_DESCRIPTION')}
            </Typography>

            <Typography
              variant="body1"
              sx={{
                marginBottom: '15px',
                color: theme.palette.common.black,
                fontSize: '1.1rem'
              }}
            >
              {t('BUREAU_FORM.RESULT.THANKS_FOR_APPLYING_LAST_TEXT')}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginBottom: '15px',
                color: theme.palette.common.black,
                fontSize: '1.1rem'
              }}
            >
              {t('BUREAU_FORM.RESULT.CORDIALLY')}
              ,
            </Typography>
          </>
        )}
        {buroResponse === ResultBuroType.WITH_AVAL && (
          <>
            <Typography
              variant="body1"
              sx={{
                marginBottom: '15px',

                fontWeight: '500',
                color: theme.palette.primary.main,
                fontSize: '1.4rem'
              }}
            >
              {t('BUREAU_FORM.RESULT.APPROVED_WITH_AVAL')}
              <span style={{ textDecoration: 'underline' }}>
                {t('BUREAU_FORM.RESULT.WITH_AVAL')}
              </span>
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginBottom: '15px',
                color: theme.palette.common.black,
                fontSize: '1.1rem'
              }}
            >
              {t('BUREAU_FORM.RESULT.NEXT_STEP')}
            </Typography>

            <ul
              style={{
                marginBottom: '15px',
                color: theme.palette.common.black,
                listStyleType: 'disc',
                paddingLeft: '25px',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <li style={{ listStyleType: 'disc' }}>
                <Typography variant="body1" fontSize="1.1rem">
                  {t('BUREAU_FORM.RESULT.DOCUMENTS.INE')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" fontSize="1.1rem">
                  {t('BUREAU_FORM.RESULT.DOCUMENTS.PERSONAL_ADDRESS')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" fontSize="1.1rem">
                  {t('BUREAU_FORM.RESULT.DOCUMENTS.EQUIPMENT_ADDRESS')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" fontSize="1.1rem">
                  {t('BUREAU_FORM.RESULT.DOCUMENTS.RFC')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" fontSize="1.1rem">
                  {t('BUREAU_FORM.RESULT.DOCUMENTS.BANK_STATEMENT')}
                </Typography>
              </li>
            </ul>
            <Typography
              variant="body1"
              sx={{
                marginBottom: '15px',
                color: theme.palette.common.black,
                fontSize: '1.1rem',
                textDecoration: 'underline',
                fontWeight: '500'
              }}
            >
              {t('BUREAU_FORM.DISCLAIMER')}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginBottom: '15px',
                color: theme.palette.common.black,
                fontSize: '1.1rem'
              }}
            >
              {t('BUREAU_FORM.RESULT.WE_ARE_ON_DISPOSAL')}
            </Typography>
          </>
        )}
        {buroResponse === ResultBuroType.WITHOUT_AVAL && (
          <>
            <Typography
              variant="body1"
              sx={{
                marginBottom: '15px',
                color: theme.palette.primary.main,
                fontWeight: '500',
                fontSize: '1.4rem'
              }}
            >
              {t('BUREAU_FORM.RESULT.APPROVED_WITHOUT_AVAL')}
              <span style={{ textDecoration: 'underline' }}>
                {t('BUREAU_FORM.RESULT.WITHOUT_AVAL')}
              </span>
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginBottom: '15px',
                color: theme.palette.common.black,
                fontSize: '1.1rem'
              }}
            >
              {t('BUREAU_FORM.RESULT.NEXT_STEP')}
            </Typography>

            <ul
              style={{
                marginBottom: '15px',
                color: theme.palette.common.black,
                listStyleType: 'disc',
                paddingLeft: '25px',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <li style={{ listStyleType: 'disc' }}>
                <Typography variant="body1" fontSize="1.1rem">
                  {t('BUREAU_FORM.RESULT.DOCUMENTS.INE')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" fontSize="1.1rem">
                  {t('BUREAU_FORM.RESULT.DOCUMENTS.PERSONAL_ADDRESS')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" fontSize="1.1rem">
                  {t('BUREAU_FORM.RESULT.DOCUMENTS.EQUIPMENT_ADDRESS')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" fontSize="1.1rem">
                  {t('BUREAU_FORM.RESULT.DOCUMENTS.RFC')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" fontSize="1.1rem">
                  {t('BUREAU_FORM.RESULT.DOCUMENTS.BANK_STATEMENT')}
                </Typography>
              </li>
            </ul>
            <Typography
              variant="body1"
              sx={{
                marginBottom: '15px',
                color: theme.palette.common.black,
                fontSize: '1.1rem',
                textDecoration: 'underline',
                fontWeight: '500'
              }}
            >
              {t('BUREAU_FORM.DISCLAIMER')}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginBottom: '15px',
                color: theme.palette.common.black,
                fontSize: '1.1rem'
              }}
            >
              {t('BUREAU_FORM.RESULT.WE_ARE_ON_DISPOSAL')}
            </Typography>
          </>
        )}
        {buroResponse === ResultBuroType.REVIEW && (
          <>
            <Typography
              variant="body1"
              sx={{
                marginBottom: '15px',
                color: theme.palette.primary.main,
                fontWeight: '500',
                fontSize: '1.4rem'
              }}
            >
              {t('BUREAU_FORM.RESULT.ADDITIONAL_REVIEW')}
            </Typography>

            <ul
              style={{
                marginBottom: '15px',
                color: theme.palette.common.black,
                listStyleType: 'none',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}
            >
              <li>
                <Typography variant="body1" fontSize="1.1rem">
                  {t('BUREAU_FORM.RESULT.REASONS.REASON_ONE')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" fontSize="1.1rem">
                  {t('BUREAU_FORM.RESULT.REASONS.REASON_TWO')}
                </Typography>
              </li>
              <li>
                <Typography variant="body1" fontSize="1.1rem">
                  {t('BUREAU_FORM.RESULT.REASONS.REASON_THREE')}
                </Typography>
              </li>
            </ul>
            <Typography
              variant="body1"
              sx={{
                marginBottom: '15px',
                color: theme.palette.common.black,
                fontSize: '1.1rem'
              }}
            >
              {t('BUREAU_FORM.RESULT.FOLLOW_UP')}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginBottom: '15px',
                color: theme.palette.common.black,
                fontSize: '1.1rem'
              }}
            >
              {t('BUREAU_FORM.RESULT.WE_ARE_ON_DISPOSAL')}
            </Typography>
          </>
        )}
        <Typography
          variant="body1"
          sx={{
            marginBottom: '15px',
            color: theme.palette.primary.main,
            fontSize: '1.2rem',
            fontWeight: '500'
          }}
        >
          {t('BUREAU_FORM.RESULT.ASTROCAP')}
        </Typography>
      </Box>
    </Box>
  );
};
ResultBureauView.propTypes = {
  buroResponse: PropTypes.string,
  name: PropTypes.string
};

export default ResultBureauView;
