import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  modalityButton: {
    textTransform: 'none',
    fontSize: '1.2rem',
    padding: '12px',
    borderRadius: '8px',
    border: '1px solid',
    '&:hover': {
      borderColor: 'rgba(25, 118, 210, 0.5)'
    },
    '&.selected': {
      borderColor: theme.palette.primary.main,
      backgroundColor: 'rgba(25, 118, 210, 0.08)',
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: 'rgba(25, 118, 210, 0.15)'
      }
    },
    '&.notSelected': {
      borderColor: theme.palette.grey[300],
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: 'rgba(25, 118, 210, 0.08)'
      }
    }
  }
}));
