import * as Yup from 'yup';
import moment from 'moment-timezone';

export default currentDueDate => {
  const today = moment().startOf('day');
  const dueDateMoment = currentDueDate ? moment(currentDueDate) : today;

  // Get the 25th of the due date's month
  const monthLimit = moment(dueDateMoment)
    .date(25)
    .endOf('day');

  // Get the first day of the due date's month
  const firstDayOfMonth = moment(dueDateMoment).startOf('month');

  // If due date's month is current month, use today as min date
  // Otherwise use first day of the due date's month
  const minDateValue = dueDateMoment.isSame(today, 'month')
    ? today
    : firstDayOfMonth;

  return Yup.object().shape({
    dueDate: Yup.date()
      .required('VALIDATION.REQUIRED')
      .min(minDateValue, 'VALIDATION.DATE_MUST_BE_VALID')
      .max(monthLimit, 'VALIDATION.DATE_MUST_BE_BEFORE_25TH')
      .nullable()
  });
};
