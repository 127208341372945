import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PhoneInput from 'src/components/PhoneInput';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from '../../initialState';

const StepFour = ({ data, handleNext, handleBack, resetRef }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const initialState = useMemo(() => makeInitialState(data), [data]);

  const onNext = useCallback(
    values => {
      return handleNext(values);
    },
    [handleNext]
  );

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={onNext}
      enableReinitialize
    >
      {({
        handleSubmit,
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        resetForm,
        setFieldValue
      }) => {
        resetRef.current = () => resetForm();
        return (
          <form onSubmit={handleSubmit}>
            <TextField
              id="full-name-input"
              InputProps={{
                autoComplete: 'off'
              }}
              error={Boolean(touched.fullName && errors.fullName)}
              fullWidth
              helperText={touched.fullName && t(errors.fullName)}
              label={t('COMMON.FULL_NAME')}
              margin="normal"
              name="fullName"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.fullName}
              variant="outlined"
            />

            <FormControl fullWidth margin="normal">
              <PhoneInput
                id="phone-input"
                name="phone"
                label={t('COMMON.PHONE')}
                value={values.phone}
                onChange={setFieldValue}
                onBlur={handleBlur}
                error={Boolean(touched.phone && errors.phone)}
              />
              {Boolean(touched.phone && errors.phone) && (
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {t(errors.phone)}
                </FormHelperText>
              )}
            </FormControl>

            <TextField
              id="email-input"
              InputProps={{
                autoComplete: 'off'
              }}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && t(errors.email)}
              label={t('COMMON.EMAIL')}
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.email}
              variant="outlined"
            />

            <TextField
              id="comment-input"
              InputProps={{
                autoComplete: 'off'
              }}
              error={Boolean(touched.comment && errors.comment)}
              fullWidth
              helperText={touched.comment && t(errors.comment)}
              label={`${t('COMMON.COMMENT')} (${t('COMMON.OPTIONAL')})`}
              margin="normal"
              name="comment"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.comment}
              variant="outlined"
            />

            <Box
              className={classes.box}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                id="back-button"
                className={classes.buttons}
                onClick={() => handleBack()}
                color="primary"
                variant="outlined"
              >
                {t('COMMON.BACK')}
              </Button>
              <Button
                id="next-button"
                className={clsx(classes.buttons, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
              >
                {t('COMMON.NEXT')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

StepFour.propTypes = {
  data: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  resetRef: PropTypes.object,
  handleBack: PropTypes.func.isRequired
};
export default StepFour;
