/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { useTranslation } from 'react-i18next';
import { CommonActions } from 'src/utils/constants/clients';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';
import {
  deleteMsiDiscountAction,
  editMsiDiscountAction
} from 'src/store/actions/catalogActions';
import { useDispatch } from 'react-redux';
import { formatAmount } from 'src/utils/formatAmout';
import MsiDiscountForm from './MsiDiscountForm';

const MsiDiscountRow = ({ id, name, implicitRate, insurance }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openMSiDiscountForm, setOpenMSiDiscountForm] = useState(false);
  const [openMSiDiscountDelete, setOpenMSiDiscountDelete] = useState(false);

  const handleCloseDeleteModal = () => {
    setOpenMSiDiscountDelete(false);
  };

  const handleCloseModelForm = () => {
    setOpenMSiDiscountForm(false);
  };

  const handleClickMore = option => {
    if (option === CommonActions.EDIT) {
      setOpenMSiDiscountForm(true);
    }
    if (option === CommonActions.DELETE) {
      setOpenMSiDiscountDelete(true);
    }
  };

  const handleDeleteModal = useCallback(() => {
    dispatch(deleteMsiDiscountAction(id, handleCloseDeleteModal));
  }, []);

  const handleEdit = useCallback(
    values => {
      dispatch(editMsiDiscountAction(values, handleCloseModelForm));
    },
    [id]
  );

  return (
    <TableRow hover>
      <TableCell align="start">{name}</TableCell>
      <TableCell align="start">{formatAmount(implicitRate)}%</TableCell>
      <TableCell align="start">{formatAmount(insurance)}%</TableCell>
      <TableCell align="right">
        <MoreMenu handleClickOption={handleClickMore} />
      </TableCell>

      <MsiDiscountForm
        data={{ implicitRate, name, id, insurance }}
        open={openMSiDiscountForm}
        onClose={handleCloseModelForm}
        onEdit={handleEdit}
      />
      <ConfirmationDialog
        open={openMSiDiscountDelete}
        handleClose={handleCloseDeleteModal}
        title={t('CATALOGS_VIEW.MSI_DISCOUNT.DELETE_MSI_DISCOUNT')}
        description={t(
          'CATALOGS_VIEW.MSI_DISCOUNT.DELETE_MSI_DISCOUNT_DESCRIPTION'
        )}
        onSubmit={handleDeleteModal}
      />
    </TableRow>
  );
};

MsiDiscountRow.propTypes = {
  name: PropTypes.string,
  implicitRate: PropTypes.number,
  id: PropTypes.string,
  insurance: PropTypes.number
};

export default MsiDiscountRow;
