import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Paper } from '@mui/material';
import { QuoteModality } from 'src/utils/constants/quotes';
import useStyles from './styles';
import { getInitialEquipmentState } from '../StepTwo/initialState';

const StepOne = ({ data, onNext }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedModality, setSelectedModality] = React.useState(data.modality);

  const handleModalityChange = newModality => {
    const modalityChanged = data.modality && data.modality !== newModality;
    setSelectedModality(newModality);
    if (modalityChanged) {
      const nextState = {
        ...data,
        modality: newModality,
        distributor: null,
        totalPriceWithIva: null,
        modalityChanged,
        ...getInitialEquipmentState()
      };
      onNext(nextState);
    } else {
      onNext({ ...data, modality: newModality });
    }
  };

  const getButtonClass = modality =>
    `${classes.modalityButton} ${
      selectedModality === modality ? 'selected' : 'notSelected'
    }`;

  return (
    <Box component="form">
      <Paper sx={{ p: 3, mb: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Button
            aria-label={t('QUOTES_VIEW.MODALITIES.NORMAL')}
            className={getButtonClass(QuoteModality.NORMAL)}
            onClick={() => handleModalityChange(QuoteModality.NORMAL)}
          >
            {t('QUOTES_VIEW.MODALITIES.NORMAL')}
          </Button>
          <Button
            aria-label={t('QUOTES_VIEW.MODALITIES.MSI')}
            className={getButtonClass(QuoteModality.MSI)}
            onClick={() => handleModalityChange(QuoteModality.MSI)}
          >
            {t('QUOTES_VIEW.MODALITIES.MSI')}
          </Button>
          <Button
            aria-label={t('QUOTES_VIEW.MODALITIES.FIRST_UPFRONT_RENT')}
            className={getButtonClass(QuoteModality.FIRST_UPFRONT_RENT)}
            onClick={() =>
              handleModalityChange(QuoteModality.FIRST_UPFRONT_RENT)
            }
          >
            {t('QUOTES_VIEW.MODALITIES.FIRST_UPFRONT_RENT')}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

StepOne.propTypes = {
  data: PropTypes.shape({
    modality: PropTypes.string,
    selectedEquipmentQuantities: PropTypes.object,
    totalQuantity: PropTypes.number
  }).isRequired,
  onNext: PropTypes.func.isRequired
};

export default StepOne;
