import React from 'react';
import { Box, Container } from '@mui/material';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';
import AutomaticDebitCollectionList from './automaticDebitCollectionView/AutomaticDebitCollectionList';
import Toolbar from './automaticDebitCollectionView/Toolbar';
import useStyles from './styles';

const AutomaticDebitCollectionsView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page
      className={classes.ContainerRoot}
      title={t('NAVIGATION.AUTOMATIC_DEBIT_COLLECTIONS')}
    >
      <FadeIn transitionDuration={200}>
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <AutomaticDebitCollectionList />
          </Box>
        </Container>
      </FadeIn>
    </Page>
  );
};

export default AutomaticDebitCollectionsView;
