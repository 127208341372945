/* eslint-disable import/no-cycle */
import { push } from 'connected-react-router';
import { sendDistributorToValidateById } from 'src/services/clientService';
import getDistributors, {
  createNewDistributor,
  createNewDistributorCatalog,
  createNewDistributorContact,
  deleteDistributorCatalogById,
  deleteDistributorContactById,
  downloadExportedDistributors,
  getDistributorById,
  getDistributorCatalogs,
  getDistributorContacts,
  updateDistributorById,
  updateDistributorCatalogById,
  getDistributorAgents,
  createNewDistributorAgent,
  updateDistributorAgentById,
  updateDistributorContactById,
  getDistributorParamsById,
  getDistributorProfileByClient as getDistributorProfileByClientService,
  uploadDistributorCatalogWithExcel
} from 'src/services/distributorService';
import { removePointsPhone } from 'src/utils/common';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import UserType, { DistributorRoles } from 'src/utils/constants/auth';
import {
  createNewUserDistributorCatalog,
  createNewUserDistributorContact,
  deleteUserDistributorCatalog,
  deleteUserDistributorContact,
  getKamAssociattedWithClient,
  getDistributorSelfParameters,
  getUserDistributorCatalogs,
  updateUserDistributorCatalog,
  updateUserDistributorContact,
  getUserDistributorContacts,
  uploadDistributorCatalogWithExcel as userUploadDistributorCatalogWithExcel
} from 'src/services/userDistributorService';
import { SnackBarVariants } from 'src/utils/constants/common';
import { getKams } from 'src/services/userKamService';
import { selectDistributorQueryOptions } from '../selectors/distributorSelectors';
import { showMessage } from './snackBarActions';

export const LOAD_DISTRIBUTORS = asyncActionCreator('LOAD_DISTRIBUTORS');
export const DOWNLOAD_DISTRIBUTOR = asyncActionCreator('DOWNLOAD_DISTRIBUTOR');
export const LOAD_DISTRIBUTOR_DETAIL = asyncActionCreator(
  'LOAD_DISTRIBUTOR_DETAIL'
);
export const LOAD_KAM_DISTRIBUTOR_PROFILE = asyncActionCreator(
  'LOAD_KAM_DISTRIBUTOR_PROFILE'
);
export const UPDATE_DISTRIBUTOR = asyncActionCreator('UPDATE_DISTRIBUTOR');
export const CREATE_DISTRIBUTOR = asyncActionCreator('CREATE_DISTRIBUTOR');
export const LOAD_DISTRIBUTOR_CONTACTS = asyncActionCreator(
  'LOAD_DISTRIBUTOR_CONTACTS'
);
export const CREATE_DISTRIBUTOR_CONTACT = asyncActionCreator(
  'CREATE_DISTRIBUTOR_CONTACT'
);
export const UPDATE_DISTRIBUTOR_CONTACT = asyncActionCreator(
  'UPDATE_DISTRIBUTOR_CONTACT'
);
export const DELETE_DISTRIBUTOR_CONTACT = asyncActionCreator(
  'DELETE_DISTRIBUTOR_CONTACT'
);
export const CLEAR_DISTRIBUTORS = 'CLEAR_DISTRIBUTORS';
export const CLEAR_DISTRIBUTOR_DETAIL = 'CLEAR_DISTRIBUTOR_DETAIL';
export const SAVE_DISTRIBUTORS_OPTIONS = 'SAVE_DISTRIBUTORS_OPTIONS';
export const CLEAR_DISTRIBUTORS_OPTIONS = 'CLEAR_DISTRIBUTORS_OPTIONS';

// Distributor Catalog
export const LOAD_DISTRIBUTOR_CATALOGS = asyncActionCreator(
  'LOAD_DISTRIBUTOR_CATALOGS'
);
export const CREATE_DISTRIBUTOR_CATALOG = asyncActionCreator(
  'CREATE_DISTRIBUTOR_CATALOG'
);
export const UPDATE_DISTRIBUTOR_CATALOG = asyncActionCreator(
  'UPDATE_DISTRIBUTOR_CATALOG'
);
export const DELETE_DISTRIBUTOR_CATALOG = asyncActionCreator(
  'DELETE_DISTRIBUTOR_CATALOG'
);

export const CLEAR_DISTRIBUTORS_CATALOGS = 'CLEAR_DISTRIBUTORS_CATALOGS';

// Distributor Agents

export const LOAD_DISTRIBUTOR_AGENTS = asyncActionCreator(
  'LOAD_DISTRIBUTOR_AGENTS'
);
export const LOAD_CLIENT_DISTRIBUTOR_AGENT = asyncActionCreator(
  'LOAD_CLIENT_DISTRIBUTOR_AGENT'
);
export const CREATE_DISTRIBUTOR_AGENT = asyncActionCreator(
  'CREATE_DISTRIBUTOR_AGENT'
);
export const UPDATE_DISTRIBUTOR_AGENT = asyncActionCreator(
  'UPDATE_DISTRIBUTOR_AGENT'
);
export const SAVE_DISTRIBUTORS_AGENTS_OPTIONS =
  'SAVE_DISTRIBUTORS_AGENTS_OPTIONS';
export const CLEAR_DISTRIBUTORS_AGENTS_OPTIONS =
  'CLEAR_DISTRIBUTORS_AGENTS_OPTIONS';
export const CLEAR_DISTRIBUTORS_AGENTS = 'CLEAR_DISTRIBUTORS_AGENTS';
export const UPDATE_DISTRIBUTOR_VALIDATION_STATE = asyncActionCreator(
  'UPDATE_DISTRIBUTOR_VALIDATION_STATE'
);

export const SEND_TO_VALIDATE = asyncActionCreator('SEND_TO_VALIDATE');
export const LOAD_DISTRIBUTOR_PARAMETER = asyncActionCreator(
  'LOAD_DISTRIBUTOR_PARAMETER'
);
export const UPLOAD_CATALOG_IN_EXCEL = asyncActionCreator(
  'UPLOAD_CATALOG_IN_EXCEL'
);
export const SET_DISTRIBUTOR_CATALOGS_FAIL_UPLOAD =
  'SET_DISTRIBUTOR_CATALOGS_FAIL_UPLOAD';

export const CALCULATE_DISCOUNT_GRID = asyncActionCreator(
  'CALCULATE_DISCOUNT_GRID'
);

export function getOptions(getState, sendLimit = true) {
  const queryOpts = selectDistributorQueryOptions(getState());

  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.enableMsi !== undefined) {
    reqOptions.enableMsi = queryOpts.enableMsi;
  }
  if (queryOpts.enableUpfrontRent !== undefined) {
    reqOptions.enableUpfrontRent = queryOpts.enableUpfrontRent;
  }
  if (queryOpts.period) {
    reqOptions.period = queryOpts.period;
  }
  if (queryOpts.name) {
    reqOptions.name = queryOpts.name;
  }
  if (queryOpts.role) {
    reqOptions.role = queryOpts.role;
  }
  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  if (queryOpts.enableMsi) {
    reqOptions.enableMsi = queryOpts.enableMsi;
  }
  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_DISTRIBUTORS_OPTIONS });
};

export const loadDistributors = (options, sendLimit) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOAD_DISTRIBUTORS.start });

    dispatch({
      type: SAVE_DISTRIBUTORS_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState, sendLimit);

    const dstributors = await getDistributors(reqOptions);

    return dispatch({
      type: LOAD_DISTRIBUTORS.success,
      ...dstributors
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_DISTRIBUTORS.failure });
  }
};

export const downloadDistributor = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DOWNLOAD_DISTRIBUTOR.start });

    const reqOptions = getOptions(getState, false);

    await downloadExportedDistributors(reqOptions);

    return dispatch({ type: DOWNLOAD_DISTRIBUTOR.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DOWNLOAD_DISTRIBUTOR.failure });
  }
};

export const loadDistributorDetail = id => async dispatch => {
  try {
    dispatch({
      type: LOAD_DISTRIBUTOR_DETAIL.start
    });

    const data = await getDistributorById(id);

    return dispatch({
      type: LOAD_DISTRIBUTOR_DETAIL.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_DISTRIBUTOR_DETAIL.failure });
  }
};

export const updateDistributor = (
  id,
  values,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_DISTRIBUTOR.start });

    let body = {};

    if (values.equipmentType) {
      body = { ...values, equipmentTypeId: values.equipmentType.id };
    } else {
      body = values;
    }

    await updateDistributorById({
      id,
      values: body
    });

    dispatch(
      showMessage({
        message: 'UPDATE_DISTRIBUTOR',
        variant: 'success'
      })
    );
    dispatch(loadDistributorDetail(id));
    return dispatch({ type: UPDATE_DISTRIBUTOR.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    closeCallback();
    return dispatch({ type: UPDATE_DISTRIBUTOR.failure });
  }
};

export const createDistributor = (values, closeCallback) => async dispatch => {
  try {
    dispatch({ type: CREATE_DISTRIBUTOR.start });

    const id = await createNewDistributor({ ...values });

    closeCallback();
    dispatch(
      showMessage({
        message: 'CREATE_DISTRIBUTOR',
        variant: 'success'
      })
    );
    dispatch(push(`/distributors/business/${id}`));
    return dispatch({ type: CREATE_DISTRIBUTOR.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    closeCallback();
    return dispatch({ type: CREATE_DISTRIBUTOR.failure });
  }
};

export const loadDistributorContactsList = (id, options = {}) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOAD_DISTRIBUTOR_CONTACTS.start });

    dispatch({
      type: SAVE_DISTRIBUTORS_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState);

    const { auth } = getState();

    if (auth.system === UserType.DISTRIBUTOR) {
      const data = await getUserDistributorContacts(reqOptions);

      return dispatch({
        type: LOAD_DISTRIBUTOR_CONTACTS.success,
        ...data
      });
    }

    const data = await getDistributorContacts({ id, options: reqOptions });

    return dispatch({
      type: LOAD_DISTRIBUTOR_CONTACTS.success,
      ...data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_DISTRIBUTOR_CONTACTS.failure });
  }
};

export const createDistributorContact = (id, values) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: CREATE_DISTRIBUTOR_CONTACT.start });

    const { auth } = getState();

    if (auth.role === DistributorRoles.DIRECTOR) {
      await createNewUserDistributorContact({
        values: {
          ...values,
          phone: removePointsPhone(values.phone)
        }
      });
      dispatch(loadDistributorContactsList());
    } else {
      await createNewDistributorContact({
        id,
        values: {
          ...values,
          phone: removePointsPhone(values.phone)
        }
      });

      dispatch(loadDistributorContactsList(id));
    }

    dispatch(
      showMessage({
        message: 'CREATE_DISTRIBUTOR_CONTACT',
        variant: 'success'
      })
    );
    return dispatch({ type: CREATE_DISTRIBUTOR_CONTACT.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CREATE_DISTRIBUTOR_CONTACT.failure });
  }
};

export const updateDistributorContact = (id, distributorId, values) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: UPDATE_DISTRIBUTOR_CONTACT.start });
    let phone;

    if (values.phone) {
      phone = removePointsPhone(values.phone);
    }
    const { auth } = getState();

    if (auth.role === DistributorRoles.DIRECTOR) {
      await updateUserDistributorContact({
        id,
        values: {
          ...values,
          phone
        }
      });
      dispatch(loadDistributorContactsList());
    } else {
      await updateDistributorContactById({
        id,
        values: {
          ...values,
          phone
        }
      });

      dispatch(loadDistributorContactsList(distributorId));
    }

    dispatch(
      showMessage({
        message: 'UPDATE_DISTRIBUTOR_CONTACT',
        variant: 'success'
      })
    );
    return dispatch({ type: UPDATE_DISTRIBUTOR_CONTACT.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPDATE_DISTRIBUTOR_CONTACT.failure });
  }
};

export const deleteDistributorContact = (id, distributorId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: DELETE_DISTRIBUTOR_CONTACT.start });

    const { auth } = getState();

    if (auth.role === DistributorRoles.DIRECTOR) {
      await deleteUserDistributorContact(id);
      dispatch(loadDistributorContactsList());
    } else {
      await deleteDistributorContactById(id);
      dispatch(loadDistributorContactsList(distributorId));
    }

    dispatch(
      showMessage({
        message: 'DELETE_DISTRIBUTOR_CONTACT',
        variant: 'success'
      })
    );
    return dispatch({ type: DELETE_DISTRIBUTOR_CONTACT.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DELETE_DISTRIBUTOR_CONTACT.failure });
  }
};

export const loadDistributorEquipmentsCatalog = (
  id,
  options,
  sendLimit = true
) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_DISTRIBUTOR_CATALOGS.start });

    dispatch({
      type: SAVE_DISTRIBUTORS_OPTIONS,
      options
    });
    const { auth } = getState();
    const reqOptions = getOptions(getState, sendLimit);

    let catalogs = null;

    if (auth.system === UserType.DISTRIBUTOR) {
      catalogs = await getUserDistributorCatalogs({
        options: reqOptions
      });
    } else {
      catalogs = await getDistributorCatalogs({
        id,
        options: reqOptions
      });
    }

    return dispatch({
      type: LOAD_DISTRIBUTOR_CATALOGS.success,
      ...catalogs
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_DISTRIBUTOR_CATALOGS.failure });
  }
};

export const createDistributorCatalog = (id, values) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: CREATE_DISTRIBUTOR_CATALOG.start });
    const { auth } = getState();

    const newValues = {
      ...values,
      brandId: values.brand.id
    };

    if (auth.system === UserType.DISTRIBUTOR) {
      await createNewUserDistributorCatalog(newValues);
    } else {
      await createNewDistributorCatalog({ id, values: newValues });
    }

    dispatch(
      showMessage({
        message: 'CREATE_DISTRIBUTOR_CATALOG',
        variant: 'success'
      })
    );
    dispatch(loadDistributorEquipmentsCatalog(id));
    return dispatch({ type: CREATE_DISTRIBUTOR_CATALOG.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CREATE_DISTRIBUTOR_CATALOG.failure });
  }
};

export const uploadCatalogInExcel = (
  file,
  closeCallback,
  openFailCatalogUpload,
  distributorId
) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPLOAD_CATALOG_IN_EXCEL.start });

    const { auth } = getState();

    const formData = new FormData();
    formData.append('file', file.file);

    let response = null;

    if (auth.system === UserType.DISTRIBUTOR) {
      response = await userUploadDistributorCatalogWithExcel(formData);
    } else {
      response = await uploadDistributorCatalogWithExcel({
        formData,
        id: distributorId
      });
    }

    if (response.data) {
      closeCallback();
      openFailCatalogUpload();
      dispatch({
        type: SET_DISTRIBUTOR_CATALOGS_FAIL_UPLOAD,
        payload: response.data
      });
      dispatch(
        showMessage({
          message: 'CREATE_DISTRIBUTOR_CATALOG_WITH_ERROR',
          variant: SnackBarVariants.ERROR
        })
      );
    } else {
      dispatch(
        showMessage({
          message: 'CREATE_DISTRIBUTOR_CATALOG',
          variant: SnackBarVariants.SUCCESS
        })
      );
      closeCallback();
    }
    dispatch(
      auth === UserType.DISTRIBUTOR
        ? loadDistributorEquipmentsCatalog()
        : loadDistributorEquipmentsCatalog(distributorId)
    );
    return dispatch({ type: UPLOAD_CATALOG_IN_EXCEL.success });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    closeCallback();
    return dispatch({ type: UPLOAD_CATALOG_IN_EXCEL.failure });
  }
};

export const updateDistributorCatalog = (id, distributorId, values) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: UPDATE_DISTRIBUTOR_CATALOG.start });
    const { auth } = getState();

    let newValues = values;
    if (values.brand) {
      newValues = {
        ...values,
        brandId: values.brand.id
      };
    }

    if (auth.role === DistributorRoles.DIRECTOR) {
      await updateUserDistributorCatalog({ id, values: newValues });
    } else {
      await updateDistributorCatalogById({ id, values: newValues });
    }

    dispatch(
      showMessage({
        message: 'UPDATE_DISTRIBUTOR_CATALOG',
        variant: 'success'
      })
    );
    dispatch(loadDistributorEquipmentsCatalog(distributorId));
    return dispatch({ type: UPDATE_DISTRIBUTOR_CATALOG.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPDATE_DISTRIBUTOR_CATALOG.failure });
  }
};

export const deleteDistributorCatalog = (id, distributorId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: DELETE_DISTRIBUTOR_CATALOG.start });
    const { auth } = getState();

    if (auth.role === DistributorRoles.DIRECTOR) {
      await deleteUserDistributorCatalog(id);
    } else {
      await deleteDistributorCatalogById(id);
    }

    dispatch(
      showMessage({
        message: 'DELETE_DISTRIBUTOR_CATALOG',
        variant: 'success'
      })
    );
    dispatch(loadDistributorEquipmentsCatalog(distributorId));
    return dispatch({ type: DELETE_DISTRIBUTOR_CATALOG.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DELETE_DISTRIBUTOR_CATALOG.failure });
  }
};

export const loadDistributorAgents = (id, options, sendLimit = true) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOAD_DISTRIBUTOR_AGENTS.start });
    const { auth } = getState();
    dispatch({
      type: SAVE_DISTRIBUTORS_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState, sendLimit);
    let agents = null;

    if (auth.system === UserType.DISTRIBUTOR) {
      const reponse = await getKams();
      agents = reponse;
    } else {
      const response = await getDistributorAgents({ options: reqOptions, id });
      agents = response;
    }

    return dispatch({
      type: LOAD_DISTRIBUTOR_AGENTS.success,
      ...agents
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_DISTRIBUTOR_AGENTS.failure });
  }
};

export const createDistributorAgent = (id, values) => async dispatch => {
  try {
    dispatch({ type: CREATE_DISTRIBUTOR_AGENT.start });

    await createNewDistributorAgent({
      id,
      values: {
        ...values,
        phone: removePointsPhone(values.phone),
        officePhone: removePointsPhone(values.officePhone),
        distributorId: id
      }
    });

    dispatch(
      showMessage({
        message: 'CREATE_DISTRIBUTOR_AGENT',
        variant: 'success'
      })
    );

    dispatch(loadDistributorAgents(id));
    return dispatch({ type: CREATE_DISTRIBUTOR_AGENT.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CREATE_DISTRIBUTOR_AGENT.failure });
  }
};

export const updateDistributorAgent = (distributorId, id, values) => {
  return async dispatch => {
    try {
      dispatch({ type: UPDATE_DISTRIBUTOR_AGENT.start });
      let phone;
      let officePhone;

      if (values.phone) {
        phone = removePointsPhone(values.phone);
      }
      if (values.officePhone) {
        officePhone = removePointsPhone(values.officePhone);
      }

      await updateDistributorAgentById({
        id,
        values: {
          ...values,
          phone,
          officePhone
        }
      });

      dispatch(
        showMessage({
          message: 'UPDATE_DISTRIBUTOR_AGENT',
          variant: 'success'
        })
      );
      dispatch(loadDistributorAgents(distributorId));
      return dispatch({ type: UPDATE_DISTRIBUTOR_AGENT.success });
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: 'error' }));
      return dispatch({ type: UPDATE_DISTRIBUTOR_AGENT.failure });
    }
  };
};
export const updateValidationState = id => async dispatch => {
  try {
    dispatch({ type: UPDATE_DISTRIBUTOR_VALIDATION_STATE.start });

    const { nufiValidation, nufiScore } = await getDistributorById(id);

    return dispatch({
      type: UPDATE_DISTRIBUTOR_VALIDATION_STATE.success,
      nufiValidation,
      nufiScore
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: UPDATE_DISTRIBUTOR_VALIDATION_STATE.failure });
  }
};

export const sendToValidate = (
  distributorId,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: SEND_TO_VALIDATE.start });

    if (distributorId) {
      await sendDistributorToValidateById(Number(distributorId));
    }

    dispatch(
      showMessage({
        message: 'VALIDATE_SUCCESS',
        variant: 'success'
      })
    );

    closeCallback();
    return dispatch({
      type: SEND_TO_VALIDATE.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    closeCallback();
    return dispatch({ type: SEND_TO_VALIDATE.failure });
  }
};

export const getDistributorParameters = id => async (dispatch, getState) => {
  try {
    const { auth } = getState();

    dispatch({ type: LOAD_DISTRIBUTOR_PARAMETER.start });

    if (auth.system === UserType.ADMIN) {
      const data = await getDistributorParamsById(id);

      return dispatch({
        type: LOAD_DISTRIBUTOR_PARAMETER.success,
        data
      });
    }

    const data = await getDistributorSelfParameters();

    return dispatch({
      type: LOAD_DISTRIBUTOR_PARAMETER.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_DISTRIBUTOR_PARAMETER.failure });
  }
};

export const updateDistributorParams = (
  id,
  values,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_DISTRIBUTOR.start });

    await updateDistributorById({
      id,
      values
    });

    dispatch(
      showMessage({
        message: 'UPDATE_DISTRIBUTOR',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch(getDistributorParameters(id));
    return dispatch({ type: UPDATE_DISTRIBUTOR.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    closeCallback();
    return dispatch({ type: UPDATE_DISTRIBUTOR.failure });
  }
};

export const getDistributorProfileByClient = (
  id,
  clientId,
  setDistributorProfile,
  setFieldDisabled
) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_CLIENT_DISTRIBUTOR_AGENT.start });

    const { auth } = getState();

    if (auth.system === UserType.DISTRIBUTOR) {
      const { distributorProfile } = await getKamAssociattedWithClient(
        clientId
      );
      if (distributorProfile) {
        setDistributorProfile(distributorProfile);
        setFieldDisabled(true);
      } else {
        setFieldDisabled(false);
      }
      return dispatch({
        type: LOAD_CLIENT_DISTRIBUTOR_AGENT.success
      });
    }

    const { distributorProfile } = await getDistributorProfileByClientService({
      id,
      clientId
    });

    if (distributorProfile) {
      setDistributorProfile(distributorProfile);
      setFieldDisabled(true);
    } else {
      setFieldDisabled(false);
    }

    return dispatch({
      type: LOAD_CLIENT_DISTRIBUTOR_AGENT.success
    });
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    return dispatch({ type: LOAD_CLIENT_DISTRIBUTOR_AGENT.failure });
  }
};

export const getDistributorProfileAssignedToClient = (
  id,
  clientId,
  setValueCallback
) => async (dispatch, getState) => {
  try {
    const { auth } = getState();

    if (auth.system === UserType.DISTRIBUTOR) {
      const { distributorProfile } = await getKamAssociattedWithClient(
        clientId
      );
      return setValueCallback(distributorProfile);
    }
    const { distributorProfile } = await getDistributorProfileByClientService({
      id,
      clientId
    });
    return setValueCallback(distributorProfile);
  } catch (error) {
    dispatch(
      showMessage({
        message: error.message,
        variant: SnackBarVariants.ERROR
      })
    );
    return dispatch({ type: LOAD_CLIENT_DISTRIBUTOR_AGENT.failure });
  }
};
