import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DialogTransition from 'src/components/DialogTransition';
import useStyles from './styles';

const CancelAutomaticDebitCollectionDialog = ({
  open,
  onClose,
  automaticDebitCollectionId,
  onSubmit
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleSubmit = () => {
    onSubmit(automaticDebitCollectionId);
  };

  if (!open) return null;

  return (
    <Dialog
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      keepMounted
      style={{ marginBottom: '1.5rem' }}
      TransitionComponent={DialogTransition}
    >
      <DialogTitle
        className={classes.header}
        id="cancel-automatic-debit-dialog-title"
        variant="h2"
        color="primary"
      >
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        {t('ENUMS.AUTOMATIC_DEBIT.CANCEL_TITLE')}
      </DialogTitle>
      <DialogContent id="cancel-automatic-debit-dialog">
        <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 3 }}>
          {t('ENUMS.AUTOMATIC_DEBIT.CANCEL_CONFIRMATION_MESSAGE')}
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: '0.5rem auto'
          }}
        >
          <Button
            onClick={onClose}
            color="primary"
            variant="outlined"
            sx={{ minWidth: '45%' }}
          >
            {t('COMMON.CANCEL')}
          </Button>

          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            sx={{ minWidth: '45%' }}
          >
            {t('COMMON.ACCEPT')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

CancelAutomaticDebitCollectionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  automaticDebitCollectionId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default CancelAutomaticDebitCollectionDialog;
