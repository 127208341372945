import { asyncActionCreator } from 'src/utils/loadingUtils';
import {
  getPaymentFailures,
  cancelPayment,
  editPaymentDueDate,
  sendToBillPayment
} from 'src/services/paymentService';
import { SnackBarVariants } from 'src/utils/constants/common';
import { showMessage } from './snackBarActions';
import { selectPaymentQueryOptions } from '../selectors/paymentSelectors';

export const LOAD_PAYMENTS = asyncActionCreator('LOAD_PAYMENTS');
export const SAVE_PAYMENTS_OPTIONS = 'SAVE_PAYMENTS_OPTIONS';
export const CLEAR_PAYMENTS_OPTIONS = 'CLEAR_PAYMENT_OPTIONS';
export const LOAD_PAYMENTS_LIST = asyncActionCreator('LOAD_PAYMENTS_LIST');
export const CANCEL_PAYMENT = asyncActionCreator('CANCEL_PAYMENT');
export const CLEAR_PAYMENT = 'CLEAR_PAYMENT';
export const EDIT_PAYMENT_DUE_DATE = asyncActionCreator(
  'EDIT_PAYMENT_DUE_DATE'
);
export const SEND_PAYMENT_TO_REBILL = asyncActionCreator(
  'SEND_PAYMENT_TO_REBILL'
);
export const CLEAR_PAYMENT_OPTIONS = 'CLEAR_PAYMENT_OPTIONS';

function getOptions(getState, sendLimit = true) {
  const queryOpts = selectPaymentQueryOptions(getState());
  const reqOptions = {};

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }
  if (queryOpts.date) {
    reqOptions.dueDate = queryOpts.date;
  }
  return reqOptions;
}

export const clearPaymentQueryOptions = () => dispatch => {
  dispatch({ type: CLEAR_PAYMENTS_OPTIONS });
};

export const setQueryOptions = options => dispatch => {
  dispatch({
    type: SAVE_PAYMENTS_OPTIONS,
    options
  });
};

const loadPayments = options => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOAD_PAYMENTS_LIST.start
    });

    dispatch({
      type: SAVE_PAYMENTS_OPTIONS,
      options
    });
    const reqOptions = getOptions(getState);

    const data = await getPaymentFailures(reqOptions);

    return dispatch({
      type: LOAD_PAYMENTS_LIST.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_PAYMENTS_LIST.failure });
  }
};

export const cancelPaymentAction = (id, closeCallback) => async dispatch => {
  try {
    dispatch({
      type: CANCEL_PAYMENT.start
    });

    await cancelPayment(id);

    closeCallback();

    dispatch(
      showMessage({
        message: 'CANCEL_PAYMENT',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch(loadPayments());

    return dispatch({
      type: CANCEL_PAYMENT.success
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: CANCEL_PAYMENT.failure });
  }
};

export const editPaymentDueDateAction = (
  id,
  dueDate,
  closeCallback
) => async dispatch => {
  try {
    dispatch({
      type: EDIT_PAYMENT_DUE_DATE.start
    });
    await editPaymentDueDate({ id, dueDate });

    closeCallback();

    dispatch(
      showMessage({
        message: 'EDIT_PAYMENT_DUE_DATE',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch(loadPayments());

    return dispatch({
      type: EDIT_PAYMENT_DUE_DATE.success
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: EDIT_PAYMENT_DUE_DATE.failure });
  }
};
export const billPaymentAction = id => async dispatch => {
  try {
    dispatch({ type: SEND_PAYMENT_TO_REBILL.start });
    await sendToBillPayment(id);
    dispatch(
      showMessage({
        message: 'PAYMENT_BILLED',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch(loadPayments());

    return dispatch({ type: SEND_PAYMENT_TO_REBILL.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: SEND_PAYMENT_TO_REBILL.failure });
  }
};

export default loadPayments;
