// eslint-disable-next-line import/prefer-default-export
export const QuoteStatus = {
  DRAFT: 'DRAFT',
  IN_REVIEW: 'IN_REVIEW',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  ARCHIVED: 'ARCHIVED'
};

export const Quoters = {
  MSI_RATE: 'msi-rate',
  QUOTE_CATEGORIES: 'quote-categories',
  DISCOUNT_GRID: 'discount-grid',
  RATE_ADJUSTMENT: 'rate-adjustment'
};

export const MinInitialPaymentValues = {
  ZERO: 0,
  TEN: 10,
  TWENTY: 20,
  THIRTY: 30
};

export const MinAndMaxTermValues = {
  TWELVE: 12,
  EIGHTEEN: 18,
  TWENTY_FOUR: 24,
  THIRTY_SIX: 36,
  FORTY_EIGTH: 48
};

export const KamAssignationStatus = {
  ASSIGNED: 'ASSIGNED',
  UNASSIGNED: 'UNASSIGNED'
};

export const DirectorQuotesTabsIndex = {
  QUOTES: 'QUOTES',
  MY_QUOTES: 'MY_QUOTES'
};

export const QuoteCreationOption = {
  // Commented it out for not being used at the moment
  // CREATE_QUOTE: 'CREATE_QUOTE',
  QUICK_QUOTE: 'QUICK_QUOTE',
  RENT_CALCULATOR: 'RENT_CALCULATOR',
  MULTIEQUIPMENT_QUOTE: 'MULTIEQUIPMENT_QUOTE'
};

export const PaymentInputOption = {
  PERCENTAGE: 'PERCENTAGE',
  AMOUNT: 'AMOUNT'
};

export const MaxGracePeriodForTwelveMonthsTerm = 60;

export const QuotesIconStatusAndColor = {
  OK: { status: 'OK', color: '#000000' },
  WARNING: {
    status: 'WARNING',
    color: '#000000'
  },
  ERROR: { status: ' ERROR', color: '#000000' },
  NONE: { status: 'NONE', color: '#000000' }
};

export const QuoteMultiEquipment = {
  MULTIEQUIPMENT: 'MULTIEQUIPMENT'
};

export const QuoteModality = {
  NORMAL: 'NORMAL',
  MSI: 'MSI',
  FIRST_UPFRONT_RENT: 'FIRST_UPFRONT_RENT'
};
