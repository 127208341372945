import React, { useCallback, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import TabPanel from 'src/components/TabPanel/TabPanel';
import Tab from 'src/components/Tab';
import { Quoters } from 'src/utils/constants/quotes';
import { useDispatch } from 'react-redux';
import loadQuoteCategories, {
  CLEAR_MSI_OPTIONS
} from 'src/store/actions/quoteActions';
import { loadMsiDiscount } from 'src/store/actions/catalogActions';
import MsiDiscountListView from 'src/views/catalog/MsiDiscountListView';
import {
  useHistory,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import QuoteCategoriesList from './QuoteCategoriesList';
import DiscountGridView from '../DiscountGrid/DiscountGridView';
import RateAdjustmentsView from '../RateAdjustement/RateAdjustmentsView';

const QuotersMenuList = [
  {
    value: Quoters.QUOTE_CATEGORIES,
    label: 'ENUMS.QUOTERS.QUOTE_CATEGORIES',
    description: 'ENUMS.QUOTERS.QUOTE_CATEGORIES'
  },
  {
    value: Quoters.MSI_RATE,
    label: 'ENUMS.QUOTERS.MSI_RATE',
    description: 'ENUMS.QUOTERS.MSI_RATE'
  },
  {
    value: Quoters.DISCOUNT_GRID,
    label: 'ENUMS.QUOTERS.MSI_DICOUNT_GRID',
    description: 'ENUMS.QUOTERS.MSI_DICOUNT_GRID'
  },
  {
    value: Quoters.RATE_ADJUSTMENT,
    label: 'ENUMS.QUOTERS.RATE_ADJUSTMENT',
    description: 'ENUMS.QUOTERS.RATE_ADJUSTMENT'
  }
];

const QuotesCategoriesListTabs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { tab } = useParams();
  const [value, setValue] = useState(tab || Quoters.QUOTE_CATEGORIES);

  const handleChangeTabs = useCallback(
    (_, newValue) => {
      setValue(newValue);
      push(`/quoters/categories/${newValue}`);
      dispatch({ type: CLEAR_MSI_OPTIONS });

      if (newValue === Quoters.MSI_RATE) {
        dispatch(loadMsiDiscount());
      } else if (newValue === Quoters.QUOTE_CATEGORIES) {
        dispatch(loadQuoteCategories());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!tab || !Object.values(Quoters).includes(tab)) {
      setValue(Quoters.QUOTE_CATEGORIES);
      push(`/quoters/categories/${Quoters.QUOTE_CATEGORIES}`);
      dispatch(loadQuoteCategories());
    } else {
      setValue(tab);
    }
  }, [tab, dispatch]);

  return (
    <Box sx={{ flexGrow: 1, mt: '2rem' }}>
      <AppBar
        elevation={0}
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChangeTabs}
        >
          {QuotersMenuList.map(item => (
            <Tab
              key={item.value}
              label={t(item.label)}
              value={item.value}
              description={t(item.description)}
            />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={Quoters.MSI_RATE}>
        <MsiDiscountListView />
      </TabPanel>
      <TabPanel value={value} index={Quoters.QUOTE_CATEGORIES}>
        <QuoteCategoriesList />
      </TabPanel>
      <TabPanel value={value} index={Quoters.DISCOUNT_GRID}>
        <DiscountGridView />
      </TabPanel>
      <TabPanel value={value} index={Quoters.RATE_ADJUSTMENT}>
        <RateAdjustmentsView />
      </TabPanel>
    </Box>
  );
};

export default QuotesCategoriesListTabs;
