import { PersonRole } from './common';

const { default: UserType } = require('./constants/auth');
const { PersonType } = require('./constants/clients');
const { NotificationType } = require('./constants/notifications');

export const getQuoteRedirect = (systemRole, quoteId) => {
  switch (systemRole) {
    case UserType.ADMIN:
      return `/quotes/detail/${quoteId}`;
    case UserType.DISTRIBUTOR:
      return `/user/quote/detail/${quoteId}`;
    default:
      return null;
  }
};

export const getContractRedirect = (systemRole, contractId) => {
  return `/contracts/${contractId}`;
};

export const getNufiRedirect = (systemRole, notification) => {
  if (notification.type === NotificationType.NUFI_VALIDATION_CURP_FAILED) {
    const { personType, person } = notification.content;

    if (personType === PersonType.INVESTOR) {
      return `/investors/${person.id}/identity`;
    }
    return personType === PersonType.AVAL
      ? `/aval/detail/${person.id}/identity`
      : `/clients/detail/${person.id}/identity`;
  }

  if (systemRole === UserType.ADMIN) {
    if (notification.content.validation.client) {
      return `/clients/detail/${notification.content.validation.nufiValidation.clientId}/identity`;
    }
    if (notification.content.validation.aval) {
      return `/aval/detail/${notification.content.validation.aval.id}/identity`;
    }
    if (notification.content.validation.nufiValidation.distributorId) {
      return `/disrtibutors/business/${notification.content.validation.nufiValidation.distributorId}`;
    }
    if (notification.content.validation.nufiValidation.investorId) {
      return `/investors/detail/${notification.content.validation.nufiValidation.investorId}/identity`;
    }
  }
  return null;
};

export const getDistributorProfileRegistrationRedirect = (
  systemRole,
  notification
) => {
  if (systemRole === UserType.ADMIN) {
    return `/distributor-profile/${notification.content.distributorProfile.id}`;
  }
  return null;
};

export const getBuroValidationFormCompletedRedirect = (
  systemRole,
  notification
) => {
  const { person } = notification.content;
  if (systemRole === UserType.ADMIN) {
    switch (person.personRole) {
      case PersonRole.CLIENT:
        return `/clients/detail/${person.id}/buro`;
      case PersonRole.AVAL:
        return `/clients/detail/${person.clientId}/aval/detail/${person.id}/buro`;
      default:
        return null;
    }
  }
  return null;
};
