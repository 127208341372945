import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOAD_DISTRIBUTOR_PARAMETER,
  getDistributorParameters
} from 'src/store/actions/distributorActions';
import { selectDistributorDetail } from 'src/store/selectors/distributorSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import ParameterDetail from './ParameterDetail';
import ParameterCategoryDetail from './ParameterCategoryDetail';

const ParameterDetailView = () => {
  const dispatch = useDispatch();
  const distributor = useSelector(selectDistributorDetail);
  const isDistributorDataLoaded = Boolean(distributor?.id);
  const isLoadingParameters = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_PARAMETER.action])
  );

  useEffect(() => {
    if (isDistributorDataLoaded) {
      dispatch(getDistributorParameters(distributor.id));
    }
  }, [isDistributorDataLoaded, dispatch, distributor?.id]);

  return (
    <Box>
      <Grid container mt={3} spacing={3}>
        <Grid item xs={6}>
          <ParameterDetail
            loading={isLoadingParameters || !isDistributorDataLoaded}
            distributor={distributor}
          />
        </Grid>

        <Grid item xs={6}>
          <ParameterCategoryDetail
            loading={isLoadingParameters || !isDistributorDataLoaded}
            distributor={distributor}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ParameterDetailView;
