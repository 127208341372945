import {
  LOAD_PAYMENTS,
  SAVE_PAYMENTS_OPTIONS,
  CLEAR_PAYMENTS_OPTIONS,
  LOAD_PAYMENTS_LIST,
  CLEAR_PAYMENT
} from '../actions/paymentsActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
    status: null
  },
  paymentsFailures: [],
  paymentsFailuresCount: 0,
  items: [],
  count: 0
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PAYMENTS.success: {
      return {
        ...state,
        items: action.data.items || [],
        count: action.data.count || 0
      };
    }
    case SAVE_PAYMENTS_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...action.options }
      };
    }
    case CLEAR_PAYMENTS_OPTIONS: {
      return {
        ...state,
        options: initialState.options
      };
    }
    case LOAD_PAYMENTS_LIST.success: {
      return {
        ...state,
        items: action.data.items,
        count: action.data.count
      };
    }
    case CLEAR_PAYMENT: {
      return {
        ...initialState,
        count: state.count
      };
    }
    default: {
      return state;
    }
  }
};

export default paymentReducer;
