import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from 'react-i18next';
import RentPreviewDataList from 'src/views/quotes/RentPreviewDataList';
import DistributorEquipmentsList from './DistributorEquipmentsList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    border: 'none',
    borderBottom: '1px solid rgba(43,45,66,0.1)',
    background: 'transparent',
    flexDirection: 'row',
    margin: '20px 0 0 0'
  }
}));

function GeneralInformationAndEquipmentsTabs({ content, rentList, loading }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const [equipments, setEquipments] = useState([]);
  const handleChange = useCallback(
    (_, newValue) => {
      setValue(newValue);
    },
    [setValue]
  );

  useEffect(() => {
    setEquipments(content.props.values.distributorEquipments);
  }, [content]);

  return (
    <div className={classes.root}>
      <AppBar variant="outlined" position="static" className={classes.appBar}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
        >
          <Tab label={t('QUOTES_VIEW.GENERAL_INFORMATION')} />
          <Tab label={t('QUOTES_VIEW.EQUIPMENTS')} />
          <Tab label={t('QUOTES_VIEW.RENTS_DATA')} />
        </Tabs>
      </AppBar>
      <TabPanel className={classes.tabPanel} value={value} index={0}>
        {content}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DistributorEquipmentsList
          className={classes.tabPanel}
          equipments={equipments}
          loading={loading}
        />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={2}>
        <RentPreviewDataList rentCosts={rentList} loading={loading} />
      </TabPanel>
    </div>
  );
}

GeneralInformationAndEquipmentsTabs.propTypes = {
  content: PropTypes.node,
  loading: PropTypes.bool,
  rentList: PropTypes.object
};

export default GeneralInformationAndEquipmentsTabs;
