import { PaymentInputOption } from 'src/utils/constants/quotes';

const makeInitialState = data => {
  return {
    modality: data ? data.modality : '',
    distributor: data ? data.distributor : '',
    distributorEquipments: data ? data.distributorEquipments : [],
    totalPriceWithIva: data ? data.totalPriceWithIva : 0,
    initialPayment: data ? data.initialPayment : 0,
    gracePeriod: data ? data.gracePeriod : '',
    paymentInputOption: data
      ? data.paymentInputOption
      : PaymentInputOption.PERCENTAGE,
    fullName: data ? data.fullName : '',
    phone: data ? data.phone : ''
  };
};

export default makeInitialState;
