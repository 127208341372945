import React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  AlertTitle,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button
} from '@mui/material';

const AlertMessage = ({
  open,
  title,
  text,
  handleClose,
  severity,
  hideCloseIcon = false,
  autoTranslation = true,
  textStyle = {},
  data
}) => {
  const { t } = useTranslation();

  const renderTable = () => (
    <TableContainer>
      <Table sx={{ borderCollapse: 'collapse' }}>
        <TableBody>
          {data.discounts.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={index}>
              <TableCell sx={{ padding: '4px 8px', borderBottom: 'none' }}>
                {`${item.term} meses`}
              </TableCell>
              <TableCell sx={{ padding: '4px 8px', borderBottom: 'none' }}>
                {`${item.discount} %`}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert
          action={
            !hideCloseIcon ? (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            ) : null
          }
          sx={{ borderRadius: '10px' }}
          severity={severity}
        >
          {title && <AlertTitle>{t(title)}</AlertTitle>}

          {data ? (
            <>
              {data.text1 && (
                <Typography sx={{ mb: 1 }}>
                  <strong>{data.text1}</strong>
                </Typography>
              )}
              {data.discounts && renderTable()}
              {data.text2 && (
                <Typography sx={{ mt: 1 }}>
                  <strong>{data.text2}</strong>
                </Typography>
              )}
              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={data.onConfirm}
                >
                  Aceptar
                </Button>
              </Box>
            </>
          ) : (
            <Typography sx={textStyle}>
              {autoTranslation ? t(text) : text}
            </Typography>
          )}
        </Alert>
      </Collapse>
    </Box>
  );
};

AlertMessage.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  handleClose: PropTypes.func,
  severity: PropTypes.oneOf(['error', 'warning', 'success', 'info']),
  hideCloseIcon: PropTypes.bool,
  autoTranslation: PropTypes.bool,
  textStyle: PropTypes.object,
  data: PropTypes.shape({
    discounts: PropTypes.arrayOf(
      PropTypes.shape({
        term: PropTypes.number.isRequired,
        discount: PropTypes.number.isRequired
      })
    ),
    onConfirm: PropTypes.func.isRequired,
    text1: PropTypes.string,
    text2: PropTypes.string
  })
};

export default AlertMessage;
