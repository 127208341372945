import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { formatAmount } from 'src/utils/formatAmout';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { CommonActions } from 'src/utils/constants/clients';
import { useDispatch } from 'react-redux';
import { editRateAdjustmentAction } from 'src/store/actions/catalogActions';
import { useTranslation } from 'react-i18next';
import { GracePeriodValues } from 'src/utils/constants/distributors';
import RateAdjustmentForm from './RateAdjustmentForm';

const RateAdjustementRow = ({ id, gracePeriod, adjustment }) => {
  const [openRateAdjustmentForm, setOpenRateAdjustmentForm] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleClickMore = option => {
    if (option === CommonActions.EDIT) {
      setOpenRateAdjustmentForm(true);
    }
  };
  const NotEditableRateOption =
    Number(gracePeriod) === GracePeriodValues.THIRTY;
  const EditRateOption = [
    {
      name: CommonActions.EDIT,
      label: `ENUMS.ADMINISTRATIVE_ACTIONS.${CommonActions.EDIT}`
    }
  ];

  const handleCloseModelForm = () => {
    setOpenRateAdjustmentForm(false);
  };

  const handleEdit = useCallback(
    values => {
      dispatch(editRateAdjustmentAction(values, handleCloseModelForm));
    },
    [id]
  );
  return (
    <TableRow
      sx={{ backgroundColor: NotEditableRateOption ? '#f1f1f4' : 'inherit' }}
    >
      <TableCell>
        {` ${gracePeriod} ${t('COMMON.DAYS')} ${
          NotEditableRateOption ? `${t('COMMON.BASIC')}` : ''
        } `}
      </TableCell>
      <TableCell align="center">{`${formatAmount(adjustment)}%`}</TableCell>
      <TableCell align="right">
        {!NotEditableRateOption && (
          <MoreMenu
            options={EditRateOption}
            handleClickOption={handleClickMore}
          />
        )}
      </TableCell>
      <RateAdjustmentForm
        data={{ id, adjustment, gracePeriod }}
        open={openRateAdjustmentForm}
        onClose={handleCloseModelForm}
        onEdit={handleEdit}
      />
    </TableRow>
  );
};

RateAdjustementRow.propTypes = {
  gracePeriod: PropTypes.number.isRequired,
  adjustment: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired
};

export default RateAdjustementRow;
