import * as Yup from 'yup';

export default data =>
  Yup.object().shape({
    startDate: Yup.date()
      .typeError('SCHEMA_ERRORS.DATE_REQUIRED')
      .required('SCHEMA_ERRORS.REQUIRED_FIELD'),
    firstRentDate: Yup.date()
      .typeError('SCHEMA_ERRORS.DATE_REQUIRED')
      .required('SCHEMA_ERRORS.REQUIRED_FIELD')
      .when('startDate', (startDate, schema) => {
        if (!startDate) {
          return schema;
        }
        return data.isFirstUpfrontRentModality
          ? schema.min(
              new Date(
                new Date(startDate).getTime() + 60 * 24 * 60 * 60 * 1000
              ),
              'SCHEMA_ERRORS.FIRST_RENT_DATE_CANNOT_BE_BEFORE_START_DATE_PLUS_GRACE_PERIOD'
            )
          : schema.min(
              Yup.ref('startDate'),
              'SCHEMA_ERRORS.FIRST_RENT_DATE_CANNOT_BE_BEFORE_START_DATE'
            );
      }),
    gracePeriod: Yup.number().required('SCHEMA_ERRORS.REQUIRED_FIELD'),
    endDate: Yup.date()
      .typeError('SCHEMA_ERRORS.DATE_REQUIRED')
      .required('SCHEMA_ERRORS.REQUIRED_FIELD')
      .min(new Date(), 'SCHEMA_ERRORS.END_DATE_CANNOT_BE_BEFORE_CURRENT_DATE'),
    payday: Yup.number()
      .required('SCHEMA_ERRORS.REQUIRED_FIELD')
      .min(1, 'SCHEMA_ERRORS.PAYDAY_MIN')
      .max(25, 'SCHEMA_ERRORS.PAYDAY_MAX')
  });
