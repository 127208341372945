import { GracePeriodValues } from 'src/utils/constants/distributors';
import {
  calculateEndDateFromGracePeriod,
  calculateFirstRentDateFromGracePeriod,
  setGracePeriod
} from 'src/utils/contracts';
import momentLocalTimezone from 'src/utils/momentLocalTimezone';

export default (values, parameter) => {
  if (values.enableMsi) {
    const firstRentDate =
      Number(values.initialPayment) === 0
        ? momentLocalTimezone()
        : momentLocalTimezone().add(1, 'months');
    return {
      ...values,
      startDate: momentLocalTimezone(),
      firstRentDate,
      gracePeriod:
        Number(values.initialPayment) === 0
          ? GracePeriodValues.SAME_DAY_AS_CONTRACT_STARTS
          : GracePeriodValues.ZERO,
      endDate:
        Number(values.initialPayment) === 0
          ? momentLocalTimezone().add(values.term - 1, 'months')
          : momentLocalTimezone()
              .add(1, 'months')
              .add(values.term - 1, 'months'),
      payday: Math.min(momentLocalTimezone().date(), 25),
      isFirstUpfrontRentModality: false
    };
  }

  return {
    ...values,
    startDate: momentLocalTimezone(),
    firstRentDate: calculateFirstRentDateFromGracePeriod(
      momentLocalTimezone(),
      setGracePeriod(values, parameter),
      values.isFirstUpfrontRentModality
    ),
    gracePeriod: setGracePeriod(values, parameter),
    endDate: calculateEndDateFromGracePeriod(
      momentLocalTimezone(),
      values.term,
      setGracePeriod(values, parameter),
      values.isFirstUpfrontRentModality
    ),
    payday: Math.min(momentLocalTimezone().date(), 25),
    isFirstUpfrontRentModality: values.isFirstUpfrontRentModality
  };
};
