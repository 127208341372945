import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import Breadcrumb from 'src/components/Breadcrumb';
import { Typography, Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from 'src/hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import SearchBar from 'src/components/SearchBar';
import { selectQuotesQueryOptions } from 'src/store/selectors/quoteSelectors';
import {
  CLEAR_QUOTES,
  CLEAR_QUOTE_OPTIONS,
  createQuickQuoteAction,
  loadQuotes,
  downloadExcelQuotes,
  setQueryOptions
} from 'src/store/actions/quoteActions';
import FileDownloadIcon from '@mui/icons-material/GetApp';
import { FilterList } from '@mui/icons-material';
import Filter from 'src/components/Filter/Filter';
import { KamAssignationStatus, QuoteStatus } from 'src/utils/constants/quotes';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import FILTER_TYPES from 'src/utils/constants/filter';
import useStyles from './styles';
import MultiQuoteForm from './MultiQuoteForm';

const filters = [
  {
    title: 'COMMON.KAM_ASSIGNATION_STATUS',
    controlGroup: FILTER_TYPES.CHECKBOX,
    items: [
      {
        label: 'ENUMS.KAM_ASSIGNATION_STATUS.ASSIGNED',
        value: KamAssignationStatus.ASSIGNED
      },
      {
        label: 'ENUMS.KAM_ASSIGNATION_STATUS.UNASSIGNED',
        value: KamAssignationStatus.UNASSIGNED
      }
    ]
  }
];

const QuotesAdminToolbar = () => {
  const { t } = useTranslation();
  const { tab } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryOptions = useSelector(selectQuotesQueryOptions);
  const [searchValue, setSearchValue] = useState('');
  const [openMultiQuoteForm, setOpenMultiQuoteForm] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterValues, setFilterValues] = useState({});

  const handleOpenMultiQuoteForm = useCallback(() => {
    setOpenMultiQuoteForm(true);
  }, [setOpenMultiQuoteForm]);

  const handleCloseMultiQuoteForm = useCallback(() => {
    setOpenMultiQuoteForm(false);
  }, [setOpenMultiQuoteForm]);

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  function getFilterOptions(options) {
    const kamAssignationStatus = [];

    if (options[KamAssignationStatus.ASSIGNED]) {
      kamAssignationStatus.push(KamAssignationStatus.ASSIGNED);
    }
    if (options[KamAssignationStatus.UNASSIGNED]) {
      kamAssignationStatus.push(KamAssignationStatus.UNASSIGNED);
    }

    return { kamAssignationStatus };
  }

  const debounceFilter = useDebounce(
    queryOpts =>
      dispatch(
        loadQuotes({
          ...queryOpts
        })
      ),
    400
  );

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleFilterChange = useCallback(
    event => {
      setFilterValues(event);
      debounceFilter({
        ...queryOptions,
        page: 0,
        ...getFilterOptions(event)
      });
    },
    [setFilterValues, debounceFilter, queryOptions]
  );

  const debounceSearch = useDebounce(
    (value, queryOpts) =>
      dispatch(loadQuotes({ ...queryOpts, page: 0, ...value })),
    400
  );

  const handleSearch = useCallback(
    e => {
      const { value } = e.target;
      setSearchValue(value);
      return debounceSearch({ clientName: value }, queryOptions);
    },
    [setSearchValue, debounceSearch, queryOptions]
  );

  const handleCreateQuote = useCallback(values => {
    dispatch(createQuickQuoteAction(values, handleCloseMultiQuoteForm));
  }, []);

  useEffect(() => {
    dispatch({ type: CLEAR_QUOTE_OPTIONS });
    dispatch(
      loadQuotes({
        status: tab || QuoteStatus.IN_REVIEW
      })
    );
    return () => dispatch({ type: CLEAR_QUOTES });
  }, [tab]);

  const handleExcelExport = useCallback(() => {
    dispatch(setQueryOptions({ status: QuoteStatus.DRAFT }));
    dispatch(downloadExcelQuotes());
  }, [dispatch]);

  useEffect(() => {
    setSearchValue('');
  }, []);

  return (
    <>
      <Box className={classes.boxContainer}>
        <Breadcrumb />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{
              fontWeight: '400',
              textTransform: 'capitalize'
            }}
            color="primary"
            variant="h1"
          >
            {t('NAVIGATION.QUOTES')}
          </Typography>
          <Box className={classes.actionsContainer}>
            <SearchBar handleSearch={handleSearch} searchValue={searchValue} />
            <Button
              className={clsx(classes.buttons, classes.outlined)}
              color="primary"
              onClick={handleClickFilter}
            >
              <FilterList className={classes.icon} />
              {t('COMMON.FILTER')}
            </Button>
            <Button
              className={clsx(classes.buttons, classes.outlined)}
              onClick={handleExcelExport}
              color="primary"
            >
              <FileDownloadIcon className={classes.icon} />
            </Button>
            <Button
              className={clsx(classes.buttons, classes.outlined)}
              color="primary"
              onClick={handleOpenMultiQuoteForm}
            >
              {t('COMMON.CREATE_QUOTE')}
            </Button>
            <Filter
              anchorEl={anchorEl}
              filterValues={filterValues}
              handleClose={handleCloseFilter}
              handleChange={handleFilterChange}
              filters={filters}
            />

            <MultiQuoteForm
              open={openMultiQuoteForm}
              handleClose={handleCloseMultiQuoteForm}
              key="multi-equipment-form"
              handleCreateQuote={handleCreateQuote}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default QuotesAdminToolbar;
