import React, { useCallback, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from 'src/components/Table/TableCell';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import dateFormatType from 'src/utils/dateFormatType';
import selectFormatDate from 'src/utils/selectDateFormat';
import OverflowTipography from 'src/components/OverflowTipography';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import {
  QuoteActions,
  getAdminQuotesMenuOptions,
  getBrandModelName
} from 'src/utils/quotes';
import {
  formatAmountWithDecimals,
  formatPositiveAmount
} from 'src/utils/formatAmout';
import DocumentViewerDialog from 'src/components/DocumentViewer/DocumentViewerDialog';
import { useDispatch } from 'react-redux';
import SendQuoteToContractForm from 'src/views/quotes/SendQuoteToContractForm';
import { createContractFromQuote } from 'src/store/actions/contractActions';
import ContractRequestForm from 'src/views/quotes/ContractRequestForm';
import { archiveQuote, assignKamToQuote } from 'src/store/actions/quoteActions';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';
import RejectQuoteDialog from 'src/views/quotes/RejectQuoteDialog';
import AssignKamDialog from 'src/views/quotes/AssignKamDialog';
import momentLocalTimezone from 'src/utils/momentLocalTimezone';

const QuoteDetailRow = ({
  id,
  brand,
  createdAt,
  distributor,
  distributorProfile,
  model,
  price,
  document,
  term,
  initialPaymentDate,
  initialPayment,
  firstRentDate,
  updatedAt,
  iva,
  priceWithIva,
  equipmentInsuranceIncluded,
  status,
  clientData,
  client,
  gracePeriod,
  isFirstUpfrontRentModality
}) => {
  const [openDocumentViewer, setOpenDocumentViewer] = useState(false);
  const { t } = useTranslation();
  const daysSinceUpdated =
    momentLocalTimezone().diff(momentLocalTimezone(updatedAt), 'days') + 1;
  const [openContractCreationForm, setOpenContractCreationForm] = useState(
    false
  );
  const [openAssignKamDialog, setOpenAssignKamDialog] = useState(false);
  const [openQuoteRejectDialog, setOpenQuoteRejectDialog] = useState(false);

  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [openRequestContractDialog, setOpenRequestContractDialog] = useState(
    false
  );
  const dispatch = useDispatch();
  const handleOpenArchiveDialog = () => {
    setOpenArchiveDialog(true);
  };

  const handleOpenContractCreationForm = () => {
    setOpenContractCreationForm(true);
  };

  const handleOpenQuoteRejectDialog = () => {
    setOpenQuoteRejectDialog(true);
  };

  const handleCloseQuoteRejectDialog = () => {
    setOpenQuoteRejectDialog(false);
  };

  const handleOpenAssignKamDialog = () => {
    setOpenAssignKamDialog(true);
  };

  const handleCloseAssignKamDialog = () => {
    setOpenAssignKamDialog(false);
  };

  const handleClickMore = option => {
    if (option === QuoteActions.SEE_DOCUMENT) {
      setOpenDocumentViewer(true);
    }
    if (option === QuoteActions.SEND_TO_CONTRACT) {
      handleOpenContractCreationForm();
    }
    if (option === QuoteActions.REJECT_QUOTE) {
      handleOpenQuoteRejectDialog();
    }
    if (option === QuoteActions.ASSIGN_KAM) {
      handleOpenAssignKamDialog();
    }
    if (option === QuoteActions.CONTRACT_REQUEST) {
      setOpenRequestContractDialog(true);
    }
    if (option === QuoteActions.ARCHIVE) {
      handleOpenArchiveDialog();
    }
  };

  const handleCloseContractCreationForm = () => {
    setOpenContractCreationForm(false);
  };

  const handleSendQuoteToContract = useCallback(
    values => {
      dispatch(
        createContractFromQuote(
          { ...values, quoteId: id },
          handleCloseContractCreationForm
        )
      );
    },
    [id]
  );

  const handleSubmitAssignKam = useCallback(
    values => {
      dispatch(assignKamToQuote(id, values, handleCloseAssignKamDialog));
    },
    [id]
  );

  const handleCloseRequestContractDialog = () => {
    setOpenRequestContractDialog(false);
  };

  const handleArchiveQuote = () => {
    dispatch(archiveQuote(id, () => setOpenArchiveDialog(false)));
  };

  return (
    <TableRow hover>
      <TableCell>
        <Link to={`/quotes/detail/${id}`}>{`${id}`}</Link>
      </TableCell>
      <TableCell align="center">
        {selectFormatDate(createdAt, dateFormatType.DAY) || t('COMMON.NO_DATA')}
      </TableCell>

      <TableCell align="center">
        <OverflowTipography maxWidth="200px">
          {distributor ? (
            <Link to={`/distributors/business/${distributor.id}`}>
              {distributor.commercialName}
            </Link>
          ) : (
            t('COMMON.NO_DATA')
          )}
        </OverflowTipography>
      </TableCell>

      <TableCell align="center">
        <OverflowTipography>
          {brand && model
            ? getBrandModelName(brand, model, t)
            : t('COMMON.NO_DATA')}
        </OverflowTipography>
      </TableCell>

      <TableCell align="center">
        <OverflowTipography maxWidth="150px">
          {distributorProfile ? (
            <Link to={`/distributor-profile/${distributorProfile.id}`}>
              {distributorProfile.name}
            </Link>
          ) : (
            t('COMMON.NO_DATA')
          )}
        </OverflowTipography>
      </TableCell>

      <TableCell align="center">
        {formatPositiveAmount(price) || t('COMMON.NO_DATA')}
      </TableCell>

      <TableCell align="center">
        {formatPositiveAmount(iva) || t('COMMON.NO_DATA')}
      </TableCell>

      <TableCell align="center">
        {formatPositiveAmount(priceWithIva) || t('COMMON.NO_DATA')}
      </TableCell>

      <TableCell align="center">
        {term ? `${term} ${t('COMMON.MONTHS')}` : `${t('COMMON.NO_DATA')}`}
      </TableCell>
      <TableCell align="center">
        {initialPayment
          ? `$${formatAmountWithDecimals(initialPayment, 1)}`
          : `${t('COMMON.NO_DATA')}`}
      </TableCell>

      <TableCell align="center">
        {t(
          `${daysSinceUpdated} ${
            daysSinceUpdated === 1 ? t('COMMON.DAY') : t('COMMON.DAYS')
          }`
        )}
      </TableCell>

      <TableCell align="center">
        <OverflowTipography maxWidth="100px" align="center">
          {equipmentInsuranceIncluded
            ? t('COMMON.INCLUDED')
            : t('COMMON.NOT_INCLUDED')}
        </OverflowTipography>
      </TableCell>

      <TableCell
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <MoreMenu
          deleteDisabled
          editDisabled
          options={getAdminQuotesMenuOptions(document, status)}
          handleClickOption={handleClickMore}
        />
        {openArchiveDialog && (
          <ConfirmationDialog
            open={openArchiveDialog}
            handleClose={() => setOpenArchiveDialog(false)}
            onSubmit={handleArchiveQuote}
            title={t('QUOTES_VIEW.ARCHIVE_QUOTE')}
            description={t('QUOTES_VIEW.ARCHIVE_QUOTE_DESCRIPTION')}
          />
        )}
        {openDocumentViewer && document.documentUrl && (
          <DocumentViewerDialog
            documentUrl={document.documentUrl}
            open={openDocumentViewer}
            onClose={() => setOpenDocumentViewer(false)}
          />
        )}
      </TableCell>
      {openContractCreationForm && (
        <SendQuoteToContractForm
          open={openContractCreationForm}
          term={term}
          quoteId={id}
          initialPaymentDate={initialPaymentDate}
          firstRentDate={firstRentDate}
          client={client ?? clientData ?? {}}
          initialPayment={initialPayment}
          gracePeriod={gracePeriod}
          handleClose={handleCloseContractCreationForm}
          onSubmit={handleSendQuoteToContract}
        />
      )}

      <RejectQuoteDialog
        open={openQuoteRejectDialog}
        onClose={handleCloseQuoteRejectDialog}
        quoteId={id}
      />

      <AssignKamDialog
        open={openAssignKamDialog}
        onClose={handleCloseAssignKamDialog}
        distributorId={distributor && distributor.id}
        onSubmit={handleSubmitAssignKam}
      />
      {openRequestContractDialog && (
        <ContractRequestForm
          open={openRequestContractDialog}
          handleClose={handleCloseRequestContractDialog}
          key="request-contract-form"
          price={priceWithIva}
          quoteId={id}
          distributorId={distributor.id}
          initialPayment={initialPayment}
          term={term}
          iva={iva}
          gracePeriod={gracePeriod}
          initialPaymentDate={initialPaymentDate}
          firstRentDate={firstRentDate}
          isFirstUpfrontRentModality={isFirstUpfrontRentModality}
        />
      )}
    </TableRow>
  );
};

export default QuoteDetailRow;

QuoteDetailRow.propTypes = {
  id: PropTypes.number.isRequired,
  brand: PropTypes.object.isRequired,
  createdAt: PropTypes.string.isRequired,
  distributor: PropTypes.object.isRequired,
  distributorProfile: PropTypes.object.isRequired,
  model: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  document: PropTypes.object.isRequired,
  term: PropTypes.number.isRequired,
  initialPayment: PropTypes.number.isRequired,
  updatedAt: PropTypes.string.isRequired,
  iva: PropTypes.number,
  priceWithIva: PropTypes.number,
  equipmentInsuranceIncluded: PropTypes.bool,
  status: PropTypes.string,
  initialPaymentDate: PropTypes.string,
  firstRentDate: PropTypes.string,
  gracePeriod: PropTypes.number,
  clientData: PropTypes.object,
  client: PropTypes.object,
  isFirstUpfrontRentModality: PropTypes.bool
};
