import * as Yup from 'yup';

export default Yup.object().shape({
  distributor: Yup.object()
    .typeError('SCHEMA_ERRORS.DISTRIBUTOR_REQUIRED')
    .required('SCHEMA_ERRORS.DISTRIBUTOR_REQUIRED'),
  distributorEquipments: Yup.array()
    .min(1, 'SCHEMA_ERRORS.MIN_EQUIPMENTS_REQUIRED')
    .max(6, 'SCHEMA_ERRORS.MAX_EQUIPMENTS_SELECTED')
    .required('SCHEMA_ERRORS.DISTRIBUTOR_EQUIPMENTS_REQUIRED')
});
