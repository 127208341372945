import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import NotFoundRecords from 'src/components/NotFoundRecords';
import MsiDiscountRow from './MsiDiscountRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ msiDiscounts }) =>
  msiDiscounts.map(msiDiscount => (
    <MsiDiscountRow key={msiDiscount.id} {...msiDiscount} />
  ));

const DiscountMsiTable = ({ msiDiscount, loading, success, ...rest }) => {
  const { t } = useTranslation();

  const theads = useMemo(() => () => (
    <TableRow component="tr">
      <TableCell sx={{ width: '30%' }} variant="head" key="name">
        {t('CATALOGS_VIEW.MSI_DISCOUNT.RATE_NAME')}
      </TableCell>
      <TableCell sx={{ width: '30%' }} variant="head" key="implicitRate">
        {t('CATALOGS_VIEW.MSI_DISCOUNT.IMPLICIT_RATE')}
      </TableCell>
      <TableCell sx={{ width: '30%' }} variant="head" key="insurance">
        {t('CATALOGS_VIEW.MSI_DISCOUNT.INSURANCE')}
      </TableCell>
      <TableCell sx={{ width: '10%' }} variant="head" key="options">
        {' '}
      </TableCell>
    </TableRow>
  ));

  const tbody = useMemo(() => () => <Tbody msiDiscounts={msiDiscount} />, [
    msiDiscount
  ]);

  if (success && !loading && !msiDiscount.length) {
    return (
      <Card>
        <NotFoundRecords title="NOT_FOUND_RECORDS_MESSAGE.MSI_DISCOUNT_TITLE" />
      </Card>
    );
  }

  return <Table {...rest} tbody={tbody} theads={theads} loading={loading} />;
};

DiscountMsiTable.propTypes = {
  msiDiscount: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool
};

export default DiscountMsiTable;
