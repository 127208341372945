import { QuoteModality } from 'src/utils/constants/quotes';

const getMaxEquipments = modality => (modality === QuoteModality.MSI ? 1 : 6);

export const getInitialEquipmentState = () => ({
  distributorEquipments: [],
  selectedEquipmentQuantities: [],
  totalQuantity: 0
});

const calculateEquipmentQuantities = (distributorEquipments, maxAllowed) => {
  const quantities = distributorEquipments
    .map(equipment => equipment.quantity)
    .slice(0, maxAllowed);

  return {
    selectedEquipmentQuantities: quantities,
    totalQuantity: quantities.reduce((acc, qty) => acc + qty, 0)
  };
};

const makeInitialState = values => {
  const maxEquipments = getMaxEquipments(values?.modality);
  const initialState = {
    ...values,
    distributor: values?.distributor || null,
    distributorEquipments: values?.distributorEquipments || [],
    totalPriceWithIva: values?.totalPriceWithIva || null,
    maxEquipments,
    enableMsi: values?.modality === QuoteModality.MSI
  };

  if (values?.distributorEquipments?.length) {
    const quantityState = calculateEquipmentQuantities(
      values.distributorEquipments,
      maxEquipments
    );
    return { ...initialState, ...quantityState };
  }

  return { ...initialState, ...getInitialEquipmentState() };
};

export default makeInitialState;
