import React from 'react';
import { useTranslation } from 'react-i18next';
import DetailRow from 'src/components/DetailRow';
import { addPercentageToValue } from 'src/utils/common';
import PropTypes from 'prop-types';
import { formatAmountWithDecimals } from 'src/utils/formatAmout';
import { translateGracePeriodValueToDaysForFirstRent } from 'src/utils/quotes';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function ParameterDetailInfo({ distributor }) {
  const { t } = useTranslation();
  return (
    <>
      <DetailRow
        name={t('DISTRIBUTORS_VIEW.PARAMETERS.QUOTE_CATEGORY')}
        value={
          distributor.quoteCategory ? (
            <Link to="/quoters/categories">
              {distributor.quoteCategory.name}
            </Link>
          ) : (
            t('COMMON.NO_DATA')
          )
        }
      />
      <DetailRow
        name={t('DISTRIBUTORS_VIEW.PARAMETERS.MIN_TERM')}
        value={
          `${distributor.minTerm} ${t('COMMON.MONTHS')}` || t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('DISTRIBUTORS_VIEW.PARAMETERS.MAX_TERM')}
        value={
          `${distributor.maxTerm} ${t('COMMON.MONTHS')}` || t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('DISTRIBUTORS_VIEW.PARAMETERS.MIN_INITIAL_PAYMENT')}
        value={
          addPercentageToValue(
            formatAmountWithDecimals(distributor.minInitialPayment)
          ) || t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('DISTRIBUTORS_VIEW.PARAMETERS.IS_PRICE_EDITABLE')}
        value={distributor.isPriceEditable ? t('COMMON.YES') : t('COMMON.NO')}
      />
      <DetailRow
        name={t('DISTRIBUTORS_VIEW.PARAMETERS.IS_INSURANCE_INCLUDED')}
        value={
          distributor.isInsuranceIncluded ? t('COMMON.YES') : t('COMMON.NO')
        }
      />

      <DetailRow
        name={t('COMMON.MAX_DAYS_FOR_FIRST_RENT')}
        value={
          translateGracePeriodValueToDaysForFirstRent(
            Number(distributor.maxGracePeriod),
            t
          ) || t('COMMON.NO_DATA')
        }
      />

      <DetailRow
        name={t('COMMON.DEFAULT_DAYS_FOR_FIRST_RENT')}
        value={
          translateGracePeriodValueToDaysForFirstRent(
            Number(distributor.defaultGracePeriod),
            t
          ) || t('COMMON.NO_DATA')
        }
      />
      <DetailRow
        name={t('COMMON.ENABLE_REPEAT_CONTACT_KAM_FOR_QUOTE_CREATION')}
        value={
          distributor.enableUseKamContactInfo ? t('COMMON.YES') : t('COMMON.NO')
        }
      />

      <DetailRow
        name={t('COMMON.ENABLE_EDIT_KAM_INFORMATION')}
        value={distributor.enableEditKamInfo ? t('COMMON.YES') : t('COMMON.NO')}
      />

      <DetailRow
        name={t('DISTRIBUTORS_VIEW.PARAMETERS.MAX_RESIDUAL_VALUE')}
        value={
          addPercentageToValue(
            formatAmountWithDecimals(distributor.maxResidualValue)
          ) || t('COMMON.NO_DATA')
        }
      />

      <DetailRow
        name={t('COMMON.MSI_ENABLED')}
        value={distributor.enableMsi ? t('COMMON.YES') : t('COMMON.NO')}
      />

      <DetailRow
        name={t('COMMON.ENABLE_ANTICIPATED_FIRST_RENT')}
        value={
          distributor.anticipatedFirstRentEnabled
            ? t('COMMON.YES')
            : t('COMMON.NO')
        }
      />
    </>
  );
}
ParameterDetailInfo.propTypes = {
  distributor: PropTypes.object.isRequired
};

export default ParameterDetailInfo;
