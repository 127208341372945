import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';
import theme from 'src/theme';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { markAsRead } from 'src/store/actions/notificationsActions';
import {
  NotificationCategory,
  NotificationStatus
} from 'src/utils/constants/notifications';
import buildNotificationMessage from 'src/utils/nofiticationsSelected';
import { push } from 'connected-react-router';
import { selectAuthSystem } from 'src/store/selectors/authSelectors';
import {
  getBuroValidationFormCompletedRedirect,
  getDistributorProfileRegistrationRedirect,
  getNufiRedirect,
  getQuoteRedirect,
  getContractRedirect
} from 'src/utils/notifcationRedirect';

const selectCardColor = status => {
  switch (status) {
    case NotificationStatus.READ:
      return theme.palette.notification.read;
    case NotificationStatus.UNREAD:
      return theme.palette.notification.unread;
    default:
      return theme.palette.notification.unread;
  }
};

const selectNotificationRedirect = (systemRole, notification) => {
  switch (notification.category) {
    case NotificationCategory.QUOTES:
      return getQuoteRedirect(systemRole, notification.content.quote.id);
    case NotificationCategory.CONTRACTS:
      return getContractRedirect(systemRole, notification.content.contract.id);
    case NotificationCategory.NUFI:
      return getNufiRedirect(systemRole, notification);
    case NotificationCategory.DISTRIBUTOR_PROFILE_REGISTRATION:
      return getDistributorProfileRegistrationRedirect(
        systemRole,
        notification
      );
    case NotificationCategory.BURO_VALIDATION:
      return getBuroValidationFormCompletedRedirect(systemRole, notification);
    default:
      return null;
  }
};

const NotificationCard = ({ notification, notificationKey, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const carColor = selectCardColor(notification.status);
  const systemRole = useSelector(selectAuthSystem);

  const handleNotificationClick = useCallback(() => {
    dispatch(markAsRead(notification.id, onClose));
    dispatch(push(`${selectNotificationRedirect(systemRole, notification)}`));
  }, [notification.id]);

  return (
    <Alert
      key={notificationKey}
      icon={false}
      sx={{
        backgroundColor: carColor,
        overflow: 'hidden'
      }}
    >
      <AlertTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            fontSize: '1rem'
          }}
        >
          {t(`ENUMS.NOTIFICATIONS.TITLES.${notification.type || 'DEFAULT'}`)}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontSize: '0.8rem'
          }}
        >
          {moment(notification.createdAt).format('DD/MM/YYYY')}
        </Typography>
      </AlertTitle>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          textOverflow: 'ellipsis'
        }}
      >
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontSize: '1rem',
            width: '100%',
            overflow: 'hidden'
          }}
        >
          {buildNotificationMessage(notification, t)}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Button
            variant="text"
            color="primary"
            onClick={handleNotificationClick}
          >
            {t('COMMON.VIEW')}
          </Button>
        </Box>
      </Box>
    </Alert>
  );
};

NotificationCard.propTypes = {
  notification: PropTypes.object.isRequired,
  notificationKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func.isRequired
};

export default NotificationCard;
