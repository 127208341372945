export const selectContract = state => state.contracts;
export const selectContributions = state => state.contributions.data;
export const selectRentAfterContribution = state =>
  state.contributions.rentAfterContribution;
export const selectContractsList = state => selectContract(state).items;
export const selectContractDetail = state => selectContract(state).detail;
export const selectContractsListCount = state => selectContract(state).count;
export const selectUpfrontRent = state => selectContract(state).upfrontRent;
export const selectContractsQueryOptions = state => {
  const { options } = selectContract(state);

  Object.keys(options).forEach(key => {
    if (options[key] === '') {
      delete options[key];
    }
  });

  return options;
};
export const selectRentBalanceSchedule = state =>
  selectContract(state).rentBalanceSchedule;

export const selectAvalList = state => selectContractDetail(state).avals;
