import { Card } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotFoundRecords from 'src/components/NotFoundRecords';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import {
  selectPaymentsList,
  selectPaymentsListCount,
  selectPaymentQueryOptions
} from 'src/store/selectors/paymentSelectors';
import loadPayments, {
  LOAD_PAYMENTS_LIST
} from 'src/store/actions/paymentsActions';
import AutomaticDebitCollectionTable from './AutomaticDebitCollectionTable';

const AutomaticDebitCollectionList = () => {
  const dispatch = useDispatch();
  const payments = useSelector(selectPaymentsList);
  const count = useSelector(selectPaymentsListCount);
  const { page } = useSelector(selectPaymentQueryOptions);
  const loading = useSelector(isLoadingSelector([LOAD_PAYMENTS_LIST.action]));
  const success = useSelector(successSelector([LOAD_PAYMENTS_LIST.action]));

  const handleChangePage = useCallback(
    (_, value) => {
      dispatch(loadPayments({ page: value }));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(loadPayments({ status: '' }));
  }, [dispatch, page]);

  if (success && !loading && count === 0) {
    return (
      <Card>
        <NotFoundRecords
          title="NOT_FOUND_RECORDS_MESSAGE.AUTOMATIC_DEBIT_COLLECTIONS_TITLE"
          description="NOT_FOUND_RECORDS_MESSAGE.AUTOMATIC_DEBIT_COLLECTIONS_DESCRIPTION"
        />
      </Card>
    );
  }

  return (
    <AutomaticDebitCollectionTable
      payments={payments}
      count={count}
      page={page}
      handleChangePage={handleChangePage}
      loading={loading}
    />
  );
};

export default AutomaticDebitCollectionList;
