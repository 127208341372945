import React, { useCallback, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import dateFormatType from 'src/utils/dateFormatType';
import selectFormatDate from 'src/utils/selectDateFormat';
import { getClientFullName } from 'src/utils/common';
import { formatAmount, formatAmountWithDecimals } from 'src/utils/formatAmout';
import TableCell from 'src/components/Table/TableCell';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import { selectQuotesQueryOptionsStatus } from 'src/store/selectors/quoteSelectors';
import { createContractFromQuote } from 'src/store/actions/contractActions';
import OverflowTipography from 'src/components/OverflowTipography';
import moment from 'moment-timezone';
import { archiveQuote, assignKamToQuote } from 'src/store/actions/quoteActions';
import DocumentViewerDialog from 'src/components/DocumentViewer/DocumentViewerDialog';
import StatusIcon from 'src/components/StatusIcon';
import IconStatusAndColor from 'src/utils/constants/common';
import { QuoteStatus } from 'src/utils/constants/quotes';
import Tooltip from 'src/components/Tooltip';
import ConfirmationDialog from 'src/components/ConfirmDialog/ConfirmationDialog';
import {
  QuoteActions,
  getAdminQuotesMenuOptions,
  getBrandModelName
} from '../../utils/quotes';
import AssignKamDialog from './AssignKamDialog';
import RejectQuoteDialog from './RejectQuoteDialog';
import SendQuoteToContractForm from './SendQuoteToContractForm';
import ContractRequestForm from './ContractRequestForm';
import SendQuoteToWhatsappDialog from './SendQuoteToWhatsappDialog';

const QuotesRow = ({
  id,
  brand,
  createdAt,
  updatedAt,
  distributor,
  distributorProfile,
  model,
  price,
  client,
  document,
  term,
  initialPayment,
  pendingDocs,
  initialPaymentDate,
  firstRentDate,
  iva,
  priceWithIva,
  equipmentInsuranceIncluded,
  sentForClientApproval,
  clientData,
  gracePeriod,
  isMsiModality,
  isFirstUpfrontRentModality
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openContractCreationForm, setOpenContractCreationForm] = useState(
    false
  );
  const [openQuoteRejectDialog, setOpenQuoteRejectDialog] = useState(false);
  const [openAssignKamDialog, setOpenAssignKamDialog] = useState(false);
  const [openDocumentViewer, setOpenDocumentViewer] = useState(false);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [openSendQuoteToWhatsapp, setOpenSendQuoteToWhatsapp] = useState(false);
  const [openRequestContractDialog, setOpenRequestContractDialog] = useState(
    false
  );
  const status = useSelector(selectQuotesQueryOptionsStatus);
  const daysSinceUpdated = moment().diff(moment(updatedAt), 'days') + 1;

  const handleCloseContractCreationForm = () => {
    setOpenContractCreationForm(false);
  };

  const handleOpenContractCreationForm = () => {
    setOpenContractCreationForm(true);
  };

  const handleOpenQuoteRejectDialog = () => {
    setOpenQuoteRejectDialog(true);
  };

  const handleCloseQuoteRejectDialog = () => {
    setOpenQuoteRejectDialog(false);
  };

  const handleOpenAssignKamDialog = () => {
    setOpenAssignKamDialog(true);
  };

  const handleCloseAssignKamDialog = () => {
    setOpenAssignKamDialog(false);
  };

  const handleOpenSendQuoteToWhatsappDialog = () => {
    setOpenSendQuoteToWhatsapp(true);
  };

  const handleCloseSendQuoteToWhatsappDialog = () => {
    setOpenSendQuoteToWhatsapp(false);
  };

  const handleSendQuoteToContract = useCallback(
    values => {
      dispatch(
        createContractFromQuote(
          { ...values, quoteId: id },
          handleCloseContractCreationForm
        )
      );
    },
    [id]
  );

  const handleSubmitAssignKam = useCallback(
    values => {
      dispatch(assignKamToQuote(id, values, handleCloseAssignKamDialog));
    },
    [id]
  );

  const handleOpenArchiveDialog = () => {
    setOpenArchiveDialog(true);
  };

  const handleArchiveQuote = () => {
    dispatch(archiveQuote(id, () => setOpenArchiveDialog(false)));
  };

  const handleCloseRequestContractDialog = () => {
    setOpenRequestContractDialog(false);
  };

  const handleClickMore = option => {
    if (option === QuoteActions.SEE_DOCUMENT) {
      setOpenDocumentViewer(true);
    }
    if (option === QuoteActions.SEND_TO_CONTRACT) {
      handleOpenContractCreationForm();
    }
    if (option === QuoteActions.REJECT_QUOTE) {
      handleOpenQuoteRejectDialog();
    }
    if (option === QuoteActions.ASSIGN_KAM) {
      handleOpenAssignKamDialog();
    }
    if (option === QuoteActions.CONTRACT_REQUEST) {
      setOpenRequestContractDialog(true);
    }
    if (option === QuoteActions.ARCHIVE) {
      handleOpenArchiveDialog();
    }
    if (option === QuoteActions.SEND_TO_WHATSAPP) {
      handleOpenSendQuoteToWhatsappDialog(true);
    }
  };

  return (
    <TableRow hover>
      <TableCell>
        <Link to={`/quotes/detail/${id}`}>{`${id}`}</Link>
      </TableCell>

      <TableCell>
        <OverflowTipography maxWidth="100%">
          {selectFormatDate(createdAt, dateFormatType.DAY) ||
            t('COMMON.NO_DATA')}
        </OverflowTipography>
      </TableCell>

      <TableCell>
        <OverflowTipography maxWidth="150px">
          {client ? (
            <Link to={`/clients/detail/${client.id}`}>
              {getClientFullName(client)}
            </Link>
          ) : (
            <Tooltip condition text={clientData?.comment}>
              {clientData?.fullName || t('COMMON.NO_DATA')}
            </Tooltip>
          )}
        </OverflowTipography>
      </TableCell>

      <TableCell align="center">
        <OverflowTipography maxWidth="200px">
          {distributor ? (
            <Link to={`/distributors/business/${distributor.id}`}>
              {distributor.commercialName}
            </Link>
          ) : (
            t('COMMON.NO_DATA')
          )}
        </OverflowTipography>
      </TableCell>

      <TableCell>
        <OverflowTipography>
          {brand && model
            ? getBrandModelName(brand, model, t)
            : t('COMMON.NO_DATA')}
        </OverflowTipography>
      </TableCell>

      <TableCell align="center">
        <OverflowTipography maxWidth="200px">
          {distributorProfile ? (
            <Link to={`/distributor-profile/${distributorProfile.id}`}>
              {distributorProfile.name}
            </Link>
          ) : (
            t('COMMON.NO_DATA')
          )}
        </OverflowTipography>
      </TableCell>

      <TableCell>
        <OverflowTipography maxWidth="100%" align="center">
          {formatAmount(price) || t('COMMON.NO_DATA')}
        </OverflowTipography>
      </TableCell>

      <TableCell align="center">
        {formatAmount(iva) || t('COMMON.NO_DATA')}
      </TableCell>

      <TableCell align="center">
        {formatAmount(priceWithIva) || t('COMMON.NO_DATA')}
      </TableCell>

      <TableCell align="center">{term ?? t('COMMON.NO_DATA')}</TableCell>
      <TableCell align="center">
        {initialPayment
          ? `$${formatAmountWithDecimals(initialPayment)}`
          : `${t('COMMON.NO_DATA')}`}
      </TableCell>

      <TableCell align="center">
        {t(
          `${daysSinceUpdated} ${
            daysSinceUpdated === 1 ? t('COMMON.DAY') : t('COMMON.DAYS')
          }`
        )}
      </TableCell>

      <TableCell align="center">
        {equipmentInsuranceIncluded
          ? t('COMMON.INCLUDED')
          : t('COMMON.NOT_INCLUDED')}
      </TableCell>

      {status === QuoteStatus.DRAFT && (
        <>
          <TableCell align="center">
            {sentForClientApproval ? (
              <Tooltip
                condition
                text={t('QUOTES_VIEW.SENT_FOR_CALIENT_APPROVAL')}
              >
                <StatusIcon type={IconStatusAndColor.OK.status} />
              </Tooltip>
            ) : (
              <Tooltip
                condition
                text={t('QUOTES_VIEW.NOT_SENT_FOR_CALIENT_APPROVAL')}
              >
                <StatusIcon type={IconStatusAndColor.ERROR.status} />
              </Tooltip>
            )}
          </TableCell>
        </>
      )}

      {/* Actons */}

      <TableCell
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <MoreMenu
          deleteDisabled
          editDisabled
          options={getAdminQuotesMenuOptions(
            document,
            status,
            distributorProfile,
            {
              number: pendingDocs,
              message: t('TOOLTIP_MESSAGES.CLIENT_HAS_PEDING_DOCS').replace(
                '{parameter}',
                pendingDocs
              )
            },
            sentForClientApproval
          )}
          handleClickOption={handleClickMore}
        />
      </TableCell>

      {openContractCreationForm && (
        <SendQuoteToContractForm
          open={openContractCreationForm}
          term={term}
          quoteId={id}
          initialPaymentDate={initialPaymentDate}
          firstRentDate={firstRentDate}
          client={client ?? clientData ?? {}}
          initialPayment={initialPayment}
          gracePeriod={gracePeriod}
          handleClose={handleCloseContractCreationForm}
          onSubmit={handleSendQuoteToContract}
        />
      )}

      <SendQuoteToWhatsappDialog
        open={openSendQuoteToWhatsapp}
        onClose={handleCloseSendQuoteToWhatsappDialog}
        quoteId={id}
        phone={client ? client.phone : clientData?.phone}
      />

      <RejectQuoteDialog
        open={openQuoteRejectDialog}
        onClose={handleCloseQuoteRejectDialog}
        quoteId={id}
      />

      <AssignKamDialog
        open={openAssignKamDialog}
        onClose={handleCloseAssignKamDialog}
        distributorId={distributor && distributor.id}
        onSubmit={handleSubmitAssignKam}
      />

      {openDocumentViewer && document.documentUrl && (
        <DocumentViewerDialog
          documentUrl={document.documentUrl}
          open={openDocumentViewer}
          onClose={() => setOpenDocumentViewer(false)}
        />
      )}

      {openArchiveDialog && (
        <ConfirmationDialog
          open={openArchiveDialog}
          handleClose={() => setOpenArchiveDialog(false)}
          onSubmit={handleArchiveQuote}
          title={t('QUOTES_VIEW.ARCHIVE_QUOTE')}
          description={t('QUOTES_VIEW.ARCHIVE_QUOTE_DESCRIPTION')}
        />
      )}
      {openRequestContractDialog && (
        <ContractRequestForm
          open={openRequestContractDialog}
          handleClose={handleCloseRequestContractDialog}
          key="request-contract-form"
          price={priceWithIva}
          quoteId={id}
          distributorId={distributor.id}
          initialPayment={initialPayment}
          term={term}
          iva={iva}
          gracePeriod={gracePeriod}
          initialPaymentDate={initialPaymentDate}
          firstRentDate={firstRentDate}
          clientData={clientData}
          client={client}
          isMsiModality={isMsiModality}
          isFirstUpfrontRentModality={isFirstUpfrontRentModality}
        />
      )}
    </TableRow>
  );
};

export default QuotesRow;

QuotesRow.propTypes = {
  brand: PropTypes.object.isRequired,
  createdAt: PropTypes.string.isRequired,
  distributor: PropTypes.object.isRequired,
  distributorProfile: PropTypes.object,
  model: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  client: PropTypes.object,
  document: PropTypes.object,
  id: PropTypes.number.isRequired,
  term: PropTypes.string,
  initialPayment: PropTypes.number,
  updatedAt: PropTypes.string.isRequired,
  pendingDocs: PropTypes.number,
  iva: PropTypes.number,
  priceWithIva: PropTypes.number,
  equipmentInsuranceIncluded: PropTypes.bool,
  sentForClientApproval: PropTypes.bool,
  clientData: PropTypes.object,
  initialPaymentDate: PropTypes.string,
  firstRentDate: PropTypes.string,
  gracePeriod: PropTypes.number,
  isMsiModality: PropTypes.bool,
  isFirstUpfrontRentModality: PropTypes.bool
};
