import * as Yup from 'yup';

export default Yup.object({
  minMsiTerm: Yup.number()
    .required('ERRORS.MIN_MSI_TERM_IS_REQUIRED')
    .test(
      'min-less-than-max',
      'ERRORS.MIN_MSI_TERM_CANNOT_BE_GRATER_THAN_MAX_MSI_TERM',
      // eslint-disable-next-line func-names
      function(value) {
        const { maxMsiTerm } = this.parent;
        return value <= maxMsiTerm;
      }
    ),
  maxMsiTerm: Yup.number()
    .required('ERRORS.MAX_MSI_TERM_IS_REQUIRED')
    .test(
      'max-greater-than-min',
      'ERRORS.MAX_MSI_TERM_CANNOT_BE_LOWER_THAN_MIN_MSI_TERM',
      // eslint-disable-next-line func-names
      function(value) {
        const { minMsiTerm } = this.parent;
        return value >= minMsiTerm;
      }
    )
});
