import React, { useCallback, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import TabPanel from 'src/components/TabPanel/TabPanel';
import Tab from 'src/components/Tab';
import { Failures } from 'src/utils/constants/failures';
import { useDispatch } from 'react-redux';
import {
  useHistory,
  useParams,
  useLocation
} from 'react-router-dom/cjs/react-router-dom.min';
import loadPayments, {
  clearPaymentQueryOptions
} from 'src/store/actions/paymentsActions';
import { loadBillingFailures } from 'src/store/actions/billingActions';
import AutomaticDebitCollectionStatus from 'src/utils/payments';
import BillingFailuresList from './billing/BillingFailuresList/BillingFailuresList';
import PaymentsFailuresList from './payments/PaymentsFailuresList';

const FailuresMenuList = [
  {
    value: Failures.BILLING,
    label: 'ENUMS.FAILURES.BILLING',
    description: 'ENUMS.FAILURES.BILLING'
  },
  {
    value: Failures.PAYMENT,
    label: 'ENUMS.FAILURES.PAYMENT',
    description: 'ENUMS.FAILURES.PAYMENT'
  }
];

const FailuresCategoriesListTabs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { tab } = useParams();
  const location = useLocation();
  const [value, setValue] = useState(tab || Failures.BILLING);

  const handleChangeTabs = useCallback(
    (_, newValue) => {
      setValue(newValue);
      push(`/failures/${newValue}`);
      if (newValue === Failures.PAYMENT) {
        dispatch(
          loadPayments({
            status: AutomaticDebitCollectionStatus.FAILED_TO_BE_DEBITED,
            page: 0
          })
        );
      } else if (newValue === Failures.BILLING) {
        dispatch(loadBillingFailures());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!tab || !Object.values(Failures).includes(tab)) {
      setValue(Failures.BILLING);
      push(`/failures/${Failures.BILLING}`);
      dispatch(loadBillingFailures());
    } else {
      setValue(tab);
    }
  }, [tab, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearPaymentQueryOptions());
    };
  }, [location.pathname, dispatch]);

  return (
    <Box sx={{ flexGrow: 1, mt: '2rem' }}>
      <AppBar
        elevation={0}
        variant="outlined"
        position="static"
        sx={{
          border: 'none',
          borderBottom: '1px solid rgba(43,45,66,0.1)',
          background: 'transparent',
          flexDirection: 'row'
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChangeTabs}
        >
          {FailuresMenuList.map(item => (
            <Tab
              key={item.value}
              label={t(item.label)}
              value={item.value}
              description={t(item.description)}
            />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={Failures.BILLING}>
        <BillingFailuresList />
      </TabPanel>
      <TabPanel value={value} index={Failures.PAYMENT}>
        <PaymentsFailuresList />
      </TabPanel>
    </Box>
  );
};

export default FailuresCategoriesListTabs;
