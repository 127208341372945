import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DetailRow from 'src/components/DetailRow';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import useStyles from '../styles';

const ParameterCategoryDetailInfo = ({ distributor }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      {!distributor.enableMsi && (
        <Typography className={classes.text} color="primary">
          {t('DISTRIBUTORS_VIEW.PARAMETERS.ENABLE_MSI_MODALITY')}
        </Typography>
      )}
      {distributor.enableMsi && (
        <>
          <DetailRow
            name={t('COMMON.MSI_QUOTER')}
            value={
              distributor.discountMsi ? (
                <Link to="/quoters/categories/msi-rate">{`${distributor.discountMsi.name}`}</Link>
              ) : (
                t('COMMON.NO_DATA')
              )
            }
          />
          <DetailRow
            name={t('DISTRIBUTORS_VIEW.PARAMETERS.MIN_MSI_TERM')}
            value={
              distributor.minMsiTerm
                ? `${distributor.minMsiTerm} meses`
                : t('COMMON.NO_DATA')
            }
          />
          <DetailRow
            name={t('DISTRIBUTORS_VIEW.PARAMETERS.MAX_MSI_TERM')}
            value={
              distributor.maxMsiTerm
                ? `${distributor.maxMsiTerm} meses`
                : t('COMMON.NO_DATA')
            }
          />
        </>
      )}
    </>
  );
};

ParameterCategoryDetailInfo.propTypes = {
  distributor: PropTypes.object.isRequired
};

export default ParameterCategoryDetailInfo;
