export const departmentsList = [
  {
    key: 'GENERAL',
    name: 'GENERAL'
  },
  {
    key: 'COMERCIAL',
    name: 'COMERCIAL'
  },
  {
    key: 'ADMINISTRATIVE',
    name: 'ADMINISTRATIVE'
  }
];

export const positionsList = [
  {
    key: 'GENERAL_DIRECTOR',
    name: 'GENERAL_DIRECTOR'
  },
  {
    key: 'COMERCIAL_DIRECTOR',
    name: 'COMERCIAL_DIRECTOR'
  },
  {
    key: 'FINANCE_DIRECTOR',
    name: 'FINANCE_DIRECTOR'
  },
  {
    key: 'KAM',
    name: 'KAM'
  },
  {
    key: 'ACCOUNTANT',
    name: 'ACCOUNTANT'
  }
];

export const AgentStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING'
};

export const AgentRoles = {
  DIRECTOR: 'DIRECTOR',
  KAM: 'KAM'
};

export const AgentRolesSelect = [
  {
    key: AgentRoles.DIRECTOR,
    name: 'DIRECTOR'
  },
  {
    key: AgentRoles.KAM,
    name: 'KAM'
  }
];
export const TermValues = {
  TWELVE: 12,
  TWELVE_MSI: 12,
  EIGHTEEN: 18,
  EIGHTEEN_MSI: 18,
  TWENTY_FOUR: 24,
  TWENTY_FOUR_MSI: 24,
  THIRTY_SIX: 36,
  FORTY_EIGHT: 48,
  SIXTY: 60,
  SEVENTY_TWO: 72
};

export const MinInitialPaymentValues = {
  ZERO: 0,
  TEN: 10,
  TWENTY: 20,
  THIRTY: 30
};

export const msiTermList = [
  { key: TermValues.TWELVE_MSI, name: 'ENUMS.MONTH_RATE.TWELVE_MONTHS' },
  { key: TermValues.EIGHTEEN_MSI, name: 'ENUMS.MONTH_RATE.EIGHTEEN_MONTHS' },
  {
    key: TermValues.TWENTY_FOUR_MSI,
    name: 'ENUMS.MONTH_RATE.TWENTY_FOUR_MONTHS'
  }
];

export const TermAnticipatedFirstRentList = [
  {
    key: TermValues.TWENTY_FOUR,
    name: 'ENUMS.MONTH_RATE.TWENTY_FOUR_MONTHS'
  },
  {
    key: TermValues.THIRTY_SIX,
    name: 'ENUMS.MONTH_RATE.THIRTY_SIX_MONTHS'
  },
  {
    key: TermValues.FORTY_EIGHT,
    name: 'ENUMS.MONTH_RATE.FORTY_EIGHT_MONTHS'
  },
  {
    key: TermValues.SIXTY,
    name: 'ENUMS.MONTH_RATE.SIXTY_MONTHS'
  },
  {
    key: TermValues.SEVENTY_TWO,
    name: 'ENUMS.MONTH_RATE.SEVENTY_TWO_MONTHS'
  }
];

export const TermList = [
  { key: TermValues.TWELVE, name: 'ENUMS.MONTH_RATE.TWELVE_MONTHS' },
  { key: TermValues.EIGHTEEN, name: 'ENUMS.MONTH_RATE.EIGHTEEN_MONTHS' },
  {
    key: TermValues.TWENTY_FOUR,
    name: 'ENUMS.MONTH_RATE.TWENTY_FOUR_MONTHS'
  },
  {
    key: TermValues.THIRTY_SIX,
    name: 'ENUMS.MONTH_RATE.THIRTY_SIX_MONTHS'
  },
  {
    key: TermValues.FORTY_EIGHT,
    name: 'ENUMS.MONTH_RATE.FORTY_EIGHT_MONTHS'
  },
  {
    key: TermValues.SIXTY,
    name: 'ENUMS.MONTH_RATE.SIXTY_MONTHS'
  },
  {
    key: TermValues.SEVENTY_TWO,
    name: 'ENUMS.MONTH_RATE.SEVENTY_TWO_MONTHS'
  }
];

export const getTermListWithMsi = isMsiEnabled => {
  const termList = TermList;

  if (isMsiEnabled) {
    termList.push({
      key: TermValues.TWELVE_MSI,
      name: 'ENUMS.MONTH_RATE.TWELVE_MONTHS_MSI'
    });
  }

  return termList;
};

export const GracePeriodValues = {
  // 0, 30, 60, 90.  -30 it's an special case
  SAME_DAY_AS_CONTRACT_STARTS: -30,
  ZERO: 0,
  THIRTY: 30,
  SIXTY: 60,
  NINETY: 90
};

// Translations corresponds to days after contract starts, not the actual days of grace period
export const DaysForFirstRentAfterContractStartList = [
  {
    key: GracePeriodValues.SAME_DAY_AS_CONTRACT_STARTS,
    name: 'ENUMS.DAYS_FOR_FIRST_RENT.SAME_DAY_AS_CONTRACT_STARTS'
  },
  {
    key: GracePeriodValues.ZERO,
    name: 'ENUMS.DAYS_FOR_FIRST_RENT.THIRTY_DAYS'
  },
  {
    key: GracePeriodValues.THIRTY,
    name: 'ENUMS.DAYS_FOR_FIRST_RENT.SIXTY_DAYS'
  },
  {
    key: GracePeriodValues.SIXTY,
    name: 'ENUMS.DAYS_FOR_FIRST_RENT.NINETY_DAYS'
  },
  {
    key: GracePeriodValues.NINETY,
    name: 'ENUMS.DAYS_FOR_FIRST_RENT.HUNDRED_TWENTY'
  }
];

export const DaysForFirstRentAfterContractStartFirstUpfrontRentList = [
  {
    key: GracePeriodValues.THIRTY,
    name: 'ENUMS.DAYS_FOR_FIRST_RENT.SIXTY_DAYS'
  },
  {
    key: GracePeriodValues.SIXTY,
    name: 'ENUMS.DAYS_FOR_FIRST_RENT.NINETY_DAYS'
  },
  {
    key: GracePeriodValues.NINETY,
    name: 'ENUMS.DAYS_FOR_FIRST_RENT.HUNDRED_TWENTY'
  }
];

export const GracePeriodList = [
  {
    key: GracePeriodValues.ZERO,
    name: 'ENUMS.GRACE_PERIOD.ZERO_DAYS'
  },
  {
    key: GracePeriodValues.THIRTY,
    name: 'ENUMS.GRACE_PERIOD.THIRTY_DAYS'
  },
  {
    key: GracePeriodValues.SIXTY,
    name: 'ENUMS.GRACE_PERIOD.SIXTY_DAYS'
  },
  {
    key: GracePeriodValues.NINETY,
    name: 'ENUMS.GRACE_PERIOD.NINETY_DAYS'
  }
];

export const getDefaultGracePeriodList = maxGracePeriod => {
  return GracePeriodList.filter(item => item.key <= maxGracePeriod);
};

export const getDefaultDaysForFirstRentList = (
  maxGracePeriod,
  isFirstUpfrontRentModality = false
) => {
  if (isFirstUpfrontRentModality) {
    const minValue = GracePeriodValues.THIRTY;
    const maxValue = Math.max(GracePeriodValues.THIRTY, maxGracePeriod);
    return DaysForFirstRentAfterContractStartFirstUpfrontRentList.filter(
      item => item.key >= minValue && item.key <= maxValue
    );
  }
  return DaysForFirstRentAfterContractStartList.filter(item => {
    return item.key <= maxGracePeriod;
  });
};

export const MinInitialPaymentList = [0, 5, 10, 15, 20, 30];

export const getInitialPaymentList = minInitialPayment => {
  return MinInitialPaymentList.filter(item => item >= minInitialPayment);
};

export const getTermList = (minTerm, maxTerm) => {
  return TermList.filter(item => item.key >= minTerm && item.key <= maxTerm);
};

export const getTermMsiList = (minTerm, maxTerm) => {
  return msiTermList.filter(item => item.key >= minTerm && item.key <= maxTerm);
};

export const getTermListForQuoteAndContracts = (
  distributorParameters,
  isMsiModality,
  isFirstUpfrontRentModality
) => {
  const { minTerm, maxTerm, minMsiTerm, maxMsiTerm } = distributorParameters;

  if (isMsiModality) {
    return getTermMsiList(minMsiTerm, maxMsiTerm);
  }

  if (isFirstUpfrontRentModality) {
    const minValue = Math.max(TermValues.TWENTY_FOUR, minTerm);
    return getTermList(minValue, maxTerm);
  }

  return getTermList(minTerm, maxTerm);
};

export const distributorDetailTabsIndex = {
  GENERAL: 'general',
  CONTACTS: 'contacts',
  CATALOG: 'catalog',
  BRANDS_AND_MODELS: 'brands',
  PROFILES: 'profiles',
  PARAMETERS: 'parameters',
  MODELS: 'models'
};
