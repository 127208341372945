import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { QuoteModality } from 'src/utils/constants/quotes';
import FormStepper from 'src/components/FormStepper/FormStepper';
import DialogTransition from 'src/components/DialogTransition';
import { CLEAR_CLIENT_DETAIL } from 'src/store/actions/clientActions';
import { CLEAR_DISTRIBUTORS } from 'src/store/actions/distributorActions';
import CurrentStep from './CurrentStep';
import useStyles from './styles';
import makeInitialState from './initialState';

const stepperLabels = [
  'QUOTES_VIEW.MULTI_QUOTE.FORM_STEPS.MODALITY_SELECTION',
  'QUOTES_VIEW.MULTI_QUOTE.FORM_STEPS.EQUIPMENT_SELECTION',
  'QUOTES_VIEW.MULTI_QUOTE.FORM_STEPS.LEASE_TERMS',
  'QUOTES_VIEW.MULTI_QUOTE.FORM_STEPS.PERSONAL_INFO',
  'QUOTES_VIEW.MULTI_QUOTE.FORM_STEPS.CONFIRMATION'
];

const MultiQuoteForm = ({ open, handleClose, handleCreateQuote }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialState = useMemo(() => makeInitialState(), []);
  const [step, setStep] = useState(0);
  const [data, setData] = useState(initialState);
  const resetRef = useRef();

  useEffect(() => {
    return () => {
      setData(initialState);
      setStep(0);
      dispatch({ type: CLEAR_DISTRIBUTORS });
    };
  }, [open]);

  const handleNext = useCallback(
    values => {
      setData({ ...data, ...values });
      setStep(step + 1);
    },
    [setStep, step, setData, data]
  );

  const handleBackOrClose = useCallback(
    values => {
      if (step === 0) {
        setData(initialState);
        handleClose();
      } else {
        if (values) {
          setData({ ...data, ...values });
        }
        setStep(step - 1);
      }
    },
    [step, setStep, setData, data, handleClose]
  );

  const handleSubmit = useCallback(
    values => {
      handleCreateQuote({
        ...data,
        ...values,
        isMsiModality: data.modality === QuoteModality.MSI,
        isFirstUpfrontRentModality:
          data.modality === QuoteModality.FIRST_UPFRONT_RENT
      });
      setData(initialState);
    },
    [data]
  );

  const handleCloseForm = () => {
    setData(initialState);
    dispatch({ type: CLEAR_CLIENT_DETAIL });
    handleClose();
  };

  if (!open) return null;

  return (
    <Box>
      <Dialog
        aria-labelledby="form-new-gps-dialog"
        classes={{ paper: classes.root }}
        open={open}
        scroll="body"
        TransitionComponent={DialogTransition}
        keepMounted
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.title} id="form-new-gps-title">
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleCloseForm}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {t('COMMON.QUOTES')}
          </Typography>
        </DialogTitle>
        <Divider light variant="middle" />
        <FormStepper
          index={step}
          labels={stepperLabels}
          sx={{
            marginBottom: '1.5rem',
            marginTop: '1.5rem'
          }}
        />
        <DialogContent>
          <CurrentStep
            activeStep={step}
            data={data}
            setData={setData}
            resetRef={resetRef}
            onNext={handleNext}
            onBack={handleBackOrClose}
            onSubmit={handleSubmit}
            onCloseForm={handleCloseForm}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

MultiQuoteForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCreateQuote: PropTypes.func.isRequired
};

export default MultiQuoteForm;
