import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider } from '@mui/material';
import AlertMessage from 'src/components/Alert/Alert';
import Preloader from 'src/components/Preloader';
import { QuoteModality } from 'src/utils/constants/quotes';
import { getInitialPaymentAmount } from 'src/utils/quotes';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRentsCosts,
  selectMsiDiscounts
} from 'src/store/selectors/quoteSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  LOAD_QUOTES_RENTS,
  loadQuotesRents,
  getMsiDiscounts
} from 'src/store/actions/quoteActions';
import GeneralInformationAndEquipmentsTabs from './GeneralInformationAndEquipmentsTabs';
import ReviewData from './ReviewData';
import useStyles from '../styles';

const FinalStep = ({ data, handleSubmit, handleBack, loading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const rentList = useSelector(selectRentsCosts) || [];
  const [showWarning, setShowWarning] = useState(true);
  const [isSendButtonEnabled, setIsSendButtonEnabled] = useState(false);
  const loadingRents = useSelector(
    isLoadingSelector([LOAD_QUOTES_RENTS.action])
  );
  const initialPaymentAmount = getInitialPaymentAmount(
    data.totalPriceWithIva,
    data.initialPayment,
    data.paymentInputOption
  );

  const rentsDataToCalculate = {
    price: Number(data.totalPriceWithIva),
    initialPayment: initialPaymentAmount,
    isMsiModality: data.modality === QuoteModality.MSI,
    distributorId: data.distributor.id,
    residualPayment: data.residualPayment,
    gracePeriod: Number(data.gracePeriod),
    isFirstUpfrontRentModality:
      data.modality === QuoteModality.FIRST_UPFRONT_RENT,
    equipmentInsuranceIncluded: data.equipmentInsuranceIncluded
  };

  const msiDiscounts = useSelector(selectMsiDiscounts);

  useEffect(() => {
    dispatch(loadQuotesRents(rentsDataToCalculate));
  }, []);
  const body = useMemo(() => {
    return {
      ...data,
      initialPayment: initialPaymentAmount
    };
  }, [data, initialPaymentAmount]);

  const content = useMemo(() => {
    return !loading ? <ReviewData values={body} /> : <Preloader />;
  }, [loading]);

  const handleOtherSubmit = e => {
    e.preventDefault();
    handleSubmit(body);
  };

  const handleConfirmWarning = () => {
    setShowWarning(false);
    setIsSendButtonEnabled(true);
  };

  useEffect(() => {
    if (data.modality === QuoteModality.MSI && !msiDiscounts) {
      dispatch(
        getMsiDiscounts({
          distributorId: data.distributor.id,
          terms: data.allowedMsiTerms,
          initialPayment: initialPaymentAmount,
          equipmentCost: data.totalPriceWithIva
        })
      );
    }
  }, [data]);

  return (
    <FadeIn>
      <form id="final-step-form" onSubmit={handleOtherSubmit}>
        <Divider light />
        {!loading &&
          showWarning &&
          data.modality === QuoteModality.MSI &&
          msiDiscounts && (
            <AlertMessage
              id="msi-warning-alert"
              open
              severity="warning"
              hideCloseIcon
              data={{
                discounts: msiDiscounts,
                onConfirm: handleConfirmWarning,
                text1: t('ALERTS.MSI_WARNING_TEXT_1'),
                text2: t('ALERTS.MSI_WARNING_TEXT_2')
              }}
            />
          )}
        {!loadingRents && !loading ? (
          <GeneralInformationAndEquipmentsTabs
            id="info-equipment-tabs"
            content={content}
            rentList={rentList}
            loading={loading}
          />
        ) : (
          <Preloader />
        )}

        <Box
          className={classes.tableBox}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            id="back-button"
            className={classes.buttons}
            onClick={() => handleBack()}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            {t('COMMON.BACK')}
          </Button>
          <Button
            id="create-button"
            className={clsx(classes.buttons, classes.buttonMain)}
            type="submit"
            color="primary"
            variant="outlined"
            disabled={
              loading ||
              (!isSendButtonEnabled && data.modality === QuoteModality.MSI)
            }
          >
            {t('COMMON.CREATE')}
          </Button>
        </Box>
      </form>
    </FadeIn>
  );
};
FinalStep.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default FinalStep;
