import { asyncActionCreator } from 'src/utils/loadingUtils';
import {
  createQuickQuote as adminCreateQuickQuote,
  adminNewQuoteCreation,
  archiveQuoteById,
  assignKam,
  assingClientToQuote,
  calculateRent,
  getQuoteList as getAdminQuoteList,
  getQuoteDetail,
  getQuoteRentsDetail,
  rejectQuote,
  calculateMsiDiscounts,
  downloadExportedQuotes
} from 'src/services/quoteService';
import {
  kamNewQuoteCreation,
  getQuoteList as getDistributorQuoteList,
  getQuoteDetailForKam,
  createQuickQuote as kamCreateQuickQuote,
  assignClientToQuote as assignKamClientToQuote,
  sendQuoteToWhatsapp
} from 'src/services/userQuoteService';
import UserType from 'src/utils/constants/auth';
import {
  createQuoteCategory,
  deleteQuoteCategory,
  editQuoteCategory,
  getQuoteCategories
} from 'src/services/catalogService';
import { SnackBarVariants } from 'src/utils/constants/common';
import { push } from 'connected-react-router';
import { showMessage } from './snackBarActions';
import { selectQuotesQueryOptions } from '../selectors/quoteSelectors';
// eslint-disable-next-line import/no-cycle
import { catalogAction } from './catalogActions';
import {
  LOAD_RENT_CALCULATOR,
  SEND_QUOTE_TO_WHATSAPP,
  LOAD_MSI_DISCOUNTS
} from '../constants/quotes';

export const LOAD_QUOTES = asyncActionCreator('LOAD_QUOTES');
export const CLEAR_QUOTES = 'CLEAR_QUOTES';
export const SAVE_QUOTE_OPTIONS = 'SAVE_QUOTE_OPTIONS';
export const LOAD_QUOTES_RENTS = asyncActionCreator('LOAD_QUOTES_RENTS');
export const SAVE_QUOTE_RENTS_OPTIONS = 'SAVE_QUOTE_RENTS_OPTIONS';
export const CLEAR_QUOTE_OPTIONS = 'CLEAR_QUOTE_OPTIONS';
export const CLEAR_MSI_OPTIONS = 'CLEAR_MSI_OPTIONS';
export const CLEAR_CLIENT_QUOTES = 'CLEAR_CLIENT_QUOTES';
export const CREATE_QUOTE = asyncActionCreator('CREATE_QUOTE');
export const LOAD_QUOTE_CATEGORIES = asyncActionCreator(
  'LOAD_QUOTE_CATEGORIES'
);
export const CREATE_QUOTE_CATEGORY = asyncActionCreator(
  'CREATE_QUOTE_CATEGORY'
);
export const UPDATE_QUOTE_CATEGORY = asyncActionCreator(
  'UPDATE_QUOTE_CATEGORY'
);
export const LOAD_KAM_QUOTES = asyncActionCreator('LOAD_KAM_QUOTES');
export const CLEAR_KAM_QUOTES = 'CLEAR_KAM_QUOTES';
export const REJECT_QUOTE = asyncActionCreator('REJECT_QUOTE');
export const ARCHIVE_QUOTE = asyncActionCreator('ARCHIVE_QUOTE');
export const LOAD_QUOTE_DETAIL = asyncActionCreator('LOAD_QUOTE_DETAIL');
export const ASSIGN_KAM = asyncActionCreator('ASSIGN_KAM');
export const LOAD_CLIENT_DISTRIBUTOR_PROFILE = asyncActionCreator(
  'LOAD_CLIENT_DISTRIBUTOR_PROFILE'
);
export const CREATE_QUICK_QUOTE = asyncActionCreator('CREATE_QUICK_QUOTE');
export const ASSIGN_CLIENT = asyncActionCreator('ASSIGN_CLIENT');

export const getQuotesOptions = (getState, sendLimit = true) => {
  const queryOpts = selectQuotesQueryOptions(getState());
  const reqOptions = {};

  reqOptions.sortOrder = 'desc';
  reqOptions.sortProperty = 'createdAt';

  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }

  if (queryOpts.clientId) {
    reqOptions.clientId = queryOpts.clientId;
  }

  if (queryOpts.clientName) {
    reqOptions.clientName = queryOpts.clientName;
  }

  if (queryOpts.distributorProfileId) {
    reqOptions.distributorProfileId = queryOpts.distributorProfileId;
  }

  if (queryOpts.distributorId) {
    reqOptions.distributorId = queryOpts.distributorId;
  }

  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }

  if (queryOpts.kamAssignationStatus) {
    reqOptions.kamAssignationStatus = queryOpts.kamAssignationStatus;
  }
  if (queryOpts.sort) {
    reqOptions.sortOrder = queryOpts.sort.order;
    reqOptions.sortProperty = queryOpts.sort.property;
  }
  if (queryOpts.search) {
    reqOptions.search = queryOpts.search;
  }

  return reqOptions;
};

export const setQueryOptions = options => dispatch => {
  dispatch({
    type: SAVE_QUOTE_OPTIONS,
    options
  });
};

export const loadQuotes = options => async (dispatch, getState) => {
  try {
    dispatch({
      type: SAVE_QUOTE_OPTIONS,
      options
    });

    const { auth } = getState();

    dispatch({ type: LOAD_QUOTES.start });

    const reqOptions = getQuotesOptions(getState);

    if (auth.system === UserType.DISTRIBUTOR) {
      const quotes = await getDistributorQuoteList(reqOptions);
      return dispatch({
        type: LOAD_QUOTES.success,
        ...quotes
      });
    }

    const quotes = await getAdminQuoteList(reqOptions);

    return dispatch({
      type: LOAD_QUOTES.success,
      ...quotes
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_QUOTES.failure });
  }
};

export const loadQuotesRents = values => async dispatch => {
  try {
    dispatch({
      type: SAVE_QUOTE_RENTS_OPTIONS,
      values
    });

    dispatch({ type: LOAD_QUOTES_RENTS.start });
    const quoteRentValues = await getQuoteRentsDetail({ ...values });

    return dispatch({
      type: LOAD_QUOTES_RENTS.success,
      payload: quoteRentValues
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_QUOTES_RENTS.failure });
  }
};

const loadQuoteCategories = () => async dispatch => {
  try {
    dispatch({
      type: LOAD_QUOTE_CATEGORIES.start
    });

    const data = await getQuoteCategories();

    return dispatch({
      type: LOAD_QUOTE_CATEGORIES.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_QUOTE_CATEGORIES.failure });
  }
};

export const createQuote = (values, closeCallback, setData) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: CREATE_QUOTE.start });

    const { system } = getState().auth;

    if (system === UserType.ADMIN || system === UserType.SUPER_ADMIN) {
      const { id } = await adminNewQuoteCreation({
        ...values,
        gracePeriod: values.gracePeriod,
        equipmentInsuranceIncluded:
          values.equipmentInsuranceIncluded === 'true',
        distributorEquipmentsData: [
          {
            id: values.distributorEquipment.id,
            quantity: 1,
            price: values.distributorEquipment.price
          }
        ]
      });
      dispatch(push(`/quotes/detail/${id}`));
    } else {
      const { id } = await kamNewQuoteCreation({
        ...values,
        distributorId: Number(values.distributorId),
        gracePeriod: values.gracePeriod,
        equipmentInsuranceIncluded:
          values.equipmentInsuranceIncluded === 'true',
        distributorEquipmentsData: [
          {
            id: values.distributorEquipment.id,
            quantity: 1,
            price: values.distributorEquipment.price
          }
        ]
      });
      dispatch(push(`/quotes/detail/${id}`));
    }

    if (setData) {
      setData();
    }

    closeCallback();
    dispatch(
      showMessage({
        message: 'CREATE_QUOTE',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch(loadQuotes());
    return dispatch({ type: CREATE_QUOTE.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    closeCallback();
    return dispatch({ type: CREATE_QUOTE.failure });
  }
};

export const loadQuoteCategoriesList = setState => async dispatch => {
  try {
    dispatch({
      type: LOAD_QUOTE_CATEGORIES.start
    });

    const data = await getQuoteCategories();

    setState(data);

    return dispatch({
      type: LOAD_QUOTE_CATEGORIES.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_QUOTE_CATEGORIES.failure });
  }
};
export const createQuoteCategoryAction = catalogAction(
  createQuoteCategory,
  loadQuoteCategories,
  'CREATE_QUOTE_CATEGORY'
);

export const editQuoteCategoryAction = catalogAction(
  editQuoteCategory,
  loadQuoteCategories,
  'EDIT_QUOTE_CATEGORY'
);

export const deleteQuoteCategoryAction = catalogAction(
  deleteQuoteCategory,
  loadQuoteCategories,
  'DELETE_QUOTE_CATEGORY'
);

export const requectQuoteById = (
  id,
  values,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: REJECT_QUOTE.start });

    await rejectQuote({ id, values });

    closeCallback();
    dispatch(
      showMessage({
        message: 'REJECT_QUOTE',
        variant: SnackBarVariants.SUCCESS
      })
    );
    dispatch(loadQuotes());
    return dispatch({ type: REJECT_QUOTE.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    closeCallback();
    return dispatch({ type: REJECT_QUOTE.failure });
  }
};

export const archiveQuote = (id, closeCallback) => async dispatch => {
  try {
    dispatch({ type: ARCHIVE_QUOTE.start });

    await archiveQuoteById({ id });

    dispatch(
      showMessage({
        message: 'ARCHIVE_QUOTE',
        variant: SnackBarVariants.SUCCESS
      })
    );
    closeCallback();
    dispatch({ type: ARCHIVE_QUOTE.success });
    return dispatch(loadQuotes());
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    closeCallback();
    return dispatch({ type: ARCHIVE_QUOTE.failure });
  }
};

export const loadQuoteDetail = id => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_QUOTE_DETAIL.start });
    const { system } = getState().auth;

    let detail = {};

    if (system === UserType.ADMIN) {
      detail = await getQuoteDetail(id);
    } else {
      detail = await getQuoteDetailForKam(id);
    }

    return dispatch({ type: LOAD_QUOTE_DETAIL.success, payload: detail });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_QUOTE_DETAIL.failure });
  }
};

export const assignKamToQuote = (
  id,
  values,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: ASSIGN_KAM.start });

    const { location } = document;

    const reference = location.pathname;

    await assignKam({
      id,
      values: { distributorProfileId: values.distributorProfile.id }
    });

    closeCallback();

    dispatch(
      showMessage({
        message: 'ASSIGN_KAM',
        variant: SnackBarVariants.SUCCESS
      })
    );

    if (reference.includes(reference.includes('quotes/detail'))) {
      dispatch(loadQuoteDetail(id));
    } else if (reference.includes('/quotes')) {
      dispatch(loadQuotes());
    }

    return dispatch({ type: ASSIGN_KAM.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    closeCallback();
    return dispatch({ type: ASSIGN_KAM.failure });
  }
};

export const createQuickQuoteAction = (
  values,
  closeCallback,
  setData
) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_QUICK_QUOTE.start });
    const { system } = getState().auth;
    const data = {
      clientData: {
        email: values.email ?? null,
        phone: values.phone ? values.phone : null,
        fullName: values.fullName,
        comment: values.comment
      },
      distributorProfileId: values.distributorProfile?.id,
      distributorId: values.distributor.id,
      distributorEquipmentsData: values.distributorEquipments.map(eq => ({
        id: eq.id,
        price: eq.priceWithIva,
        quantity: eq.quantity || 1
      })),
      price: values.totalPriceWithIva
        ? Number(values.totalPriceWithIva)
        : Number(values.price),
      residualPayment: Number(values.residualPayment),
      term: values.term ? Number(values.term) : null,
      initialPayment: values.initialPayment ?? null,
      equipmentInsuranceIncluded: values.equipmentInsuranceIncluded === 'true',
      paymentInputOption: values.paymentInputOption,
      gracePeriod: values.gracePeriod,
      isMsiModality: !!values.isMsiModality,
      isFirstUpfrontRentModality: !!values.isFirstUpfrontRentModality
    };
    const { id: createdQuoteId } =
      system === UserType.ADMIN
        ? await adminCreateQuickQuote(data)
        : await kamCreateQuickQuote(data);

    dispatch(push(`/quotes/detail/${createdQuoteId}`));

    if (setData) {
      setData();
    }

    closeCallback();
    dispatch(
      showMessage({
        message: 'CREATE_QUICK_QUOTE',
        variant: SnackBarVariants.SUCCESS
      })
    );

    return dispatch({ type: CREATE_QUICK_QUOTE.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    closeCallback();
    return dispatch({ type: CREATE_QUICK_QUOTE.failure });
  }
};

export const assignClientToQuote = (
  values,
  closeCallback,
  reloadCallback
) => async (dispatch, getState) => {
  try {
    dispatch({ type: ASSIGN_CLIENT.start });

    const { auth } = getState();

    if (auth.system === UserType.DISTRIBUTOR) {
      await assignKamClientToQuote({
        quoteId: values.quoteId,
        clientId: values.clientId
      });
    } else {
      await assingClientToQuote({
        quoteId: values.quoteId,
        clientId: values.clientId
      });
    }

    closeCallback();

    dispatch(
      showMessage({
        message: 'ASSIGN_CLIENT',
        variant: SnackBarVariants.SUCCESS
      })
    );

    reloadCallback();
    return dispatch({ type: ASSIGN_CLIENT.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    closeCallback();
    return dispatch({ type: ASSIGN_CLIENT.failure });
  }
};

export const getRentCalculator = values => async dispatch => {
  try {
    dispatch({ type: LOAD_RENT_CALCULATOR.start });

    const data = await calculateRent(values);

    return dispatch({
      type: LOAD_RENT_CALCULATOR.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_RENT_CALCULATOR.failure });
  }
};

export const getMsiDiscounts = values => async dispatch => {
  try {
    dispatch({ type: LOAD_MSI_DISCOUNTS.start });

    const data = await calculateMsiDiscounts(values);

    return dispatch({
      type: LOAD_MSI_DISCOUNTS.success,
      data
    });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: LOAD_MSI_DISCOUNTS.failure });
  }
};

export const sendQuoteToWhatsappAction = (
  values,
  onCloseCallback
) => async dispatch => {
  try {
    dispatch({ type: SEND_QUOTE_TO_WHATSAPP.start });
    await sendQuoteToWhatsapp(values);
    dispatch(
      showMessage({
        message: 'SEND_QUOTE_TO_WHATSAPP',
        variant: SnackBarVariants.SUCCESS
      })
    );
    dispatch({ type: SEND_QUOTE_TO_WHATSAPP.success });
    onCloseCallback();
    dispatch(loadQuotes());
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export const downloadExcelQuotes = () => async (dispatch, getState) => {
  try {
    const reqOptions = getQuotesOptions(getState, false);
    await downloadExportedQuotes(reqOptions);
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export default loadQuoteCategories;
