import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import { formatAmount } from 'src/utils/formatAmout';
import useStyles from './styles';

const RentPreviewDataList = ({ rentCosts }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Table classes={{ root: classes.tableRoot }} component="div">
      <TableBody
        classes={{ root: classes.tableBody }}
        component="div"
        colSpan={1}
      >
        {rentCosts.map(rentCost => {
          const formatedAmount = formatAmount(rentCost.amount);
          return (
            <TableRow component="div" key={rentCost.term}>
              <TableCell
                component="div"
                className={classes.reviewDataLabel}
                align="center"
              >
                {t('COMMON.RENT_TERM', { term: rentCost.term })}
              </TableCell>
              <TableCell component="div" align="center">
                <Typography variant="body2" color="textSecondary">
                  {t('COMMON.RENT_AMOUNT', {
                    amount: formatedAmount
                  })}
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
RentPreviewDataList.propTypes = {
  rentCosts: PropTypes.object
};

export default RentPreviewDataList;
