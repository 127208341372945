import {
  CLEAR_QUOTES,
  CLEAR_QUOTE_OPTIONS,
  LOAD_QUOTES,
  LOAD_QUOTES_RENTS,
  LOAD_QUOTE_DETAIL,
  SAVE_QUOTE_OPTIONS
} from '../actions/quoteActions';
import {
  CLEAR_RENT_CALCULATOR,
  LOAD_RENT_CALCULATOR,
  LOAD_MSI_DISCOUNTS,
  CLEAR_MSI_DISCOUNTS
} from '../constants/quotes';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0
  },
  items: [],
  count: 0,
  detail: {},
  rentsCosts: [],
  // Only use for quick quote flow
  client: {},
  rentCost: 0,
  msiDiscounts: null
};

const quoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_QUOTES.success: {
      return {
        ...state,
        items: action.items,
        count: action.count
      };
    }
    case LOAD_QUOTES_RENTS.success: {
      return {
        ...state,
        rentsCosts: action.payload
      };
    }
    case LOAD_QUOTE_DETAIL.success: {
      return {
        ...state,
        detail: action.payload
      };
    }
    case LOAD_RENT_CALCULATOR.success: {
      return {
        ...state,
        rentCost: action.data
      };
    }
    case CLEAR_RENT_CALCULATOR: {
      return {
        ...state,
        rentCost: initialState.rentCost
      };
    }
    case CLEAR_MSI_DISCOUNTS: {
      return {
        ...state,
        msiDiscounts: null
      };
    }
    case LOAD_MSI_DISCOUNTS.success: {
      return {
        ...state,
        msiDiscounts: action.data
      };
    }
    case CLEAR_QUOTES: {
      return {
        ...state,
        options: initialState.options,
        items: [],
        count: 0
      };
    }
    case SAVE_QUOTE_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...action.options }
      };
    }
    case CLEAR_QUOTE_OPTIONS: {
      return {
        ...state,
        options: initialState.options
      };
    }
    default: {
      return state;
    }
  }
};

export default quoteReducer;
