import BallotIcon from '@mui/icons-material/Ballot';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import PaymentsIcon from '@mui/icons-material/Payments';
import BillingIcon from '@mui/icons-material/FeaturedPlayList';
import TransactionIcon from '@mui/icons-material/PriceChange';
import { ReactComponent as InsuranceIcon } from 'src/assets/icons/security.svg';
import InvoiceIcon from '@mui/icons-material/ReceiptLong';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import IncomeIcon from '@mui/icons-material/Assessment';
import RentIcon from '@mui/icons-material/Feed';
import PolicyIcon from '@mui/icons-material/Policy';
import DistributorIcon from '@mui/icons-material/Hail';
import QuoterIcon from '@mui/icons-material/Work';
import { ReactComponent as CatalogIcon } from 'src/assets/icons/list.svg';
import { ReactComponent as ParametersIcon } from 'src/assets/icons/settings.svg';
import BillingFailures from '@mui/icons-material/AssignmentLate';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import BusinessIcon from '@mui/icons-material/Business';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ResumeIcon from '@mui/icons-material/Article';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import GroupIcon from '@mui/icons-material/Group';
import { ListAlt } from '@mui/icons-material';
import InvestmentIcon from '@mui/icons-material/MonetizationOn';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import SummarizeIcon from '@mui/icons-material/Summarize';

export const adminRoutes = [
  {
    title: 'NAVIGATION.OPERATION',
    children: [
      {
        href: '/clients',
        icon: EmojiPeopleIcon,
        title: 'NAVIGATION.CLIENT'
      },
      {
        href: '/inventory',
        icon: BallotIcon,
        title: 'NAVIGATION.INVENTORY'
      },
      {
        href: '/contracts',
        icon: InvoiceIcon,
        title: 'NAVIGATION.CONTRACT'
      },
      {
        icon: BusinessIcon,
        title: 'NAVIGATION.DISTRIBUTOR',
        children: [
          {
            href: '/distributors/business',
            icon: DistributorIcon,
            title: 'NAVIGATION.BUSINESS'
          },
          {
            href: '/distributors/metrics',
            icon: AnalyticsIcon,
            title: 'NAVIGATION.METRICS'
          },
          {
            href: '/distributors/resume',
            icon: ResumeIcon,
            title: 'NAVIGATION.RESUME'
          }
        ]
      },
      {
        href: '/quotes',
        icon: RequestQuoteIcon,
        title: 'NAVIGATION.QUOTES'
      },
      {
        icon: PaymentsIcon,
        title: 'NAVIGATION.INVESTMENTS',
        children: [
          {
            href: '/investors/list',
            icon: ListAlt,
            title: 'NAVIGATION.INVESTORS'
          },
          {
            href: '/investors/agents',
            icon: DistributorIcon,
            title: 'NAVIGATION.AGENTS'
          },
          {
            href: '/investors/investments',
            icon: InvestmentIcon,
            title: 'NAVIGATION.INVESTMENTS'
          }
        ]
      }
    ]
  },
  {
    title: 'NAVIGATION.INCOME',
    children: [
      {
        icon: RentIcon,
        title: 'NAVIGATION.PERIOD_INCOME',
        href: '/income/rent'
      },
      {
        icon: IncomeIcon,
        title: 'NAVIGATION.INCOME_RESUME',
        href: '/income/resume'
      },
      {
        icon: BillingFailures,
        title: 'NAVIGATION.FAILURES',
        href: '/failures'
      },
      {
        icon: BillingFailures,
        title: 'NAVIGATION.UPFRONT_RENTS',
        href: '/upfront-rents'
      },
      {
        icon: AccountBalanceIcon,
        title: 'NAVIGATION.AUTOMATIC_DEBIT_COLLECTIONS',
        href: '/automatic-debit-collections'
      }
    ]
  },
  {
    title: 'NAVIGATION.ACCOUNTING',
    children: [
      {
        icon: TransactionIcon,
        title: 'NAVIGATION.TRANSACTIONS',
        href: '/transactions'
      },
      {
        icon: BillingIcon,
        title: 'NAVIGATION.BILLING',
        href: '/billing/list'
      },

      {
        icon: AccountBalanceWalletIcon,
        title: 'NAVIGATION.BALANCE',
        children: [
          {
            icon: AccountBalanceIcon,
            title: 'NAVIGATION.INTEREST_BALANCE',
            href: '/interest-balance'
          },
          {
            icon: AccountBalanceWalletIcon,
            title: 'NAVIGATION.CAPITAL_BALANCE',
            href: '/capital-balance'
          },
          {
            href: '/insurances/list',
            icon: InsuranceIcon,
            title: 'NAVIGATION.INSURANCES'
          },
          {
            title: 'NAVIGATION.AMORTIZATION_BALANCE',
            icon: PolicyIcon,
            href: '/policies'
          }
        ]
      },
      {
        icon: InsuranceIcon,
        title: 'NAVIGATION.CFDI_ISSUED',
        children: [
          {
            icon: InvoiceIcon,
            title: 'NAVIGATION.CFDI_ISSUED',
            href: '/tax-accounting/cfdi-issued'
          },
          {
            icon: IncomeIcon,
            title: 'NAVIGATION.CFDI_INCOME',
            href: '/tax-accounting/cfdi-income'
          },
          {
            icon: IncomeIcon,
            title: 'NAVIGATION.CFDI_EXPENSE',
            href: '/tax-accounting/cfdi-expense'
          },
          {
            icon: IncomeIcon,
            title: 'NAVIGATION.CFDI_PAYMENT',
            href: '/tax-accounting/cfdi-payment'
          }
        ]
      },
      {
        icon: InsuranceIcon,
        title: 'NAVIGATION.CFDI_RECEIVED',
        children: [
          {
            icon: InvoiceIcon,
            title: 'NAVIGATION.CFDI_RECEIVED',
            href: '/tax-accounting/cfdi-received'
          }
        ]
      },
      {
        icon: MoneyOffIcon,
        title: 'NAVIGATION.WITHHOLDINGS',
        children: [
          {
            icon: SummarizeIcon,
            title: 'COMMON.SUMMARY',
            href: '/tax-accounting/withholdings/resume'
          }
        ]
      }
    ]
  },
  {
    title: 'NAVIGATION.AUDIT',
    children: [
      {
        icon: IncomeIcon,
        title: 'NAVIGATION.IDENTITY',
        href: '/identity'
      }
    ]
  },
  {
    title: 'NAVIGATION.ADMINISTRATION',
    children: [
      {
        icon: GroupIcon,
        title: 'NAVIGATION.COUNSELORS',
        href: '/counselors'
      },
      {
        icon: CatalogIcon,
        title: 'NAVIGATION.CATALOGS',
        href: '/catalogs'
      },
      {
        href: '/parameters',
        icon: ParametersIcon,
        title: 'NAVIGATION.PARAMETERS'
      },
      {
        href: '/quoters/categories',
        icon: QuoterIcon,
        title: 'NAVIGATION.QUOTE'
      }
    ]
  }
];

export const CATALOG_ROUTES = [
  {
    href: '/catalogs/agents',
    title: 'NAVIGATION.CATALOG_LIST.AGENT'
  },
  {
    href: '/catalogs/insurers',
    title: 'NAVIGATION.CATALOG_LIST.INSURER'
  },
  {
    href: '/catalogs/companies',
    title: 'NAVIGATION.CATALOG_LIST.COMPANIES'
  },
  {
    href: '/catalogs/brands',
    title: 'NAVIGATION.CATALOG_LIST.BRANDS'
  },
  {
    href: '/catalogs/equipment-types',
    title: 'NAVIGATION.CATALOG_LIST.EQUIPMENT_TYPES'
  },
  {
    href: '/catalogs/services',
    title: 'NAVIGATION.CATALOG_LIST.SERVICES'
  },
  {
    href: '/catalogs/incidents',
    title: 'NAVIGATION.CATALOG_LIST.INCIDENTS'
  },
  {
    href: '/catalogs/tax-system',
    title: 'NAVIGATION.CATALOG_LIST.TAX_SYSTEM'
  },
  {
    href: '/catalogs/billing-concepts',
    title: 'NAVIGATION.CATALOG_LIST.BILLING_CONCEPT'
  },
  {
    href: '/catalogs/denominations',
    title: 'NAVIGATION.CATALOG_LIST.DENOMINATIONS'
  },
  {
    href: '/catalogs/kinships',
    title: 'NAVIGATION.CATALOG_LIST.KINSHIP'
  },
  {
    href: '/catalogs/industries',
    title: 'NAVIGATION.CATALOG_LIST.INDUSTRIES'
  },
  {
    href: '/catalogs/administrative-roles',
    title: 'NAVIGATION.CATALOG_LIST.ADMINISTRATIVES_ROLES'
  },
  {
    href: '/catalogs/economic-activities',
    title: 'NAVIGATION.CATALOG_LIST.ECONOMIC_ACTIVITIES'
  },
  {
    href: '/catalogs/banks',
    title: 'NAVIGATION.CATALOG_LIST.SANTANDER_BANKS'
  },
  {
    href: '/catalogs/lines-of-business',
    title: 'NAVIGATION.CATALOG_LIST.LINES_OF_BUSINESS'
  }
];

export const superAdminUserRoutes = adminRoutes.slice().map(route => {
  if (route.title === 'NAVIGATION.ADMINISTRATION') {
    return {
      ...route,
      children: [
        ...route.children,
        {
          href: '/super-admin/administrators',
          icon: SupervisorAccountIcon,
          title: 'NAVIGATION.USERS'
        }
      ]
    };
  }
  return route;
});
