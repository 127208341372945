import { PaymentInputOption } from './quotes';

export const IconStatusAndColor = {
  OK: { status: 'OK', color: '#7cb342', backgroundColor: '#7cb3421a' },
  WARNING: {
    status: 'WARNING',
    color: '#fdd835',
    backgroundColor: '#fdd8351a'
  },
  ERROR: { status: ' ERROR', color: '#df4d49', backgroundColor: '#df4d491a' },
  NONE: { status: 'NONE', color: '#f44336', backgroundColor: '#f443361a' }
};

export const IconStatusAndColorVariants = {
  LIGHT: {
    OK: { status: 'OK', color: '#8fc95b', backgroundColor: '#8fc95b1a' },
    WARNING: {
      status: 'WARNING',
      color: '#ffb74d',
      backgroundColor: '#ffb74d1a'
    },
    ERROR: { status: ' ERROR', color: '#e57471', backgroundColor: '#e574711a' },
    NONE: { status: 'NONE', color: '#f66c60', backgroundColor: '#f66c601a' }
  },
  DEFAULT: IconStatusAndColor
};

export const SnackBarVariants = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning'
};

export const FirstDayOfMonth = 1;

export default IconStatusAndColor;

export const UserStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING'
};

export const InstagramUrl = 'https://www.instagram.com/';

export const PhoneMask = '#0.0000.0000';

export const DocumentStatus = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED'
};

export const partnerOrShareholderId = 1;

export const KinshipEnum = [
  'PARENT',
  'GRANDPARENT',
  'CHILDREN',
  'SIBLINGS',
  'HALFSIBLINGS',
  'COUSINS',
  'SPOUSE',
  'PARTNER',
  'EXSPOUSE',
  'STEPFATHER',
  'STEPMOTHER',
  'STEPCHILD',
  'STEPSIBLINGS'
];

export const getAmountWithIva = (amount, iva) => {
  return amount * (1 + iva / 100);
};

export const getIvaFromAmountWithoutIva = (amount, iva) => {
  return amount * (iva / 100);
};

export const paymentTypeList = [
  {
    key: PaymentInputOption.PERCENTAGE,
    name: '%'
  },
  {
    key: PaymentInputOption.AMOUNT,
    name: '$'
  }
];

export const getPaymentType = key => {
  const type = paymentTypeList.find(item => item.key === key);
  return type ? type.name : '';
};
