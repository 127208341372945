import { MaxGracePeriodForTwelveMonthsTerm } from 'src/utils/constants/quotes';
import * as Yup from 'yup';

export default parameter => {
  return Yup.object().shape({
    equipmentInsuranceIncluded: Yup.boolean().required(
      'SCHEMA_ERRORS.REQUIRED_FIELD'
    ),
    totalPriceWithIva: Yup.number().required('SCHEMA_ERRORS.PRICE_REQUIRED'),
    gracePeriod: Yup.number()
      .when('term', {
        is: 12,
        then: Yup.number().lessThan(
          MaxGracePeriodForTwelveMonthsTerm,
          'SCHEMA_ERRORS.GRACE_PERIOD_MUST_BE_LESS_THAN_SIXTY'
        )
      })
      .required('SCHEMA_ERRORS.VALUE_REQUIRED'),
    initialPayment: Yup.number().required('SCHEMA_ERRORS.VALUE_REQUIRED'),
    residualPayment: Yup.number()
      .required()
      .test(
        'residual-payment-validation',
        'El valor del pago residual excede el límite',
        // eslint-disable-next-line func-names, consistent-return
        function(value) {
          if (value === 0) {
            return this.createError({
              message: 'SCHEMA_ERRORS.MINIMUM_REQUIRED_RESIDUAL_PAYMENT'
            });
          }
          if (parameter && value) {
            const { totalPriceWithIva } = this.parent;
            if (Number(parameter.maxResidualValue) === 0 && value === 1) {
              return true;
            }
            const maxValue =
              (totalPriceWithIva * Number(parameter.maxResidualValue)) / 100;
            return value > 0 && value <= maxValue;
          }
        }
      )
  });
};
