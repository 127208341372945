import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import {
  formatAmountWithDecimals,
  formatNegativeAmount,
  formatPositiveAmount
} from 'src/utils/formatAmout';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { addIVAToValue, addPercentageToValue } from 'src/utils/common';
import { PersonType } from 'src/utils/constants/clients';
import { translateGracePeriodValueToDaysForFirstRent } from 'src/utils/quotes';
import { PaymentInputOption } from 'src/utils/constants/quotes';
import useStyles from '../styles';

const ReviewData = ({ values }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const initialPaymentAmount =
    values.paymentInputOption === PaymentInputOption.AMOUNT
      ? values.initialPayment
      : values.equipmentCost * (values.initialPayment / 100);

  return (
    <Table classes={{ root: classes.tableRoot }} component="div">
      <TableBody classes={{ root: classes.tableBody }} component="div">
        <TableRow component="div">
          <TableCell
            align="center"
            colSpan={2}
            className={classes.reviewDataSubtitle}
          >
            {t('CONTRACT_VIEW.CONTRACT_DATA')}
          </TableCell>
        </TableRow>
        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.CLIENT')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.clientName}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.EQUIPMENT')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.equipmentId}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.RATE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {addPercentageToValue(formatAmountWithDecimals(values.rate))}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.TERM')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {`${values.term}`}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.EQUIPMENT_COST')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {formatPositiveAmount(values.equipmentCost)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.EQUIPMENT_COST_WITH_IVA')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {formatPositiveAmount(addIVAToValue(values.equipmentCost))}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.INITIAL_PAYMENT')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {formatNegativeAmount(initialPaymentAmount)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.INITIAL_PAYMENT_WITH_IVA')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {formatNegativeAmount(addIVAToValue(initialPaymentAmount))}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.NET_INVESTMENT')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {formatPositiveAmount(values.netInvestment)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.NET_INVESTMENT_WITH_IVA')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {formatPositiveAmount(addIVAToValue(values.netInvestment))}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.RESIDUAL')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {formatPositiveAmount(values.residualPayment)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.RENT')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {formatPositiveAmount(values.rent)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.RENT_WITH_IVA')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {formatPositiveAmount(addIVAToValue(values.rent))}
            </Typography>
          </TableCell>
        </TableRow>

        {!!values.msiDiscountPercentage && !!values.msiDiscountAmount && (
          <TableRow component="div">
            <TableCell component="div" className={classes.reviewDataLabel}>
              {t('CONTRACT_VIEW.MSI_DISCOUNT')}
            </TableCell>
            <TableCell component="div">
              <Typography variant="body2" color="textSecondary">
                {`${values.msiDiscountPercentage}% (-${formatPositiveAmount(
                  values.msiDiscountAmount
                )})`}
              </Typography>
            </TableCell>
          </TableRow>
        )}

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.SIGNATURE_DATE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {selectFormatDate(values.startDate, dateFormatType.DAY)}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.PAY_DAY')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.payday}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.FIRST_RENT_DATE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {selectFormatDate(values.firstRentDate, dateFormatType.DAY)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.LAST_RENT_DATE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {selectFormatDate(values.endDate, dateFormatType.DAY)}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.DAYS_FOR_FIRST_RENT')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {translateGracePeriodValueToDaysForFirstRent(
                values.gracePeriod,
                t
              )}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('CONTRACT_VIEW.SEND_TO_DIGITAL_SIGN')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.sendToDigitalSign ? t('COMMON.YES') : t('COMMON.NO')}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell
            align="center"
            colSpan={2}
            className={classes.reviewDataSubtitle}
          >
            {t('CONTRACT_VIEW.EQUIPMENT_ADDRESS')}
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.STREET')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.equipmentLocation.street}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.EXTERNAL_HOME_NUMBER')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.equipmentLocation.externalHomeNumber}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.INSIDE_HOME_NUMBER')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.equipmentLocation.insideHomeNumber}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.ZIP_CODE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.equipmentLocation.zipCode}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.COLONIA')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.equipmentLocation.colonia}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.CITY')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.equipmentLocation.city}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell component="div" className={classes.reviewDataLabel}>
            {t('COMMON.STATE')}
          </TableCell>
          <TableCell component="div">
            <Typography variant="body2" color="textSecondary">
              {values.equipmentLocation.state}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow component="div">
          <TableCell
            align="center"
            colSpan={2}
            className={classes.reviewDataSubtitle}
          >
            {t('COMMON.AVALS')}
          </TableCell>
        </TableRow>

        {values.clientPersonType === PersonType.LEGAL ? (
          <>
            {values.avals.map((aval, index) => (
              <TableRow component="div">
                <TableCell component="div" className={classes.reviewDataLabel}>
                  {index === 0
                    ? t('ENUMS.AVAL_ROLE.LEGAL_REPRESENTATIVE')
                    : `${t('COMMON.AVAL')} ${index}`}
                </TableCell>
                <TableCell component="div">
                  <Typography variant="body2" color="textSecondary">
                    {aval.fullName}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </>
        ) : (
          <>
            {values.avals.map((aval, index) => (
              <TableRow component="div">
                <TableCell component="div" className={classes.reviewDataLabel}>
                  {index === 0
                    ? t('COMMON.AVAL')
                    : `${t('COMMON.AVAL')} ${index + 1}`}
                </TableCell>
                <TableCell component="div">
                  <Typography variant="body2" color="textSecondary">
                    {aval.fullName}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </>
        )}
      </TableBody>
    </Table>
  );
};
ReviewData.propTypes = {
  values: PropTypes.object.isRequired
};

ReviewData.propTypes = {
  values: PropTypes.object.isRequired
};

export default ReviewData;
