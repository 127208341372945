import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { formatPositiveAmount } from 'src/utils/formatAmout';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  getAutomaticDebitCollectionStatus,
  AutomaticDebitCollectionOptions,
  AutomaticDebitCollectionStatus,
  getAutomaticDebitCollectionMenuOptions
} from 'src/utils/payments';
import {
  billPaymentAction,
  cancelPaymentAction,
  editPaymentDueDateAction
} from 'src/store/actions/paymentsActions';
import { TableRow, Tooltip, Typography } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import MoreMenu from 'src/components/MoreMenu/MoreMenu';
import CancelAutomaticDebitCollectionDialog from '../CancelAutomaticDebitCollectionDialog';
import EditAutomaticDebitCollectionDueDateForm from '../EditAutomaticDebitCollectionDueDateForm';

const AutomaticDebitCollectionRow = ({
  contractFolio,
  client,
  clientId,
  contractId,
  status,
  amount,
  debitedAmount,
  dueDate,
  id
}) => {
  const dispatch = useDispatch();
  const [openCancelationDialog, setOpenCancelationDialog] = useState(false);
  const [openSetNewDueDateForm, setOpenSetNewDueDateForm] = useState(false);
  const handleClickMore = option => {
    if (option === AutomaticDebitCollectionOptions.CANCEL) {
      setOpenCancelationDialog(true);
    }
    if (option === AutomaticDebitCollectionOptions.SET_NEW_DUE_DATE) {
      setOpenSetNewDueDateForm(true);
    }
    if (option === AutomaticDebitCollectionOptions.SEND_TO_REBILL) {
      dispatch(billPaymentAction(id));
    }
  };

  const handleCloseCancelationDialog = () => {
    setOpenCancelationDialog(false);
  };
  const handleCloseSetNewDieDateForm = () => {
    setOpenSetNewDueDateForm(false);
  };

  const handleSubmitCancelation = useCallback(
    automaticDebitCollectionId => {
      dispatch(
        cancelPaymentAction(
          automaticDebitCollectionId,
          handleCloseCancelationDialog
        )
      );
    },
    [id]
  );
  const handleSubmitDueDateEdition = useCallback(values => {
    dispatch(
      editPaymentDueDateAction(
        values.id,
        values.dueDate,
        handleCloseSetNewDieDateForm
      )
    );
  }, []);

  return (
    <TableRow hover>
      <TableCell align="center">
        <Tooltip title={contractFolio}>
          <Link to={`/contracts/${contractId}`}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '1.43'
              }}
            >
              {contractFolio}
            </Typography>
          </Link>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        {client && client.length > 45 ? (
          <Tooltip title={client}>
            <Link to={`/clients/detail/${clientId}`}>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '1.43'
                }}
              >
                {`${client.slice(0, 45)}...`}
              </Typography>
            </Link>
          </Tooltip>
        ) : (
          <Link to={`/clients/detail/${clientId}`}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '1.43'
              }}
            >
              {client}
            </Typography>
          </Link>
        )}
      </TableCell>
      <TableCell align="center">{formatPositiveAmount(amount)}</TableCell>
      <TableCell align="center">
        {formatPositiveAmount(debitedAmount)}
      </TableCell>
      <TableCell align="center">
        {getAutomaticDebitCollectionStatus(status)}
      </TableCell>
      <TableCell align="center">
        {selectFormatDate(dueDate, dateFormatType.DAY)}
      </TableCell>
      <TableCell align="center">
        <MoreMenu
          deleteDisabled
          editDisabled
          disabled={
            status !== AutomaticDebitCollectionStatus.PENDING &&
            status !== AutomaticDebitCollectionStatus.FAILED_TO_BE_DEBITED &&
            status !== AutomaticDebitCollectionStatus.FAILED_TO_BE_PROCESSED
          }
          options={getAutomaticDebitCollectionMenuOptions(status)}
          handleClickOption={handleClickMore}
        />
      </TableCell>
      <CancelAutomaticDebitCollectionDialog
        open={openCancelationDialog}
        onClose={handleCloseCancelationDialog}
        automaticDebitCollectionId={id}
        onSubmit={handleSubmitCancelation}
      />
      <EditAutomaticDebitCollectionDueDateForm
        open={openSetNewDueDateForm}
        onClose={handleCloseSetNewDieDateForm}
        automaticDebitCollectionId={id}
        onSubmit={handleSubmitDueDateEdition}
        currentDueDate={dueDate}
      />
    </TableRow>
  );
};

AutomaticDebitCollectionRow.propTypes = {
  contractFolio: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  debitedAmount: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  dueDate: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  contractId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default AutomaticDebitCollectionRow;
