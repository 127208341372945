import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Collapse } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { QuoteModality } from 'src/utils/constants/quotes';
import PropTypes from 'prop-types';

const DistributorAlert = ({ distributors = [], loading, modality }) => {
  const [open] = useState(true);

  const { t } = useTranslation();

  if (!loading) {
    if (!distributors.length) {
      if (modality && modality === QuoteModality.MSI) {
        return (
          <Collapse in={open}>
            <Alert severity="error">
              {t('QUOTES_VIEW.CREATION_FORM.ALERT.NO_MSI_DISTRIBUTORS_FIRST')}
              <Link to="distributors/business">
                {t(
                  'QUOTES_VIEW.CREATION_FORM.ALERT.NO_MSI_DISTRIBUTORS_SECOND'
                )}
              </Link>
            </Alert>
          </Collapse>
        );
      }
      return (
        <Collapse in={open}>
          <Alert severity="error">
            {t('QUOTES_VIEW.CREATION_FORM.ALERT.NO_DISTRIBUTORS_FIRST')}
            <Link to="distributors/business">
              {t('QUOTES_VIEW.CREATION_FORM.ALERT.NO_DISTRIBUTORS_SECOND')}
            </Link>
          </Alert>
        </Collapse>
      );
    }
  }

  return null;
};

DistributorAlert.propTypes = {
  loading: PropTypes.bool.isRequired,
  distributors: PropTypes.array.isRequired,
  modality: PropTypes.string
};

export default DistributorAlert;
