import { Box } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDiscountGrid } from 'src/store/selectors/catalogSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  CALCULATE_DISCOUNT_GRID,
  CLEAR_DISCOUNT_GRID,
  downloadDiscountMsiGrid
} from 'src/store/actions/catalogActions';
import DownloadButton from 'src/components/DownloadButton';
import { formatAmount } from 'src/utils/formatAmout';
import CalculateGridForm from './CalculateGridForm/CalculateGridForm';
import DiscountGridTable from './DiscountGridTable';

const DiscountGridView = () => {
  const gridData = useSelector(selectDiscountGrid);
  const isLoading = useSelector(
    isLoadingSelector([CALCULATE_DISCOUNT_GRID.action])
  );
  const dispatch = useDispatch();
  const [rate, setRate] = useState(0);

  const formattedGridData = useMemo(() => {
    return gridData.map(item => ({
      ...item,
      discountTwelve: formatAmount(item.discountTwelve),
      discountEighteen: formatAmount(item.discountEighteen),
      discountTwentyFour: formatAmount(item.discountTwentyFour)
    }));
  }, [gridData]);

  const handleDiscountGridExport = useCallback(() => {
    const dataToExport = {
      rate: Number(rate),
      discountMsiGrid: formattedGridData
    };
    dispatch(downloadDiscountMsiGrid(dataToExport));
  }, [dispatch, gridData, rate]);

  useEffect(() => {
    dispatch({ type: CLEAR_DISCOUNT_GRID });
  }, []);

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <CalculateGridForm setRate={setRate} />
          <DownloadButton
            onDownload={handleDiscountGridExport}
            title="COMMON.DOWNLOAD"
            disabled={!rate}
          />
        </Box>

        <DiscountGridTable gridData={gridData} loading={isLoading} />
      </Box>
    </>
  );
};

export default DiscountGridView;
