import {
  CLEAR_CONTRACTS,
  CLEAR_CONTRACT_DETAIL,
  CLEAR_CONTRACT_OPTIONS,
  LOAD_CONTRACTS,
  LOAD_CONTRACT_AVAL_LIST,
  LOAD_CONTRACT_DETAIL,
  LOAD_CONTRACT_PAYMENT_SCHEDULE,
  LOAD_RENT_BALANCE_SCHEDULE,
  SAVE_CONTRACT_OPTIONS,
  LOAD_UPFRONT_RENT_NEXT_PERIOD
} from '../actions/contractActions';

const initialState = {
  options: {
    limit: +process.env.REACT_APP_TABLE_SIZE,
    page: 0,
    fullScheduleView: false
  },
  items: [],
  count: 0,
  detail: { equipmentLocation: {}, avals: [] },
  rentBalanceSchedule: [],
  upfrontRent: null
};

const contracReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CONTRACTS.success: {
      return {
        ...state,
        items: action.items,
        count: action.count
      };
    }
    case CLEAR_CONTRACTS: {
      return initialState;
    }
    case SAVE_CONTRACT_OPTIONS: {
      return {
        ...state,
        options: { ...state.options, ...action.options }
      };
    }
    case CLEAR_CONTRACT_OPTIONS: {
      return {
        ...state,
        options: initialState.options
      };
    }
    case LOAD_CONTRACT_DETAIL.success: {
      return {
        ...state,
        detail: { ...state.detail, ...action.data }
      };
    }
    case CLEAR_CONTRACT_DETAIL: {
      return {
        ...state,
        detail: initialState.detail
      };
    }
    case LOAD_CONTRACT_PAYMENT_SCHEDULE.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          paymentSchedule: action.data
        }
      };
    }
    case LOAD_RENT_BALANCE_SCHEDULE.success: {
      return {
        ...state,
        rentBalanceSchedule: action.payload
      };
    }
    case LOAD_CONTRACT_AVAL_LIST.success: {
      return {
        ...state,
        detail: {
          ...state.detail,
          avals: action.payload
        }
      };
    }
    case LOAD_UPFRONT_RENT_NEXT_PERIOD.success: {
      return {
        ...state,
        upfrontRent: action.data
      };
    }

    case LOAD_UPFRONT_RENT_NEXT_PERIOD.failure: {
      return {
        ...state,
        upfrontRent: null
      };
    }
    default: {
      return state;
    }
  }
};

export default contracReducer;
