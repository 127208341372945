import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, FieldArray } from 'formik';
import {
  Box,
  Button,
  FormControl,
  TextField,
  IconButton,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Delete as DeleteIcon } from '@mui/icons-material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { formatAmount } from 'src/utils/formatAmout';
import { QuoteModality } from 'src/utils/constants/quotes';
import MoneyMask from 'src/components/MoneyMask/MoneyMask';
import NumericStepper from 'src/components/NumericStepper/NumericStepper';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import EquipmentsAlert from 'src/components/EquipmentsAlert/EquipmentsAlert';
import {
  selectDistributorList,
  selectDistributorCatalogs
} from 'src/store/selectors/distributorSelectors';
import {
  loadDistributorAgents,
  loadDistributors,
  loadDistributorEquipmentsCatalog,
  CLEAR_DISTRIBUTORS_CATALOGS
} from 'src/store/actions/distributorActions';
import { CLEAR_KAM_DETAIL } from 'src/store/actions/userKamActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import NoMsiParametersAlert from '../../../Alerts/NoMsiParametersAlert';
import DistributorAlert from './DistributorAlert';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const DEFAULT_EQUIPMENT = {
  id: '',
  priceWithIva: '',
  model: '',
  quantity: 0
};

const MSI_MAX_EQUIPMENTS = 1;
const REGULAR_MAX_EQUIPMENTS = 6;
const MIN_EQUIPMENTS = 1;

const StepTwo = ({ data, handleNext, handleBack, resetRef }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const distributors = useSelector(selectDistributorList);
  const equipments = useSelector(selectDistributorCatalogs);
  const isLoadingDistributor = useSelector(
    isLoadingSelector(['LOAD_DISTRIBUTORS'])
  );
  const isLoadingEquipments = useSelector(
    isLoadingSelector(['LOAD_DISTRIBUTOR_CATALOGS'])
  );

  const initialState = useMemo(() => makeInitialState({ ...data }), [
    data.modality,
    data.modalityChanged
  ]);

  const [maxAmountOfEquipments, setMaxAmountOfEquipments] = useState(
    data.modality === QuoteModality.MSI
      ? MSI_MAX_EQUIPMENTS
      : REGULAR_MAX_EQUIPMENTS
  );
  const [
    selectedEquipmentQuantities,
    setSelectedEquipmentQuantities
  ] = useState(
    data.selectedEquipmentQuantities ||
      initialState.selectedEquipmentQuantities ||
      []
  );
  const [totalQuantity, setTotalQuantity] = useState(
    data.totalQuantity || initialState.totalQuantity || 0
  );
  const [isPriceEditable, setIsPriceEditable] = useState(false);
  const [isNextClicked, setIsNextClicked] = useState(false);
  const [totalPriceWithIva, setTotalPriceWithIva] = useState('0.00');

  const handleQuantityChange = (index, newQuantity) => {
    const updatedQuantities = [...selectedEquipmentQuantities];
    const oldQuantity = updatedQuantities[index] || 0;
    const quantityDiff = newQuantity - oldQuantity;
    const newTotalQuantity = totalQuantity + quantityDiff;

    if (newTotalQuantity <= maxAmountOfEquipments && newTotalQuantity > 0) {
      updatedQuantities[index] = newQuantity;
      setSelectedEquipmentQuantities(updatedQuantities);
      setTotalQuantity(newTotalQuantity);
    }
  };

  const [noMsiParametersSettedUp, setNoMsiParametersSettedUp] = useState(
    data.distributor
      ? !!(data.distributor?.enableMsi && !data.distributor?.hasDiscountMsi)
      : false
  );

  useEffect(() => {
    console.log('totalQuantity: ', totalQuantity);
  }, [totalQuantity]);

  const calculateTotalPrice = useCallback(
    equipmentsSelected => {
      const total = equipmentsSelected
        .reduce((eqTotal, equipment, index) => {
          const quantity = selectedEquipmentQuantities[index] || 1;
          return eqTotal + (parseFloat(equipment.priceWithIva) || 0) * quantity;
        }, 0)
        .toFixed(2);
      setTotalPriceWithIva(total);
      return total;
    },
    [selectedEquipmentQuantities]
  );

  const validateAndFilterEquipments = useCallback(equipmentsSelected => {
    const validEquipments = equipmentsSelected.filter(
      equipment => equipment.id
    );
    return validEquipments.length >= MIN_EQUIPMENTS
      ? validEquipments
      : equipmentsSelected;
  }, []);

  const handleDistributorChange = (distributor, setFieldValue) => {
    dispatch({ type: CLEAR_KAM_DETAIL });
    dispatch({ type: CLEAR_DISTRIBUTORS_CATALOGS });
    setNoMsiParametersSettedUp(
      !!(distributor?.enableMsi && !distributor?.hasDiscountMsi)
    );
    setIsPriceEditable(distributor.isPriceEditable);

    const isSameDistributor = data.distributor?.id === distributor.id;
    setFieldValue('distributor', distributor);

    if (!isSameDistributor) {
      setFieldValue('distributorEquipments', [DEFAULT_EQUIPMENT]);
      setSelectedEquipmentQuantities([]);
      setTotalQuantity(0);
    }

    if (distributor?.id) {
      dispatch(loadDistributorAgents(distributor.id, null, false));
      const params =
        data.modality === QuoteModality.MSI ? { enableMsi: true } : {};
      dispatch(loadDistributorEquipmentsCatalog(distributor.id, params, false));
    }
  };

  const handleDistributorEquipmentsChange = useCallback(
    (equipment, setFieldValue, index, values) => {
      const updatedEquipments = [...values.distributorEquipments];
      updatedEquipments[index] = {
        ...equipment,
        priceWithIva: equipment ? equipment.priceWithIva.toFixed(2) : ''
      };

      const updatedQuantities = [...selectedEquipmentQuantities];
      const oldQuantity = updatedQuantities[index] || 0;
      updatedQuantities[index] = equipment ? 1 : 0;

      setFieldValue('distributorEquipments', updatedEquipments);
      setSelectedEquipmentQuantities(updatedQuantities);
      setTotalQuantity(totalQuantity - oldQuantity + (equipment ? 1 : 0));
      calculateTotalPrice(updatedEquipments);
    },
    [calculateTotalPrice, totalQuantity, selectedEquipmentQuantities]
  );

  const handleBackWithState = useCallback(
    values => {
      handleBack({
        ...data,
        ...values,
        selectedEquipmentQuantities,
        totalQuantity,
        totalPriceWithIva
      });
    },
    [
      handleBack,
      data,
      selectedEquipmentQuantities,
      totalQuantity,
      totalPriceWithIva
    ]
  );

  const onNext = useCallback(
    async values => {
      const updatedEquipments = values.distributorEquipments.map(
        (equipment, index) => ({
          ...equipment,
          quantity: selectedEquipmentQuantities[index] || 1
        })
      );

      const filteredEquipments = validateAndFilterEquipments(updatedEquipments);

      if (totalQuantity >= MIN_EQUIPMENTS) {
        handleNext({
          ...values,
          modality: data.modality,
          distributorEquipments: filteredEquipments,
          totalPriceWithIva,
          totalQuantity,
          selectedEquipmentQuantities
        });
      }
    },
    [
      handleNext,
      totalPriceWithIva,
      validateAndFilterEquipments,
      selectedEquipmentQuantities,
      totalQuantity,
      data.modality
    ]
  );

  useEffect(() => {
    if (data.modalityChanged) {
      setSelectedEquipmentQuantities([]);
      setTotalQuantity(0);
      setTotalPriceWithIva('0.00');
      setIsPriceEditable(false);
      setIsNextClicked(false);

      dispatch({ type: CLEAR_KAM_DETAIL });
      dispatch({ type: CLEAR_DISTRIBUTORS_CATALOGS });

      if (resetRef.current) {
        resetRef.current();
      }
    }

    dispatch(
      loadDistributors(
        {
          enableMsi: data.modality === QuoteModality.MSI,
          enableUpfrontRent: data.modality === QuoteModality.FIRST_UPFRONT_RENT
        },
        false
      )
    );
    setMaxAmountOfEquipments(
      data.modality === QuoteModality.MSI
        ? MSI_MAX_EQUIPMENTS
        : REGULAR_MAX_EQUIPMENTS
    );
  }, [data.modalityChanged, data.modality]);

  const renderEquipmentFields = (values, setFieldValue) => (
    equipment,
    index
  ) => (
    <Box
      key={`equipment-${index}`}
      display="flex"
      alignItems="center"
      gap={2}
      mt={1}
      mb={2}
    >
      <Autocomplete
        options={equipments.filter(
          eq =>
            !values.distributorEquipments.some(
              dEquipment => dEquipment.id === eq.id
            )
        )}
        id={`equipment-select-${index}`}
        getOptionLabel={option => option.model || ''}
        value={equipment ?? ''}
        label={t('COMMON.EQUIPMENT')}
        placeholder={t('COMMON.SELECT_EQUIPMENT')}
        fullWidth
        sx={{ width: '55%' }}
        onChange={(_e, value) => {
          handleDistributorEquipmentsChange(
            value,
            setFieldValue,
            index,
            values
          );
        }}
        renderInput={params => <TextField {...params} variant="outlined" />}
      />
      <NumericStepper
        value={selectedEquipmentQuantities[index]}
        onIncrease={() =>
          handleQuantityChange(
            index,
            (selectedEquipmentQuantities[index] || 1) + 1
          )
        }
        onDecrease={() =>
          handleQuantityChange(
            index,
            (selectedEquipmentQuantities[index] || 1) - 1
          )
        }
        minValue={1}
        maxValue={maxAmountOfEquipments}
        // eslint-disable-next-line react/destructuring-assignment
        disabled={!equipment.id}
        id={`quantity-stepper-${index}`}
      />
      <TextField
        InputProps={{
          autoComplete: 'off',
          inputComponent: MoneyMask
        }}
        label={t('COMMON.AMOUNT_WITH_IVA')}
        sx={{ width: '30%' }}
        variant="outlined"
        // eslint-disable-next-line react/destructuring-assignment
        value={equipment.priceWithIva ?? ''}
        onChange={e => {
          const updatedEquipments = [...values.distributorEquipments];
          updatedEquipments[index].priceWithIva = e.target.value;
          setFieldValue('distributorEquipments', updatedEquipments);
          calculateTotalPrice(updatedEquipments);
        }}
        disabled={!isPriceEditable}
        fullWidth
        id={`price-input-${index}`}
      />
      <IconButton
        onClick={() => {
          const updatedEquipments = [...values.distributorEquipments];
          const updatedQuantities = [...selectedEquipmentQuantities];
          const removedQuantity = updatedQuantities[index] || 0;

          updatedEquipments.splice(index, 1);
          updatedQuantities.splice(index, 1);

          setFieldValue('distributorEquipments', updatedEquipments);
          setSelectedEquipmentQuantities(updatedQuantities);
          setTotalQuantity(totalQuantity - removedQuantity);
          calculateTotalPrice(updatedEquipments);
        }}
        id={`delete-equipment-${index}`}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );

  return (
    <Formik
      initialValues={initialState}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onNext}
    >
      {({
        errors,
        handleSubmit,
        touched,
        values,
        setFieldValue,
        resetForm
      }) => {
        resetRef.current = () => resetForm();

        return (
          <form onSubmit={handleSubmit} id="step-two-form">
            <Box className={classes.container} sx={{ mb: 2 }}>
              <Box sx={{ mt: 1 }}>
                {data.modality === QuoteModality.MSI &&
                  noMsiParametersSettedUp &&
                  values.distributor?.id && (
                    <NoMsiParametersAlert
                      open
                      distributorId={values.distributor.id}
                    />
                  )}
                <DistributorAlert
                  distributors={distributors}
                  loading={isLoadingDistributor}
                  modality={data.modality}
                />
                <EquipmentsAlert
                  equipments={equipments}
                  loading={values.distributor && isLoadingEquipments}
                  distributor={values.distributor}
                  modality={data.modality}
                />
              </Box>

              <FormControl
                fullWidth
                error={Boolean(touched.distributor && errors.distributor)}
              >
                <Autocomplete
                  options={distributors}
                  id="distributor-select"
                  loading={isLoadingDistributor}
                  getOptionLabel={option =>
                    option ? `${option.id} - ${option.name}` : ''
                  }
                  value={values.distributor ?? ''}
                  fullWidth
                  onChange={(_e, value) =>
                    handleDistributorChange(value, setFieldValue)
                  }
                  sx={{ marginBottom: 2, marginTop: 2 }}
                  label={t('COMMON.DISTRIBUTOR')}
                  error={Boolean(touched.distributor && errors.distributor)}
                  helperText={touched.distributor && t(errors.distributor)}
                />
              </FormControl>

              <FieldArray name="distributorEquipments">
                {({ push }) => (
                  <Box>
                    <Box
                      sx={{
                        maxHeight: '200px',
                        overflowY: 'auto',
                        marginBottom: '10px'
                      }}
                    >
                      {values.distributorEquipments.map(
                        renderEquipmentFields(values, setFieldValue)
                      )}
                    </Box>

                    <Button
                      variant="outlined"
                      onClick={() => push(DEFAULT_EQUIPMENT)}
                      disabled={
                        isLoadingEquipments ||
                        !equipments.length ||
                        totalQuantity >= maxAmountOfEquipments
                      }
                      id="add-equipment-button"
                    >
                      {`+ ${t('COMMON.ADD')}`}
                    </Button>

                    {totalQuantity > maxAmountOfEquipments && (
                      <Typography
                        className={clsx('error', classes.error)}
                        variant="body2"
                        color="error"
                        sx={{ marginTop: '10px' }}
                      >
                        {t('SCHEMA_ERRORS.MAX_EQUIPMENTS_SELECTED', {
                          maxAmountOfEquipments
                        })}
                      </Typography>
                    )}

                    <Box mt={3}>
                      <TextField
                        InputProps={{
                          autoComplete: 'off',
                          inputComponent: MoneyMask
                        }}
                        label={t(
                          'QUOTES_VIEW.MULTI_QUOTE.TOTAL_AMOUNT_WITH_IVA'
                        )}
                        value={formatAmount(
                          calculateTotalPrice(values.distributorEquipments),
                          2
                        )}
                        id="total-price-input"
                        disabled
                        fullWidth
                      />
                    </Box>
                  </Box>
                )}
              </FieldArray>
            </Box>

            {isNextClicked && totalQuantity < MIN_EQUIPMENTS && (
              <Typography
                className={clsx('error', classes.error)}
                variant="body2"
                color="error"
              >
                {t('SCHEMA_ERRORS.MIN_EQUIPMENTS_REQUIRED')}
              </Typography>
            )}

            <Box
              className={classes.tableBox}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                className={classes.buttons}
                onClick={() => handleBackWithState(values)}
                color="primary"
                variant="outlined"
                id="back-button"
              >
                {t('COMMON.BACK')}
              </Button>
              <LoadingButton
                className={clsx(classes.buttons, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
                loadingPosition="start"
                disabled={
                  totalQuantity > maxAmountOfEquipments ||
                  (noMsiParametersSettedUp &&
                    data.modality === QuoteModality.MSI) ||
                  equipments.length === 0
                }
                onClick={() => {
                  setIsNextClicked(true);
                }}
                id="next-button"
              >
                {t('COMMON.NEXT')}
              </LoadingButton>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

StepTwo.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  resetRef: PropTypes.object
};

export default StepTwo;
