import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PercentageMask from 'src/components/PercentageMask/PercentageMask';
import clsx from 'clsx';
import DropdownMenu from 'src/components/DropdownMenu';
import {
  TermValues,
  getTermListForQuoteAndContracts
} from 'src/utils/constants/distributors';
import { selectDistributorDetail } from 'src/store/selectors/distributorSelectors';
import MoneyMask from 'src/components/MoneyMask/MoneyMask';
import { useDispatch, useSelector } from 'react-redux';
import {
  calculateMonthlyRent,
  calculateSingleMsiDiscount
} from 'src/store/actions/contractActions';
import PaymentMultipleInput from 'src/components/PaymentMultipleInput';
import { PaymentInputOption } from 'src/utils/constants/quotes';
import { Parameter } from 'src/utils/constants/parameters';
import { loadParameter } from 'src/store/actions/parameterActions';
import { validateTermAndInitialPayment } from 'src/utils/calculator';
import { selectIndividualParameter } from 'src/store/selectors/parameterSelector';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initalState';

const CalculateNetInvestment = ({ values, setFieldValue }) => {
  useEffect(() => {
    const { initialPayment, equipmentCost, paymentInputOption } = values;
    // TODO: define the formula for net investment with initialPayment with iva
    const initialPaymentAmount =
      paymentInputOption === PaymentInputOption.PERCENTAGE
        ? (initialPayment / 100) * equipmentCost
        : initialPayment;

    setFieldValue('netInvestment', equipmentCost - initialPaymentAmount);
  }, [values.initialPayment, values.equipmentCost, values.paymentInputOption]);

  return null;
};

CalculateNetInvestment.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

const CalculateRent = ({ values, setFieldValue }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const {
      rate,
      term,
      residualPayment,
      equipmentCost,
      initialPayment,
      paymentInputOption
    } = values;

    if (
      !rate ||
      !term ||
      !residualPayment ||
      !equipmentCost ||
      initialPayment === undefined
    ) {
      setFieldValue('rent', 0);
      return;
    }

    let initialPaymentAmount;

    if (paymentInputOption === PaymentInputOption.PERCENTAGE) {
      initialPaymentAmount = (initialPayment / 100) * equipmentCost;
    } else {
      initialPaymentAmount = initialPayment;
    }

    if (values.enableMsi) {
      const rent = (equipmentCost - initialPaymentAmount) / term;
      setFieldValue('rent', rent);
      return;
    }

    dispatch(
      calculateMonthlyRent(
        {
          rate,
          term,
          residualPayment,
          equipmentCost,
          initialPayment: initialPaymentAmount
        },
        setFieldValue
      )
    );
  }, [
    values.rate,
    values.term,
    values.residualPayment,
    values.initialPayment,
    values.equipmentCost,
    values.paymentInputOption
  ]);

  return null;
};

CalculateRent.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

const CalculateMsiDiscount = ({ values, setFieldValue }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const {
      rate,
      term,
      equipmentCost,
      initialPayment,
      paymentInputOption
    } = values;

    if (
      !rate ||
      !term ||
      !equipmentCost ||
      initialPayment === undefined ||
      initialPayment === null
    ) {
      setFieldValue('msiDiscountPercentage', 0);
      return;
    }

    let initialPaymentAmount;

    if (paymentInputOption === PaymentInputOption.PERCENTAGE) {
      initialPaymentAmount = (initialPayment / 100) * equipmentCost;
    } else {
      initialPaymentAmount = initialPayment;
    }

    dispatch(
      calculateSingleMsiDiscount(
        {
          rate,
          term,
          equipmentCost,
          initialPayment: initialPaymentAmount
        },
        setFieldValue
      )
    );
  }, [
    values.rate,
    values.term,
    values.initialPayment,
    values.equipmentCost,
    values.paymentInputOption
  ]);

  return null;
};

CalculateMsiDiscount.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

const StepThree = ({ data, handleBack, handleNext, resetRef }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const parameter = useSelector(selectDistributorDetail);
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const dispatch = useDispatch();
  const maxInitialPayment = useSelector(selectIndividualParameter);
  const maxResidualValue = Number(parameter.maxResidualValue);

  const hasDistributorAssociated = Boolean(parameter);

  useEffect(() => {
    if (hasDistributorAssociated) {
      dispatch(loadParameter(Parameter.MAXIMUM_INITIAL_PAYMENT_PERCENTAGE));
    }
  }, []);

  const onNext = useCallback(
    (values, setFieldError) => {
      if (hasDistributorAssociated) {
        const isValidated = validateTermAndInitialPayment(
          { ...values, price: values.equipmentCost },
          setFieldError,
          {
            minInitialPaymentPercentage: parameter.minInitialPayment,
            maxInitialPaymentPercentage: Number(maxInitialPayment),
            maxTerm: parameter.maxTerm,
            minTerm: parameter.minTerm
          },
          t,
          values.enableMsi
        );
        if (!isValidated) return;
      }
      handleNext(values);
    },
    [handleNext, maxInitialPayment, parameter]
  );

  const handleCheckboxMsiChange = useCallback((enableMsi, setFieldValue) => {
    if (enableMsi) {
      setFieldValue('rate', null);
      setFieldValue('term', null);
      setFieldValue('term', TermValues.TWELVE_MSI);
    } else {
      setFieldValue('rate', Number(parameter.discountMsi.implicitRate));
    }
    setFieldValue('residualPayment', 1);
  }, []);

  const handleCheckboxAnticipatedFirstRentChange = useCallback(
    (_, setFieldValue) => {
      setFieldValue('residualPayment', 1);
    },
    []
  );

  useEffect(() => {
    if (hasDistributorAssociated) {
      dispatch(loadParameter(Parameter.MAXIMUM_INITIAL_PAYMENT_PERCENTAGE));
    }
  }, []);

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema(maxResidualValue)}
      onSubmit={(values, { setFieldError }) => {
        onNext(values, setFieldError);
      }}
    >
      {({
        handleSubmit,
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        resetForm
      }) => {
        resetRef.current = () => resetForm();
        return (
          <form onSubmit={handleSubmit}>
            {parameter.enableMsi && data.equipment.enableMsi && (
              <FormControlLabel
                sx={{ mt: 3 }}
                key="enable-msi"
                control={
                  <Checkbox
                    name="enableMsi"
                    color="primary"
                    checked={values.enableMsi}
                    onChange={() => {
                      handleCheckboxMsiChange(values.enableMsi, setFieldValue);

                      setFieldValue('enableMsi', !values.enableMsi);
                      setFieldValue('isFirstUpfrontRentModality', false);
                    }}
                  />
                }
                label={t('COMMON.MSI_ENABLED')}
              />
            )}
            {parameter.anticipatedFirstRentEnabled && (
              <FormControlLabel
                sx={{ mt: 3 }}
                key="anticipated-first-rent"
                control={
                  <Checkbox
                    name="isFirstUpfrontRentModality"
                    color="primary"
                    checked={values.isFirstUpfrontRentModality}
                    onChange={() => {
                      handleCheckboxAnticipatedFirstRentChange(
                        values.isFirstUpfrontRentModality,
                        setFieldValue
                      );
                      setFieldValue('enableMsi', false);
                      setFieldValue(
                        'isFirstUpfrontRentModality',
                        !values.isFirstUpfrontRentModality
                      );
                    }}
                  />
                }
                label={t('COMMON.ENABLE_ANTICIPATED_FIRST_RENT')}
              />
            )}
            <TextField
              InputProps={{
                autoComplete: 'off'
              }}
              // this was made to avoid rendering issues where enableMsi option is selected
              key={values.enableMsi ? `rate - ${values.rate}` : 'rate'}
              error={Boolean(touched.rate && errors.rate)}
              fullWidth
              helperText={touched.rate && t(errors.rate)}
              label={`${t('CONTRACT_VIEW.RATE')} %`}
              margin="normal"
              name="rate"
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              value={values.rate}
              disabled={values.enableMsi}
              variant="outlined"
            />

            <DropdownMenu
              list={getTermListForQuoteAndContracts(
                parameter,
                values.enableMsi,
                values.isFirstUpfrontRentModality
              )}
              value={values.term}
              setValue={value => setFieldValue('term', value)}
              label={t('CONTRACT_VIEW.TERM')}
              error={Boolean(touched.term && errors.term)}
              errorText={touched.term && t(errors.term)}
              key={values.term}
            />

            <TextField
              InputProps={{
                autoComplete: 'off',
                inputComponent: MoneyMask
              }}
              error={Boolean(touched.equipmentCost && errors.equipmentCost)}
              fullWidth
              helperText={touched.equipmentCost && t(errors.equipmentCost)}
              label={t('CONTRACT_VIEW.EQUIPMENT_COST')}
              margin="normal"
              name="equipmentCost"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.equipmentCost.toFixed(2)}
              variant="outlined"
              disabled
            />

            <PaymentMultipleInput
              firstInputName="paymentInputOption"
              secondInputName="initialPayment"
              label={t('COMMON.INITIAL_PAYMENT')}
            />

            <TextField
              InputProps={{
                autoComplete: 'off',
                inputComponent: MoneyMask
              }}
              error={Boolean(touched.netInvestment && errors.netInvestment)}
              fullWidth
              helperText={touched.netInvestment && t(errors.netInvestment)}
              label={t('CONTRACT_VIEW.NET_INVESTMENT')}
              margin="normal"
              name="netInvestment"
              onBlur={handleBlur}
              disabled
              value={values.netInvestment.toFixed(2)}
              variant="outlined"
            />

            <CalculateNetInvestment
              values={values}
              setFieldValue={setFieldValue}
            />

            <TextField
              InputProps={{
                autoComplete: 'off',
                inputComponent: MoneyMask
              }}
              error={Boolean(touched.residualPayment && errors.residualPayment)}
              fullWidth
              helperText={touched.residualPayment && t(errors.residualPayment)}
              label={t('CONTRACT_VIEW.RESIDUAL')}
              margin="normal"
              name="residualPayment"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.residualPayment}
              disabled={maxResidualValue === 0 || values.enableMsi}
            />

            <TextField
              InputProps={{
                autoComplete: 'off',
                inputComponent: MoneyMask
              }}
              error={Boolean(touched.rent && errors.rent)}
              fullWidth
              helperText={touched.rent && t(errors.rent)}
              label={t('COMMON.RENT')}
              margin="normal"
              name="rent"
              onBlur={handleBlur}
              disabled
              value={values.rent?.toFixed(2)}
              variant="outlined"
            />

            <CalculateRent values={values} setFieldValue={setFieldValue} />

            {values.enableMsi && (
              <>
                <TextField
                  InputProps={{
                    autoComplete: 'off',
                    inputComponent: PercentageMask
                  }}
                  key={`Msi Discount - ${values.msiDiscountPercentage}`}
                  error={Boolean(
                    touched.msiDiscountPercentage &&
                      errors.msiDiscountPercentage
                  )}
                  fullWidth
                  helperText={
                    touched.msiDiscountPercentage &&
                    t(errors.msiDiscountPercentage)
                  }
                  label={t('COMMON.MSI_DISCOUNT')}
                  margin="normal"
                  name="msiDiscountPercentage"
                  onBlur={handleBlur}
                  disabled
                  value={values.msiDiscountPercentage}
                  variant="outlined"
                />
                <CalculateMsiDiscount
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </>
            )}

            <Box
              className={classes.box}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                className={classes.buttons}
                onClick={() => handleBack()}
                color="primary"
                variant="outlined"
              >
                {t('COMMON.BACK')}
              </Button>
              <Button
                className={clsx(classes.buttons, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
              >
                {t('COMMON.NEXT')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

StepThree.propTypes = {
  data: PropTypes.object.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  resetRef: PropTypes.object.isRequired
};
export default StepThree;
