import momentLocalTimezone from 'src/utils/momentLocalTimezone';

const makeInitialState = values => {
  return {
    id: values.id ?? '',
    term: values && values.term,
    moreInfo: values.moreInfo ?? '',
    price: values.price ?? '',
    initialPaymentAmount: values.price * (values.initialPayment / 100) ?? '',
    initialPaymentDate: values.initialPaymentDate ?? momentLocalTimezone(),
    firstRentDate: values.firstRentDate ?? momentLocalTimezone(),
    iva: values.iva ?? '',
    gracePeriod: values.gracePeriod ?? '',
    phone: values.client ? values.client.phone : values.clientData?.phone ?? '',
    isMsiModality: values.isMsiModality ?? false,
    isFirstUpfrontRentModality: values.isFirstUpfrontRentModality ?? false
  };
};

export default makeInitialState;
