import getAllContracts, {
  activateContractAutomaticBilling,
  cancelContract,
  createContractFromQuoteRequest,
  createNewContract,
  createNewContractDates,
  deactivateContractAutomaticBilling,
  downloadContractPaymentSchedule,
  downloadRentBalanceSchedule,
  editContractById,
  generateAutomaticDebitRequestDocument,
  getContractAvalList,
  getContractById,
  getContractPaymentSchedule,
  getMonthlyRent,
  getRentAfterContribution,
  getRentBalanceSchedule,
  sendToSignAutomaticDebitRequestDocument,
  sendToSingContract,
  singContractManually,
  getSingleMsiDiscount,
  downloadContractList,
  getUpfrontRentNextPeriod
} from 'src/services/contractService';
import { push } from 'connected-react-router';
import { asyncActionCreator } from 'src/utils/loadingUtils';
import { SnackBarVariants } from 'src/utils/constants/common';
import { PersonType } from 'src/utils/constants/clients';
import { PaymentInputOption } from 'src/utils/constants/quotes';
import { selectContractsQueryOptions } from '../selectors/contractSelectors';
import { showMessage } from './snackBarActions';
import {
  createCapitalContribution,
  getContributionsByContract
} from '../../services/contractService';
import uploadSingleFile from './commonActions';

export const SAVE_CONTRACT_OPTIONS = 'SAVE_CONTRACT_OPTIONS';
export const CLEAR_CONTRACT_OPTIONS = 'CLEAR_CONTRACT_OPTIONS';
export const CLEAR_CONTRACTS = 'CLEAR_CONTRACTS';
export const CLEAR_CONTRIBUTIONS = 'CLEAR_CONTRIBUTIONS';
export const CLEAR_CONTRACT_DETAIL = 'CLEAR_CONTRACT_DETAIL';
export const LOAD_CONTRACTS = asyncActionCreator('LOAD_CONTRACTS');
export const DOWNLOAD_CONTRACT_LIST = asyncActionCreator(
  'DOWNLOAD_CONTRACT_LIST'
);
export const CREATE_CONTRACT = asyncActionCreator('CREATE_CONTRACT');
export const UPDATE_CONTRACT_DATES = asyncActionCreator(
  'UPDATE_CONTRACT_DATES'
);
export const LOAD_CONTRACT_DETAIL = asyncActionCreator('LOAD_CONTRACT_DETAIL');
export const UPDATE_CONTRACT = asyncActionCreator('UPDATE_CONTRACT');
export const DOWNLOAD_CONTRACT_SCHEDULE = asyncActionCreator(
  'DOWNLOAD_CONTRACT_SCHEDULE'
);
export const LOAD_CONTRACT_PAYMENT_SCHEDULE = asyncActionCreator(
  'LOAD_CONTRACT_PAYMENT_SCHEDULE'
);
export const CANCEL_CONTRACT = asyncActionCreator('CANCEL_CONTRACT');
export const SEND_TO_DIGITAL_SIGN_CONTRACT = asyncActionCreator(
  'SEND_TO_DIGITAL_SIGN_CONTRACT'
);
export const SIGN_CONTRACT_MANUALLY = asyncActionCreator(
  'SIGN_CONTRACT_MANUALLY'
);
export const UPDATE_PROMISSORY_DOCUMENT = asyncActionCreator(
  'UPDATE_PROMISSORY_DOCUMENT'
);
export const CREATE_CONTRIBUTION = asyncActionCreator('CREATE_CONTRIBUTION');
export const LOAD_CONTRIBUTIONS = asyncActionCreator('LOAD_CONTRIBUTIONS');
export const LOAD_RENT_AFTER_CONTRIBUTION = asyncActionCreator(
  'LOAD_RENT_AFTER_CONTRIBUTION'
);
export const LOAD_UPFRONT_RENT_NEXT_PERIOD = asyncActionCreator(
  'LOAD_UPFRONT_RENT_NEXT_PERIOD'
);
export const CLEAR_RENT_AFTER_CONTRIBUTION = 'CLEAR_RENT_AFTER_CONTRIBUTION';
export const CREATE_CONTRACT_FROM_QUOTE = asyncActionCreator(
  'CREATE_CONTRACT_FROM_QUOTE'
);
export const LOAD_RENT_BALANCE_SCHEDULE = asyncActionCreator(
  'LOAD_RENT_BALANCE_SCHEDULE'
);
export const DOWNLOAD_RENT_BALANCE_SCHEDULE = asyncActionCreator(
  'DOWNLOAD_RENT_BALANCE_SCHEDULE'
);
export const LOAD_CONTRACT_AVAL_LIST = asyncActionCreator(
  'LOAD_CONTRACT_AVAL_LIST'
);
export const CALCULATE_MONTHLY_RENT = asyncActionCreator(
  'CALCULATE_MONTHLY_RENT'
);
export const CALCULATE_SINGLE_MSI_DISCOUNT = asyncActionCreator(
  'CALCULATE_SINGLE_MSI_DISCOUNT'
);
export const ACTIVATE_AUTOMATIC_BILLING = asyncActionCreator(
  'ACTIVATE_AUTOMATIC_BILLING'
);
export const DEACTIVATE_AUTOMATIC_BILLING = asyncActionCreator(
  'DEACTIVATE_AUTOMATIC_BILLING'
);

export const GENERATE_AUTOMATIC_REQUEST_DOCUMENT = asyncActionCreator(
  'GENERATE_AUTOMATIC_REQUEST_DOCUMENT'
);

export const SEND_TO_SIGN_AUTOMATIC_REQUEST_DOCUMENT = asyncActionCreator(
  'SEND_TO_SIGN_AUTOMATIC_REQUEST_DOCUMENT'
);

export function getOptions(getState, sendLimit = true) {
  const queryOpts = selectContractsQueryOptions(getState());

  const reqOptions = {};
  if (queryOpts.page > 0) {
    reqOptions.offset =
      queryOpts.page * Number(process.env.REACT_APP_TABLE_SIZE);
  }
  if (queryOpts.id) {
    reqOptions.id = queryOpts.id;
  }
  if (queryOpts.client) {
    reqOptions.name = queryOpts.client;
  }
  if (queryOpts.status) {
    reqOptions.status = queryOpts.status;
  }
  if (queryOpts.fullScheduleView) {
    reqOptions.fullScheduleView = queryOpts.fullScheduleView;
  }
  if (queryOpts.folio) {
    reqOptions.folio = queryOpts.folio;
  }
  if (queryOpts.automaticBilling !== undefined) {
    reqOptions.automaticBilling = queryOpts.automaticBilling;
  }

  if (sendLimit) {
    reqOptions.limit = queryOpts.limit;
  }
  return reqOptions;
}

export const clearOptions = () => dispatch => {
  dispatch({ type: CLEAR_CONTRACT_OPTIONS });
};

export const setContractOptions = options => dispatch => {
  dispatch({
    type: SAVE_CONTRACT_OPTIONS,
    options
  });
};

export const loadContracts = options => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_CONTRACTS.start });

    dispatch({
      type: SAVE_CONTRACT_OPTIONS,
      options
    });

    const reqOptions = getOptions(getState);
    const data = await getAllContracts(reqOptions);

    return dispatch({
      type: LOAD_CONTRACTS.success,
      ...data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CONTRACTS.failure });
  }
};

export const exportContractListInExcel = options => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: DOWNLOAD_CONTRACT_LIST.start });

    dispatch({
      type: SAVE_CONTRACT_OPTIONS,
      options
    });

    const reqOptions = getOptions(getState);

    await downloadContractList(reqOptions);

    return dispatch({ type: DOWNLOAD_CONTRACT_LIST.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: DOWNLOAD_CONTRACT_LIST.failure });
  }
};

export const createContract = (values, closeCallback) => async dispatch => {
  try {
    dispatch({ type: CREATE_CONTRACT.start });
    let avals = [];

    if (values.avals) {
      if (values.clientPersonType === PersonType.LEGAL) {
        avals = values.avals.map((aval, index) => {
          if (index === 0) {
            return {
              id: aval.id,
              isLegalRepresentative: true
            };
          }
          return {
            id: aval.id,
            isLegalRepresentative: false
          };
        });
      }

      if (values.clientPersonType === PersonType.PHYSICAL) {
        avals = values.avals.map(aval => ({
          id: aval.id,
          isLegalRepresentative: false
        }));
      }
    }

    const { id } = await createNewContract({
      ...values,
      initialPayment:
        values.paymentInputOption === PaymentInputOption.PERCENTAGE
          ? (values.initialPayment / 100) * values.equipmentCost
          : values.initialPayment,
      rate: Number(values.rate),
      avals,
      isMsiModality: values.enableMsi,
      isFirstUpfrontRentModality: values.isFirstUpfrontRentModality
    });

    closeCallback();
    dispatch(
      showMessage({
        message: 'CREATE_CONTRACT',
        variant: 'success'
      })
    );
    dispatch(push(`/contracts/${id}`));
    return dispatch({ type: CREATE_CONTRACT.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    closeCallback();
    return dispatch({ type: CREATE_CONTRACT.failure });
  }
};

export const loadContractDetail = options => async dispatch => {
  try {
    dispatch({
      type: LOAD_CONTRACT_DETAIL.start
    });

    const data = await getContractById({
      id: options.id,
      fullScheduleView: options.fullScheduleView
    });

    return dispatch({
      type: LOAD_CONTRACT_DETAIL.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CONTRACT_DETAIL.failure });
  }
};

export const loadUpfrontRentNextPeriod = options => async dispatch => {
  try {
    dispatch({
      type: LOAD_UPFRONT_RENT_NEXT_PERIOD.start
    });

    const data = await getUpfrontRentNextPeriod({
      id: options.id,
      fullScheduleView: options.fullScheduleView
    });

    return dispatch({
      type: LOAD_UPFRONT_RENT_NEXT_PERIOD.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_UPFRONT_RENT_NEXT_PERIOD.failure });
  }
};

export const updateContractDates = (
  id,
  values,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: UPDATE_CONTRACT_DATES.start });
    await createNewContractDates({ id, values });
    dispatch({ type: UPDATE_CONTRACT_DATES.success });
    dispatch(
      showMessage({
        message: 'UPDATE_CONTRACT_DATES',
        variant: SnackBarVariants.SUCCESS
      })
    );
    dispatch(loadContractDetail({ id }));
    return closeCallback();
  } catch (error) {
    dispatch({ type: UPDATE_CONTRACT_DATES.failure });
    return dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
  }
};

export const loadContractPaymentSchedule = options => async dispatch => {
  try {
    dispatch({
      type: LOAD_CONTRACT_PAYMENT_SCHEDULE.start
    });

    const data = await getContractPaymentSchedule({
      id: options.id,
      fullScheduleView: options.fullScheduleView
    });

    return dispatch({
      type: LOAD_CONTRACT_PAYMENT_SCHEDULE.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CONTRACT_PAYMENT_SCHEDULE.failure });
  }
};

export const loadRentBalanceSchedule = options => async dispatch => {
  try {
    dispatch({
      type: LOAD_RENT_BALANCE_SCHEDULE.start
    });

    const data = await getRentBalanceSchedule({
      id: options.id,
      fullScheduleView: true
    });

    return dispatch({
      type: LOAD_RENT_BALANCE_SCHEDULE.success,
      payload: data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_RENT_BALANCE_SCHEDULE.failure });
  }
};

export const downloadExcelPaymentSchedule = id => async dispatch => {
  try {
    dispatch({
      type: DOWNLOAD_CONTRACT_SCHEDULE.start
    });
    await downloadContractPaymentSchedule(id);

    return dispatch({
      type: DOWNLOAD_CONTRACT_SCHEDULE.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DOWNLOAD_CONTRACT_SCHEDULE.failure });
  }
};

export const downloadRentBalanceScheduleAction = id => async dispatch => {
  try {
    dispatch({
      type: DOWNLOAD_RENT_BALANCE_SCHEDULE.start
    });
    await downloadRentBalanceSchedule(id);

    return dispatch({
      type: DOWNLOAD_RENT_BALANCE_SCHEDULE.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: DOWNLOAD_RENT_BALANCE_SCHEDULE.failure });
  }
};

export const sendToSingDocuments = id => async dispatch => {
  try {
    dispatch({ type: SEND_TO_DIGITAL_SIGN_CONTRACT.start });

    await sendToSingContract({ id });

    dispatch(
      showMessage({
        message: 'SEND_DOCUMENT_TO_SIGN',
        variant: SnackBarVariants.SUCCESS
      })
    );
    dispatch({ type: SEND_TO_DIGITAL_SIGN_CONTRACT.success });

    return dispatch(loadContractDetail({ id }));
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: SEND_TO_DIGITAL_SIGN_CONTRACT.failure });
  }
};

export const signContractManuallyAction = (id, values) => async dispatch => {
  try {
    dispatch({ type: SIGN_CONTRACT_MANUALLY.start });

    const documentUrl = await uploadSingleFile(values.file);

    await singContractManually({ id, documentUrl });

    dispatch(
      showMessage({
        message: 'SIGN_CONTRACT_MANUALLY',
        variant: SnackBarVariants.SUCCESS
      })
    );
    dispatch({ type: SIGN_CONTRACT_MANUALLY.success });

    return dispatch(loadContractDetail({ id }));
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: SIGN_CONTRACT_MANUALLY.failure });
  }
};

export const sendToCancelContract = id => async dispatch => {
  try {
    dispatch({ type: CANCEL_CONTRACT.start });

    await cancelContract({ id });

    dispatch(
      showMessage({
        message: 'CANCEL_CONTRACT',
        variant: 'success'
      })
    );
    dispatch(push('/contracts'));
    return dispatch({ type: CANCEL_CONTRACT.success });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CANCEL_CONTRACT.failure });
  }
};

export const updatePromissoryNote = ({ contractId }) => async dispatch => {
  try {
    dispatch({ type: UPDATE_PROMISSORY_DOCUMENT.start });
    // Upload all assets.

    dispatch(
      showMessage({
        message: 'UPDATE_PROMISSORY_DOCUMENT',
        variant: 'success'
      })
    );

    return dispatch(loadContractDetail({ id: contractId }));
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    dispatch({ type: UPDATE_PROMISSORY_DOCUMENT.failure });
    return dispatch({ type: LOAD_CONTRACT_DETAIL.failure });
  }
};

export const loadContributions = id => async dispatch => {
  try {
    dispatch({
      type: LOAD_CONTRIBUTIONS.start
    });

    const data = await getContributionsByContract(id);

    return dispatch({
      type: LOAD_CONTRIBUTIONS.success,
      data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CONTRIBUTIONS.failure });
  }
};

export const newCapitalContribution = (
  id,
  values,
  handleClose
) => async dispatch => {
  try {
    dispatch({ type: CREATE_CONTRIBUTION.start });

    await createCapitalContribution({ contractId: id, ...values });

    dispatch(
      showMessage({
        message: 'CREATE_CONTRIBUTION',
        variant: 'success'
      })
    );

    handleClose();
    dispatch({ type: CREATE_CONTRIBUTION.success });
    dispatch({ type: CLEAR_RENT_AFTER_CONTRIBUTION });
    return dispatch(loadContributions(id));
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CREATE_CONTRIBUTION.failure });
  }
};

export const loadRentAfterContribution = (id, amount) => async dispatch => {
  try {
    dispatch({
      type: LOAD_RENT_AFTER_CONTRIBUTION.start
    });

    const data = await getRentAfterContribution({ id, amount });

    return dispatch({
      type: LOAD_RENT_AFTER_CONTRIBUTION.success,
      payload: data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_RENT_AFTER_CONTRIBUTION.failure });
  }
};

export const createContractFromQuote = (
  values,
  closeCallback
) => async dispatch => {
  try {
    dispatch({ type: CREATE_CONTRACT_FROM_QUOTE.start });
    let avals = [];

    if (values.avals) {
      if (values.clientPersonType === PersonType.LEGAL) {
        avals = values.avals.map((aval, index) => {
          if (index === 0) {
            return {
              id: aval.id,
              isLegalRepresentative: true
            };
          }
          return {
            id: aval.id,
            isLegalRepresentative: false
          };
        });
      }

      if (values.clientPersonType === PersonType.PHYSICAL) {
        avals = values.avals.map(aval => ({
          id: aval.id,
          isLegalRepresentative: false
        }));
      }
    }

    await createContractFromQuoteRequest({ ...values, avals });

    closeCallback();
    dispatch(
      showMessage({
        message: CREATE_CONTRACT_FROM_QUOTE.baseName,
        variant: SnackBarVariants.SUCCESS
      })
    );
    dispatch(push(`/quotes`));
    return dispatch({ type: CREATE_CONTRACT_FROM_QUOTE.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    closeCallback();
    return dispatch({ type: CREATE_CONTRACT_FROM_QUOTE.failure });
  }
};

export const updateContract = (id, values, closeCallback) => async dispatch => {
  try {
    dispatch({ type: UPDATE_CONTRACT.start });

    await editContractById({ id, values });

    dispatch(
      showMessage({
        message: UPDATE_CONTRACT.baseName,
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch(loadContractDetail({ id }));

    dispatch({ type: UPDATE_CONTRACT.success });
    return closeCallback();
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    closeCallback();
    return dispatch({ type: UPDATE_CONTRACT.failure });
  }
};

export const loadContractAvalList = id => async dispatch => {
  try {
    dispatch({
      type: LOAD_CONTRACT_AVAL_LIST.start
    });

    const data = await getContractAvalList(id);

    return dispatch({
      type: LOAD_CONTRACT_AVAL_LIST.success,
      payload: data
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: LOAD_CONTRACT_AVAL_LIST.failure });
  }
};

export const calculateSingleMsiDiscount = (
  values,
  setFieldValue
) => async dispatch => {
  try {
    dispatch({
      type: CALCULATE_SINGLE_MSI_DISCOUNT.start
    });

    const data = await getSingleMsiDiscount(values);

    setFieldValue('msiDiscountPercentage', data.discountPercentage);
    setFieldValue('msiDiscountAmount', data.discountAmount);

    return dispatch({
      type: CALCULATE_SINGLE_MSI_DISCOUNT.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CALCULATE_SINGLE_MSI_DISCOUNT.failure });
  }
};

export const calculateMonthlyRent = (
  values,
  setFieldValue
) => async dispatch => {
  try {
    dispatch({
      type: CALCULATE_MONTHLY_RENT.start
    });

    const data = await getMonthlyRent(values);

    setFieldValue('rent', data);

    return dispatch({
      type: CALCULATE_MONTHLY_RENT.success
    });
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: 'error' }));
    return dispatch({ type: CALCULATE_MONTHLY_RENT.failure });
  }
};

export const activateAutomaticBilling = id => async dispatch => {
  try {
    dispatch({ type: ACTIVATE_AUTOMATIC_BILLING.start });

    await activateContractAutomaticBilling(id);

    dispatch(
      showMessage({
        message: 'ACTIVATE_AUTOMATIC_BILLING',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch(loadContractDetail({ id }));

    return dispatch({ type: ACTIVATE_AUTOMATIC_BILLING.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: ACTIVATE_AUTOMATIC_BILLING.failure });
  }
};

export const desactivateAutomaticBilling = id => async dispatch => {
  try {
    dispatch({ type: DEACTIVATE_AUTOMATIC_BILLING.start });

    await deactivateContractAutomaticBilling(id);

    dispatch(
      showMessage({
        message: 'DEACTIVATE_AUTOMATIC_BILLING',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch(loadContractDetail({ id }));

    return dispatch({ type: DEACTIVATE_AUTOMATIC_BILLING.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: DEACTIVATE_AUTOMATIC_BILLING.failure });
  }
};

export const generateAutomaticDebitRequestDocumentAction = id => async dispatch => {
  try {
    dispatch({ type: GENERATE_AUTOMATIC_REQUEST_DOCUMENT.start });

    await generateAutomaticDebitRequestDocument(id);

    dispatch(
      showMessage({
        message: 'AUTOMATIC_DEBIT_REQUEST_DOCUMENT_GENERATED',
        variant: SnackBarVariants.SUCCESS
      })
    );

    dispatch(loadContractDetail({ id }));

    return dispatch({ type: GENERATE_AUTOMATIC_REQUEST_DOCUMENT.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: GENERATE_AUTOMATIC_REQUEST_DOCUMENT.failure });
  }
};

export const sendToSignAutomaticRequestDocumentAction = (
  id,
  handleClose
) => async dispatch => {
  try {
    dispatch({ type: SEND_TO_SIGN_AUTOMATIC_REQUEST_DOCUMENT.start });

    await sendToSignAutomaticDebitRequestDocument(id);

    dispatch(
      showMessage({
        message: 'AUTOMATIC_DEBIT_REQUEST_DOCUMENT_SENT_TO_SIGN',
        variant: SnackBarVariants.SUCCESS
      })
    );

    handleClose();
    dispatch(loadContractDetail({ id }));

    return dispatch({ type: SEND_TO_SIGN_AUTOMATIC_REQUEST_DOCUMENT.success });
  } catch (error) {
    dispatch(
      showMessage({ message: error.message, variant: SnackBarVariants.ERROR })
    );
    return dispatch({ type: SEND_TO_SIGN_AUTOMATIC_REQUEST_DOCUMENT.failure });
  }
};
