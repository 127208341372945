import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Box, Button } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { FilterList } from '@mui/icons-material';
import Filter from 'src/components/Filter/Filter';
import FILTER_TYPES from 'src/utils/constants/filter';
import loadPayments, {
  clearPaymentQueryOptions
} from 'src/store/actions/paymentsActions';
import moment from 'moment-timezone';
import { selectPaymentQueryOptions } from 'src/store/selectors/paymentSelectors';
import useStyles from '../styles';

const filters = [
  {
    title: 'COMMON.DATE',
    controlGroup: FILTER_TYPES.DATE,
    items: [
      {
        label: 'COMMON.DATE',
        value: 'date',
        view: ['year', 'month']
      }
    ]
  }
];

function getFilterOptions(options) {
  let date = null;

  if (options.date) {
    date = moment(options.date).toDate();
  }

  return { date };
}

const Toolbar = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filterValues, setFilterValues] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const queryOptions = useSelector(selectPaymentQueryOptions);

  const handleClickFilter = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleCloseFilter = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleFilterChange = useCallback(
    event => {
      if (event.date && moment(event.date).isValid()) {
        setFilterValues(event);
        dispatch(
          loadPayments({
            ...getFilterOptions(event),
            page: 0
          })
        );
      }
    },
    [setFilterValues, queryOptions, getFilterOptions]
  );

  useEffect(() => {
    dispatch(
      loadPayments({
        ...queryOptions,
        date: moment().toDate()
      })
    );
    return () => dispatch(clearPaymentQueryOptions());
  }, []);

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb doNotRedirectIndexList={[0]} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography
              sx={{ fontWeight: '400', marginRight: 1 }}
              color="primary"
              variant="h1"
            >
              {t('NAVIGATION.AUTOMATIC_DEBIT_COLLECTIONS')}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '30%'
              }}
            >
              <Filter
                anchorEl={anchorEl}
                filterValues={filterValues}
                handleClose={handleCloseFilter}
                handleChange={handleFilterChange}
                filters={filters}
              />
              <Button
                className={clsx(
                  classes.buttons,
                  classes.outlined,
                  classes.horizontalPadding
                )}
                color="primary"
                onClick={handleClickFilter}
                sx={{ marginRight: 2 }}
              >
                <FilterList className={classes.icon} />
                {t('COMMON.FILTER')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
