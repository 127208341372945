import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TablePaginationCustom from 'src/components/Table/TablePaginationCustom';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import useStyles from '../styles';
import AutomaticDebitCollectionRow from './AutomaticDebitCollectionRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ payments, t }) =>
  payments.map(payment => (
    <AutomaticDebitCollectionRow key={payment.id} t={t} {...payment} />
  ));

const AutomaticDebitCollectionTable = ({
  className,
  payments,
  count,
  page,
  handleChangePage,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell
          variant="head"
          key="contractFolio"
          sx={{ width: '10%' }}
          align="center"
        >
          {t('ENUMS.AUTOMATIC_DEBIT.CONTRACT_FOLIO')}
        </TableCell>
        <TableCell
          variant="head"
          key="client"
          sx={{ width: '10%' }}
          align="center"
        >
          {t('ENUMS.AUTOMATIC_DEBIT.CLIENT_NAME')}
        </TableCell>
        <TableCell
          variant="head"
          key="amount"
          sx={{ width: '10%' }}
          align="center"
        >
          {t('ENUMS.AUTOMATIC_DEBIT.AMOUNT')}
        </TableCell>
        <TableCell
          variant="head"
          key="debitedAmount"
          sx={{ width: '10%' }}
          align="center"
        >
          {t('ENUMS.AUTOMATIC_DEBIT.DEBITED_AMOUNT')}
        </TableCell>
        <TableCell
          variant="head"
          key="log"
          sx={{ width: '10%' }}
          align="center"
        >
          {t('ENUMS.AUTOMATIC_DEBIT.STATUS')}
        </TableCell>
        <TableCell
          variant="head"
          key="dueDate"
          sx={{ width: '10%' }}
          align="center"
        >
          {t('ENUMS.AUTOMATIC_DEBIT.DUE_DATE')}
        </TableCell>
        <TableCell style={{ width: '2%' }} key="options">
          {' '}
        </TableCell>
      </TableRow>
    ),
    [classes]
  );

  const tbody = useMemo(() => () => <Tbody t={t} payments={payments} />, [
    payments,
    t
  ]);

  const pagination = useMemo(
    () => () => (
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={+process.env.REACT_APP_TABLE_SIZE}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationCustom}
        labelDisplayedRows={({ from, to, count: c }) => {
          return `${from}-${to} ${t('COMMON.OF')} ${c}`;
        }}
      />
    ),
    [page, handleChangePage, count]
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      pagination={pagination}
      loading={loading}
    />
  );
};

AutomaticDebitCollectionTable.propTypes = {
  className: PropTypes.string,
  payments: PropTypes.array.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  loading: PropTypes.bool
};

AutomaticDebitCollectionTable.defaultProps = {
  count: 0
};

export default AutomaticDebitCollectionTable;
