import React from 'react';
import { Button, TextField, Box } from '@mui/material';
import PropTypes from 'prop-types';

const NumericStepper = ({
  value,
  onIncrease,
  onDecrease,
  minValue = 1,
  maxValue = Infinity,
  disabled = false
}) => {
  const textFieldHeight = '57px';

  const handleIncrease = () => {
    if (value < maxValue) onIncrease();
  };

  const handleDecrease = () => {
    if (value > minValue) onDecrease();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px'
      }}
    >
      <TextField
        value={value}
        size="small"
        variant="outlined"
        inputProps={{
          sx: {
            textAlign: 'center',
            width: '50px',
            height: textFieldHeight,
            padding: 0
          },
          readOnly: true
        }}
        sx={{
          height: textFieldHeight
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px'
        }}
      >
        <Button
          onClick={handleIncrease}
          disabled={disabled || value >= maxValue}
          variant="outlined"
          size="small"
          sx={{
            minWidth: '32px',
            padding: 0,
            height: '27px'
          }}
        >
          +
        </Button>
        <Button
          onClick={handleDecrease}
          disabled={disabled || value <= minValue}
          variant="outlined"
          size="small"
          sx={{
            minWidth: '32px',
            padding: 0,
            height: '27px'
          }}
        >
          -
        </Button>
      </Box>
    </Box>
  );
};

export default NumericStepper;

NumericStepper.propTypes = {
  value: PropTypes.number.isRequired,
  onIncrease: PropTypes.func.isRequired,
  onDecrease: PropTypes.func.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  disabled: PropTypes.bool
};
