import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import TableCell from 'src/components/Table/TableCell';
import { useTranslation } from 'react-i18next';
import Table from 'src/components/Table';
import useStyles from './styles';
import RateAdjustementRow from './RateAdjustementRow';

if (!process.env.REACT_APP_TABLE_SIZE) {
  console.error(
    'REACT_APP_TABLE_SIZE variable not loaded properly. Check your .env file please!'
  );
}

const Tbody = ({ rateAdjustmentData }) =>
  rateAdjustmentData.map(item => (
    <RateAdjustementRow key={item.id} {...item} />
  ));

const RateAdjustmentsTable = ({ rateAdjustmentData, loading, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theads = useMemo(
    () => () => (
      <TableRow>
        <TableCell variant="head" key="name" sx={{ width: '45%' }}>
          {`${t('COMMON.GRACE_PERIOD')}`}
        </TableCell>
        <TableCell
          variant="head"
          key="twelveMonthsRate"
          sx={{ width: '45%' }}
          align="center"
        >
          %
        </TableCell>
        <TableCell sx={{ width: '10%' }} variant="head" key="options">
          {' '}
        </TableCell>
      </TableRow>
    ),
    [classes]
  );

  const tbody = useMemo(
    () => () => <Tbody t={t} rateAdjustmentData={rateAdjustmentData} />,
    [rateAdjustmentData, t]
  );

  return (
    <Table
      {...rest}
      variant="outlined"
      tbody={tbody}
      theads={theads}
      loading={loading}
    />
  );
};

RateAdjustmentsTable.propTypes = {
  rateAdjustmentData: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};

export default RateAdjustmentsTable;
