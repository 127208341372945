import { PaymentInputOption, QuoteModality } from 'src/utils/constants/quotes';
import { GracePeriodValues } from 'src/utils/constants/distributors';

const setGracePeriod = (values, parameter) => {
  if (values.modality === QuoteModality.FIRST_UPFRONT_RENT) {
    return GracePeriodValues.THIRTY;
  }
  if (values.modality === QuoteModality.MSI && values.initialPayment > 0) {
    return GracePeriodValues.ZERO;
  }

  if (values && values.initialPayment === 0) {
    return GracePeriodValues.SAME_DAY_AS_CONTRACT_STARTS;
  }

  return values.gracePeriod ?? parameter.defaultGracePeriod;
};

const makeInitialState = (values, parameter) => {
  return {
    ...values,
    distributorEquipments: values.distributorEquipments,
    totalPriceWithIva: values ? values.totalPriceWithIva : '',
    residualPayment:
      Number(parameter.maxResidualValue) === 0 ? 1 : values.residualPayment,
    equipmentInsuranceIncluded: values
      ? values.equipmentInsuranceIncluded
      : false,

    initialPayment: values ? values.initialPayment : '',
    gracePeriod: setGracePeriod(values, parameter),
    paymentInputOption: values
      ? values.paymentInputOption
      : PaymentInputOption.PERCENTAGE
  };
};

export default makeInitialState;
