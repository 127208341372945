import qs from 'qs';
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

// eslint-disable-next-line import/prefer-default-export
export const getQuoteList = serviceBase({
  request: options =>
    axiosClient.get('/quote', {
      params: options,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    }),
  retry: 0
});

export const rejectQuote = serviceBase({
  request: ({ id, values }) => axiosClient.post(`/quote/${id}`, values)
});

export const archiveQuoteById = serviceBase({
  request: ({ id }) => axiosClient.post(`/quote/${id}/archive`)
});

export const getQuoteDetail = serviceBase({
  request: id => axiosClient.get(`/quote/${id}`)
});

export const getQuoteRentsDetail = serviceBase({
  request: values => axiosClient.post(`/quote/rents-values`, values)
});

export const assignKam = serviceBase({
  request: ({ id, values }) =>
    axiosClient.post(`/quote/assign-kam`, { id, ...values })
});

export const adminNewQuoteCreation = serviceBase({
  request: values => axiosClient.post('/quote', values)
});

export const createQuickQuote = serviceBase({
  request: values => axiosClient.post('/quote/quick-quote', values)
});

export const assingClientToQuote = serviceBase({
  request: ({ quoteId, clientId }) =>
    axiosClient.post(`/quote/${quoteId}/assign`, { clientId })
});

export const calculateRent = serviceBase({
  request: values =>
    axiosClient.post('/distributor-profile/calculate-rent', values)
});

export const calculateMsiDiscounts = serviceBase({
  request: values => axiosClient.post('/quote/msi-discount', values)
});

export const createAdminContractRequest = serviceBase({
  request: values =>
    axiosClient.post(`/quote/send-to-contract/${values.id}`, values)
});

export const downloadExportedQuotes = serviceBase({
  request: options =>
    axiosClient.get('/quote/export', {
      responseType: 'blob',
      params: options
    }),
  transformResponse: res => {
    try {
      const filename = 'Cotizaciones.xlsx';
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.data);
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log(`Opps, un error: ${error}`);
      console.error(error);
    }
  },
  retry: 0
});
