import React, { useEffect, useMemo, useRef } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DialogTransition from 'src/components/DialogTransition';
import theme from 'src/theme';
import PercentageInputMask from 'src/components/inputMask/PercentageInputMask';

import clsx from 'clsx';
import { GracePeriodValues } from 'src/utils/constants/distributors';
import validationSchema from './schema';
import makeInitialState from './initialState';
import useStyles from '../styles';

const RateAdjustmentForm = ({ open, data, onClose, onEdit }) => {
  const classes = useStyles();
  const resetRef = useRef();
  const { t } = useTranslation();
  const initialState = useMemo(() => makeInitialState(data), [data]);

  const handleOuterSubmit = useMemo(() => {
    return onEdit;
  }, [data, onEdit, open]);

  useEffect(() => {
    if (resetRef.current) {
      resetRef.current();
    }
  }, [open, resetRef]);

  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '50%'
      }}
    >
      <Dialog
        sx={{ display: 'flex', flexDirection: 'column' }}
        open={open}
        scroll="body"
        TransitionComponent={DialogTransition}
        keepMounted
        disableEscapeKeyDown
      >
        <DialogTitle
          sx={{ display: 'flex', flexDirection: 'column' }}
          key="dialog-title"
        >
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
            sx={{ alignSelf: 'flex-end' }}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} color="primary" variant="h2">
            {t('CATALOGS_VIEW.RATE_ADJUSTMENT.EDIT_RATE_ADJUSTMENT')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialState}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleOuterSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              resetForm
            }) => {
              resetRef.current = () => resetForm(initialState);
              return (
                <form onSubmit={handleSubmit}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel
                      htmlFor="gracePeriod-label"
                      error={Boolean(touched.gracePeriod && errors.gracePeriod)}
                    >
                      {t('CATALOGS_VIEW.GRACE_PERIOD_NAME')}
                    </InputLabel>
                    <OutlinedInput
                      id="gracePeriod-label"
                      name="gracePeriod"
                      placeholder={t('CATALOGS_VIEW.GRACE_PERIOD_NAME')}
                      label={t('CATALOGS_VIEW.GRACE_PERIOD_NAME')}
                      value={values.gracePeriod}
                      disabled
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.gracePeriod && errors.gracePeriod)}
                    />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                    <InputLabel
                      htmlFor="adjustment-label"
                      error={Boolean(touched.adjustment && errors.adjustment)}
                    >
                      {t('CATALOGS_VIEW.RATE_ADJUSTMENT.ADJUSTMENT')}
                    </InputLabel>
                    <OutlinedInput
                      id="adjustment-label"
                      name="adjustment"
                      placeholder="0%"
                      label={t('CATALOGS_VIEW.RATE_ADJUSTMENT.ADJUSTMENT')}
                      value={values.adjustment}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputComponent={PercentageInputMask}
                      error={Boolean(touched.adjustment && errors.adjustment)}
                      disabled={
                        Number(data.gracePeriod) === GracePeriodValues.THIRTY
                      }
                    />
                    {Boolean(touched.adjustment && errors.adjustment) && (
                      <FormHelperText sx={{ color: theme.palette.error.main }}>
                        {t(errors.adjustment)}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Grid
                    container
                    direction="column"
                    alignItems="stretch"
                    gap={2}
                    sx={{ marginTop: '1rem' }}
                  >
                    <Grid item>
                      <Button
                        sx={{
                          width: '100%'
                        }}
                        className={clsx(classes.buttons)}
                        type="submit"
                        color="primary"
                        variant="contained"
                      >
                        {t('COMMON.UPDATE')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        sx={{
                          width: '100%',
                          backgroundColor: theme.palette.common.white,
                          color: theme.palette.primary.main
                        }}
                        onClick={onClose}
                        color="primary"
                        variant="outlined"
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

RateAdjustmentForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
};

export default RateAdjustmentForm;
