import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, FormControl, TextField, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { selectDistributorDetail } from 'src/store/selectors/distributorSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  LOAD_DISTRIBUTOR_PARAMETER,
  getDistributorParameters
} from 'src/store/actions/distributorActions';
import DropdownMenu from 'src/components/DropdownMenu';
import MoneyMask from 'src/components/MoneyMask/MoneyMask';
import { selectRentCost } from 'src/store/selectors/quoteSelectors';
import { CLEAR_RENT_CALCULATOR } from 'src/store/constants/quotes';
import { validateTermAndInitialPayment } from 'src/utils/calculator';
import { formatAmount } from 'src/utils/formatAmout';
import { LoadingButton } from '@mui/lab';
import { selectIndividualParameter } from 'src/store/selectors/parameterSelector';
import {
  getDefaultDaysForFirstRentList,
  msiTermList
} from 'src/utils/constants/distributors';
import { loadParameter } from 'src/store/actions/parameterActions';
import { Parameter } from 'src/utils/constants/parameters';
import PaymentMultipleInput from 'src/components/PaymentMultipleInput';
import { handleInitialPaymentChange } from 'src/utils/quotes';
import { QuoteModality } from 'src/utils/constants/quotes';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const StepThree = ({ data, handleNext, handleBack, resetRef }) => {
  const classes = useStyles();
  const parameter = useSelector(selectDistributorDetail);
  const initialState = useMemo(
    () =>
      makeInitialState(
        {
          ...data,
          equipmentInsuranceIncluded: data.distributor.isInsuranceIncluded
            ? 'true'
            : 'false',
          gracePeriod: Number(data.gracePeriod),
          residualPayment: 1
        },
        parameter
      ),
    [data, parameter]
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const rentCost = useSelector(selectRentCost);
  const maxInitialPayment = useSelector(selectIndividualParameter);

  const isLoadingDistributorParameter = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_PARAMETER.action])
  );

  useEffect(() => {
    dispatch({ type: CLEAR_RENT_CALCULATOR });
  }, [dispatch]);

  const onNext = useCallback(
    async values => {
      handleNext({
        ...values
      });
    },
    [handleNext]
  );

  const onBack = useCallback(
    values => {
      handleBack({
        ...data,
        ...values
      });
    },
    [handleBack, data]
  );

  const handleSubmitForm = useCallback(
    (values, setFieldError) => {
      const MultiEquipmentValues = {
        ...values,
        price: values.totalPriceWithIva
      };
      const isValidated = validateTermAndInitialPayment(
        MultiEquipmentValues,
        setFieldError,
        {
          minInitialPaymentPercentage: parameter.minInitialPayment,
          maxInitialPaymentPercentage: Number(maxInitialPayment)
        },
        t,
        false
      );
      if (!isValidated) return;
      if (data.modality === QuoteModality.MSI) {
        const allowedMsiTermValues = msiTermList
          .filter(
            el =>
              Number(el.key) >= Number(parameter.minMsiTerm) &&
              Number(el.key) <= Number(parameter.maxMsiTerm)
          )
          .map(el => Number(el.key));
        onNext({ ...values, allowedMsiTerms: allowedMsiTermValues });
      } else {
        onNext({ ...values });
      }
    },
    [parameter, rentCost, maxInitialPayment]
  );
  useEffect(() => {
    dispatch(getDistributorParameters(data.distributor.id));
    dispatch(loadParameter(Parameter.MAXIMUM_INITIAL_PAYMENT_PERCENTAGE));
  }, [dispatch]);

  return (
    <>
      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema(parameter)}
        onSubmit={(values, { setFieldError }) => {
          handleSubmitForm(values, setFieldError);
        }}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          handleBlur,
          handleChange,
          resetForm
        }) => {
          resetRef.current = () => resetForm();
          return (
            <form onSubmit={handleSubmit}>
              <Box className={classes.container}>
                <TextField
                  id="total-price-input"
                  InputProps={{
                    autoComplete: 'off',
                    inputComponent: MoneyMask
                  }}
                  error={Boolean(
                    touched.totalPriceWithIva && errors.totalPriceWithIva
                  )}
                  fullWidth
                  helperText={
                    touched.totalPriceWithIva && t(errors.totalPriceWithIva)
                  }
                  label={t('QUOTES_VIEW.MULTI_QUOTE.TOTAL_AMOUNT_WITH_IVA')}
                  margin="normal"
                  name="totalPriceWithIva"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled
                  value={formatAmount(values.totalPriceWithIva, 2)}
                  variant="outlined"
                />

                <PaymentMultipleInput
                  id="initial-payment-input"
                  firstInputName="paymentInputOption"
                  secondInputName="initialPayment"
                  disabled={!values.distributorEquipments}
                  label={t('COMMON.INITIAL_PAYMENT')}
                  onChange={e => {
                    handleInitialPaymentChange(
                      e,
                      setFieldValue,
                      data.modality === QuoteModality.MSI
                    );
                  }}
                />

                <FormControl fullWidth>
                  <DropdownMenu
                    id="grace-period-select"
                    onBlur={handleBlur}
                    label={t('COMMON.DAYS_FOR_FIRST_RENT')}
                    value={values.gracePeriod}
                    name="gracePeriod"
                    setValue={value => setFieldValue('gracePeriod', value)}
                    error={Boolean(touched.gracePeriod && errors.gracePeriod)}
                    errorText={touched.gracePeriod && t(errors.gracePeriod)}
                    list={getDefaultDaysForFirstRentList(
                      parameter.maxGracePeriod,
                      data.modality === QuoteModality.FIRST_UPFRONT_RENT
                    )}
                    disabled={
                      isLoadingDistributorParameter ||
                      values.initialPayment === 0 ||
                      data.modality === QuoteModality.MSI
                    }
                  />
                </FormControl>

                <Tooltip
                  disableFocusListener
                  title={t('COMMON.OBTAINED_FROM_DISTRIBUTOR_PARAMS')}
                >
                  <span>
                    <DropdownMenu
                      id="insurance-included-select"
                      disabled
                      list={[
                        { key: 'true', name: t('COMMON.YES') },
                        { key: 'false', name: t('COMMON.NO') }
                      ]}
                      value={values.equipmentInsuranceIncluded}
                      setValue={value =>
                        setFieldValue(
                          'equipmentInsuranceIncluded',
                          value === 'true'
                        )
                      }
                      label={t(
                        'DISTRIBUTORS_VIEW.PARAMETERS.IS_INSURANCE_INCLUDED'
                      )}
                      error={Boolean(
                        touched.equipmentInsuranceIncluded &&
                          errors.equipmentInsuranceIncluded
                      )}
                      errorText={
                        touched.equipmentInsuranceIncluded &&
                        t(errors.equipmentInsuranceIncluded)
                      }
                    />
                  </span>
                </Tooltip>

                <br />
              </Box>

              <Box
                className={classes.tableBox}
                display="flex"
                justifyContent="space-between"
              >
                <Button
                  id="back-button"
                  className={classes.buttons}
                  onClick={() => onBack(values)}
                  color="primary"
                  variant="outlined"
                >
                  {t('COMMON.BACK')}
                </Button>
                <LoadingButton
                  id="next-button"
                  className={clsx(classes.buttons, classes.buttonMain)}
                  type="submit"
                  color="primary"
                  variant="outlined"
                  loadingPosition="start"
                >
                  {t('COMMON.NEXT')}
                </LoadingButton>
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
StepThree.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  resetRef: PropTypes.object
};

export default StepThree;
