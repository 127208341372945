import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import DialogTransition from 'src/components/DialogTransition';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment-timezone';
import useStyles from './styles';
import validationSchema from './schema';

const EditAutomaticDebitCollectionDueDateForm = ({
  open,
  onClose,
  automaticDebitCollectionId,
  onSubmit,
  currentDueDate
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const resetRef = useRef();

  const { canEditDueDate, maxDate, minDate, initialDate } = useMemo(() => {
    const today = moment().startOf('day');
    const dueDateMoment = currentDueDate ? moment(currentDueDate) : today;

    // Get the 25th of the due date's month
    const monthLimit = moment(dueDateMoment)
      .date(25)
      .startOf('day');

    // Get the first day of the due date's month
    const firstDayOfMonth = moment(dueDateMoment).startOf('month');

    // If due date's month is current month, use today as min date
    // Otherwise use first day of the due date's month
    const minDateValue = dueDateMoment.isSame(today, 'month')
      ? today
      : firstDayOfMonth;

    // Can only edit if today is before or on the 25th of the due date's month
    const canEdit = today.isSameOrBefore(monthLimit);

    return {
      canEditDueDate: canEdit,
      maxDate: monthLimit,
      minDate: minDateValue,
      initialDate: dueDateMoment
    };
  }, [currentDueDate]);

  if (!open) return null;

  // If we can't edit (today is after the 25th), show message
  if (!canEditDueDate) {
    return (
      <Dialog
        disableEscapeKeyDown
        classes={{ paper: classes.dialogRoot }}
        open={open}
        keepMounted
        TransitionComponent={DialogTransition}
      >
        <DialogTitle className={classes.header}>
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ fontSize: '1.25rem' }}>
            {t('ENUMS.AUTOMATIC_DEBIT.SET_NEW_DUE_DATE_TITLE')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 2, textAlign: 'center', fontSize: '1rem' }}>
            {t('ENUMS.AUTOMATIC_DEBIT.DUE_DATE_HAS_PASSED')}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button onClick={onClose} color="primary" variant="contained">
              <Typography sx={{ fontSize: '0.875rem' }}>
                {t('COMMON.CLOSE')}
              </Typography>
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  const handleFormSubmit = values => {
    onSubmit({
      id: automaticDebitCollectionId,
      dueDate: moment(values.dueDate).toISOString()
    });
  };

  return (
    <Dialog
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      keepMounted
      TransitionComponent={DialogTransition}
    >
      <DialogTitle className={classes.header}>
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        {t('ENUMS.AUTOMATIC_DEBIT.SET_NEW_DUE_DATE_TITLE')}
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ dueDate: initialDate }}
          validationSchema={validationSchema(currentDueDate)}
          onSubmit={handleFormSubmit}
        >
          {({
            errors,
            handleSubmit,
            touched,
            values,
            resetForm,
            setFieldValue
          }) => {
            resetRef.current = () => resetForm();
            return (
              <Form onSubmit={handleSubmit}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.dueDate && errors.dueDate)}
                >
                  <DatePicker
                    label={t('ENUMS.AUTOMATIC_DEBIT.DUE_DATE')}
                    inputFormat="DD/MM/YYYY"
                    value={values.dueDate}
                    onChange={newValue => {
                      setFieldValue('dueDate', newValue);
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        error={Boolean(touched.dueDate && errors.dueDate)}
                        helperText={touched.dueDate && t(errors.dueDate)}
                        sx={{ marginTop: 2, marginBottom: 2 }}
                      />
                    )}
                  />
                </FormControl>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    margin: '1rem auto'
                  }}
                >
                  <Button
                    onClick={onClose}
                    color="primary"
                    variant="outlined"
                    sx={{ minWidth: '45%' }}
                  >
                    {t('COMMON.CANCEL')}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ minWidth: '45%' }}
                  >
                    {t('COMMON.ACCEPT')}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

EditAutomaticDebitCollectionDueDateForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  automaticDebitCollectionId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentDueDate: PropTypes.string
};

export default EditAutomaticDebitCollectionDueDateForm;
