import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOAD_QUOTE_CATEGORIES,
  loadQuoteCategoriesList
} from 'src/store/actions/quoteActions';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DialogTransition from 'src/components/DialogTransition';
import Button from 'src/components/Button';

import DropdownMenu from 'src/components/DropdownMenu/DropdownMenu';
import {
  DaysForFirstRentAfterContractStartList,
  MinInitialPaymentList,
  TermList,
  getDefaultDaysForFirstRentList
} from 'src/utils/constants/distributors';
import { getDifferenceBetweenStates } from 'src/utils/common';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import makeInitialState from './initialState';
import validationSchema from './schema';
import useStyles from '../../styles';

const EditParameterDialog = ({ open, parameter, handleClose, onSubmit }) => {
  const classes = useStyles();
  const initialState = useMemo(() => makeInitialState(parameter), [parameter]);
  const minValueOfMaxResidualValue = 0;
  const maxValueOfMaxResidualValue = 30;

  const dispatch = useDispatch();

  const resetRef = useRef();
  const { t } = useTranslation();

  const loadingCategoriesList = useSelector(
    isLoadingSelector([LOAD_QUOTE_CATEGORIES.action])
  );

  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    if (open) {
      dispatch(loadQuoteCategoriesList(setCategoryList));
    }
  }, [open]);

  const handleOuterSubmit = useCallback(
    (values, { resetForm }) => {
      const diference = getDifferenceBetweenStates(initialState, values);
      if (Object.entries(diference).length === 0) {
        handleClose();
        return;
      }
      onSubmit(diference);
      resetForm(initialState);
      handleClose();
    },
    [onSubmit, handleClose, initialState]
  );

  useEffect(() => {
    return () => {
      if (resetRef.current) {
        resetRef.current();
      }
    };
  }, [resetRef, open]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.dialogRoot }}
      open={open}
      TransitionComponent={DialogTransition}
      keepMounted
    >
      <DialogTitle className={classes.header} id="form-quote-dialog-title">
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.header} color="primary" variant="h2">
          {t('DISTRIBUTORS_VIEW.PARAMETERS.EDIT_PARAMETER')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '16px' }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={handleOuterSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            values,
            touched,
            resetForm,
            setFieldValue
          }) => {
            resetRef.current = () => resetForm(initialState);
            return (
              <form onSubmit={handleSubmit}>
                <DropdownMenu
                  onBlur={handleBlur}
                  label={t('DISTRIBUTORS_VIEW.PARAMETERS.MIN_TERM')}
                  value={values.minTerm}
                  setValue={value => setFieldValue('minTerm', value)}
                  name="minTerm"
                  error={Boolean(touched.minTerm && errors.minTerm)}
                  errorText={touched.minTerm && t(errors.minTerm)}
                  list={TermList}
                />
                <DropdownMenu
                  onBlur={handleBlur}
                  label={t('DISTRIBUTORS_VIEW.PARAMETERS.MAX_TERM')}
                  value={values.maxTerm}
                  name="maxTerm"
                  setValue={value => setFieldValue('maxTerm', value)}
                  error={Boolean(touched.maxTerm && errors.maxTerm)}
                  errorText={touched.maxTerm && t(errors.maxTerm)}
                  list={TermList}
                />

                <Autocomplete
                  label={t('CONTRACT_VIEW.INITIAL_PAYMENT_PERCENTAGE')}
                  name="minInitialPayment"
                  options={MinInitialPaymentList}
                  id="minInitialPayment"
                  value={values.minInitialPayment}
                  clearOnBlur={false}
                  fullWidth
                  inputType="number"
                  sx={{ marginBottom: 1, marginTop: 2 }}
                  onChange={(_e, value) => {
                    setFieldValue('minInitialPayment', Number(value));
                  }}
                />

                <DropdownMenu
                  list={[
                    { key: 'true', name: t('COMMON.YES') },
                    { key: 'false', name: t('COMMON.NO') }
                  ]}
                  value={values.isPriceEditable}
                  setValue={value =>
                    setFieldValue('isPriceEditable', value === 'true')
                  }
                  label={t('DISTRIBUTORS_VIEW.PARAMETERS.IS_PRICE_EDITABLE')}
                  error={Boolean(
                    touched.isPriceEditable && errors.isPriceEditable
                  )}
                  errorText={
                    touched.isPriceEditable && t(errors.isPriceEditable)
                  }
                />

                <DropdownMenu
                  list={[
                    { key: 'true', name: t('COMMON.YES') },
                    { key: 'false', name: t('COMMON.NO') }
                  ]}
                  value={values.isInsuranceIncluded}
                  setValue={value =>
                    setFieldValue('isInsuranceIncluded', value === 'true')
                  }
                  label={t(
                    'DISTRIBUTORS_VIEW.PARAMETERS.IS_INSURANCE_INCLUDED'
                  )}
                  error={Boolean(
                    touched.isInsuranceIncluded && errors.isInsuranceIncluded
                  )}
                  errorText={
                    touched.isInsuranceIncluded && t(errors.isInsuranceIncluded)
                  }
                />

                <DropdownMenu
                  onBlur={handleBlur}
                  label={t('COMMON.MAX_DAYS_FOR_FIRST_RENT')}
                  value={values.maxGracePeriod}
                  name="maxGracePeriod"
                  setValue={value => setFieldValue('maxGracePeriod', value)}
                  error={Boolean(
                    touched.maxGracePeriod && errors.maxGracePeriod
                  )}
                  errorText={touched.maxGracePeriod && t(errors.maxGracePeriod)}
                  list={DaysForFirstRentAfterContractStartList}
                />

                <DropdownMenu
                  onBlur={handleBlur}
                  label={t('COMMON.DEFAULT_DAYS_FOR_FIRST_RENT')}
                  value={values.defaultGracePeriod}
                  name="defaultGracePeriod"
                  setValue={value => setFieldValue('defaultGracePeriod', value)}
                  error={Boolean(
                    touched.defaultGracePeriod && errors.defaultGracePeriod
                  )}
                  errorText={
                    touched.defaultGracePeriod && t(errors.defaultGracePeriod)
                  }
                  list={getDefaultDaysForFirstRentList(values.maxGracePeriod)}
                />
                <DropdownMenu
                  list={[
                    { key: 'true', name: t('COMMON.YES') },
                    { key: 'false', name: t('COMMON.NO') }
                  ]}
                  value={values.isPriceEditable}
                  setValue={value =>
                    setFieldValue('isPriceEditable', value === 'true')
                  }
                  label={t('DISTRIBUTORS_VIEW.PARAMETERS.IS_PRICE_EDITABLE')}
                  error={Boolean(
                    touched.isPriceEditable && errors.isPriceEditable
                  )}
                  errorText={
                    touched.isPriceEditable && t(errors.isPriceEditable)
                  }
                />
                <DropdownMenu
                  list={[
                    { key: 'true', name: t('COMMON.YES') },
                    { key: 'false', name: t('COMMON.NO') }
                  ]}
                  value={values.enableUseKamContactInfo}
                  setValue={value =>
                    setFieldValue('enableUseKamContactInfo', value === 'true')
                  }
                  label={t(
                    'COMMON.ENABLE_REPEAT_CONTACT_KAM_FOR_QUOTE_CREATION'
                  )}
                  error={Boolean(
                    touched.enableUseKamContactInfo &&
                      errors.enableUseKamContactInfo
                  )}
                  errorText={
                    touched.enableUseKamContactInfo &&
                    t(errors.enableUseKamContactInfo)
                  }
                />

                <DropdownMenu
                  list={[
                    { key: 'true', name: t('COMMON.YES') },
                    { key: 'false', name: t('COMMON.NO') }
                  ]}
                  value={values.enableEditKamInfo}
                  setValue={value =>
                    setFieldValue('enableEditKamInfo', value === 'true')
                  }
                  label={t('COMMON.ENABLE_EDIT_KAM_INFORMATION')}
                  error={Boolean(
                    touched.enableEditKamInfo && errors.enableEditKamInfo
                  )}
                  errorText={
                    touched.enableEditKamInfo && t(errors.enableEditKamInfo)
                  }
                />

                <TextField
                  label={t('DISTRIBUTORS_VIEW.PARAMETERS.MAX_RESIDUAL_VALUE')}
                  name="maxResidualValue"
                  id="maxResidualValue"
                  value={values.maxResidualValue}
                  onChange={e => {
                    const value = Number(e.target.value);
                    if (
                      value >= minValueOfMaxResidualValue &&
                      value <= maxValueOfMaxResidualValue
                    ) {
                      setFieldValue('maxResidualValue', value);
                    }
                  }}
                  type="number"
                  inputProps={{
                    min: minValueOfMaxResidualValue,
                    max: maxValueOfMaxResidualValue,
                    step: 1
                  }}
                  fullWidth
                  sx={{ marginBottom: 1, marginTop: 2 }}
                  error={Boolean(
                    touched.maxResidualValue && errors.maxResidualValue
                  )}
                  helperText={
                    touched.maxResidualValue && t(errors.maxResidualValue)
                  }
                />

                <DropdownMenu
                  list={[
                    { key: 'true', name: t('COMMON.YES') },
                    { key: 'false', name: t('COMMON.NO') }
                  ]}
                  value={values.enableMsi}
                  setValue={value =>
                    setFieldValue('enableMsi', value === 'true')
                  }
                  label={t('DISTRIBUTORS_VIEW.PARAMETERS.ENABLE_MSI')}
                  error={Boolean(touched.enableMsi && errors.enableMsi)}
                  errorText={touched.enableMsi && t(errors.enableMsi)}
                />

                <Autocomplete
                  options={categoryList}
                  id="quoteCategory"
                  loading={loadingCategoriesList}
                  getOptionLabel={option =>
                    option.name ? `${option.name}` : ''
                  }
                  label={t('DISTRIBUTORS_VIEW.PARAMETERS.CATEGORY')}
                  value={
                    categoryList.find(
                      category => category.id === values.quoteCategory?.id
                    ) ?? null
                  }
                  fullWidth
                  margin="normal"
                  onChange={(_e, value) => {
                    setFieldValue('quoteCategory', value ?? '');
                    setFieldValue('quoteCategoryId', value?.id ?? '');
                  }}
                  sx={{ marginBottom: 2, marginTop: 2 }}
                  error={Boolean(touched.quoteCategory && errors.quoteCategory)}
                  helperText={touched.quoteCategory && t(errors.quoteCategory)}
                />
                <DropdownMenu
                  list={[
                    { key: 'true', name: t('COMMON.YES') },
                    { key: 'false', name: t('COMMON.NO') }
                  ]}
                  value={values.anticipatedFirstRentEnabled}
                  setValue={value =>
                    setFieldValue(
                      'anticipatedFirstRentEnabled',
                      value === 'true'
                    )
                  }
                  label={t('COMMON.ENABLE_ANTICIPATED_FIRST_RENT')}
                  error={Boolean(
                    touched.anticipatedFirstRentEnabled &&
                      errors.anticipatedFirstRentEnabled
                  )}
                  errorText={
                    touched.anticipatedFirstRentEnabled &&
                    t(errors.anticipatedFirstRentEnabled)
                  }
                />

                <Grid container direction="column" alignItems="stretch">
                  <Grid item className={classes.buttons}>
                    <Button type="submit" color="primary" variant="outlined">
                      {t('COMMON.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

EditParameterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  parameter: PropTypes.object
};

export default EditParameterDialog;
